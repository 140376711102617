<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>

              <OpPainMedsForm
                :siteId="siteId"
                :visitId="visitId"
                :meds="meds"
                @setLocals="setLocals"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import OpPainMedsForm from '@/components/OpPainMedsForm.vue';

export default {
  name: 'OpPainMeds',
  props: {
    siteId: { type: Number, required: true },
    visitId: { type: Number, required: true }
  },
  components: { OpPainMedsForm },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      meds: {
        locals: []
      },
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Pain Medications';
      if (this.meds.mrn) {
        title += ` for Outpatient ${this.meds.mrn}`;
      }
      return title;
    },
    subtitle() {
      let subtitle = '';
      if (this.meds.visit_date) {
        subtitle += moment(this.meds.visit_date).format('dddd M/D/YYYY');
      }
      if (this.meds.post_injury_day) {
        subtitle += `, Post-Injury Day ${this.meds.post_injury_day}`;
      }
      return subtitle;
    }
  },
  async created() {
    if (this.visitId) {
      await this.getMeds(this.visitId);
      // Make sure that the meds object has the visit ID.
      if (!this.meds.op_visit_id) {
        this.meds.op_visit_id = this.visitId;
      }
    }
  },
  methods: {
    async getMeds() {
      const result = await api.post('/outpatients/getOpPainMeds', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.meds = result.data;

      let title = 'Pain Meds';
      let backPath = '/patients';
      if (this.meds) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.meds.patient_id}`,
            disabled: false,
            to: `/patients/${this.siteId}/${this.meds.patient_id}`
          },
          {
            text: 'Follow-up Visits',
            disabled: false,
            to: `/opVisits/${this.siteId}/${this.meds.patient_id}`
          },
          {
            text: `Day ${this.meds.post_injury_day} Pain Meds`,
            disabled: true
          }
        ];

        title = `${this.meds.mrn} Visit Day ${this.meds.post_injury_day}, Pain Meds`;
        backPath = `/opVisits/${this.siteId}/${this.meds.patient_id}`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', { title, backPath });
    },
    setLocals(locals) {
      this.meds = { ...this.meds, locals };
    },
    async save() {
      this.meds.locals = cleanNamedItems(this.meds.locals);

      this.saving = true;
      await api.post('/outpatients/saveOpPainMeds', {
        siteId: this.siteId,
        meds: this.meds
      });
      this.saving = false;
      this.$snotify.success('Saved the Pain Meds', 'Saved');
      this.$router.push(`/opVisits/${this.siteId}/${this.meds.patient_id}`);
    }
  }
};
</script>
