<template>
  <v-container>
    <v-layout fluid>
      <v-flex fluid>
        <v-card class="elevation-6 mx-auto" max-width="800px">
          <v-card-text>
            <v-container>
              <!-- study form -->
              <v-form>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      id="name"
                      label="Name"
                      v-model="study.name"
                      :rules="[v => !!v || 'Required']"
                      required
                      v-on:keyup.enter="save"
                    />
                  </v-col>
                </v-row>

                <!-- Sites multi-select -->
                <v-row>
                  <v-col cols="12" class="flex-shrink-1">
                    <v-combobox
                      v-model="study.sites"
                      :items="siteOptions"
                      item-value="id"
                      item-text="name"
                      label="Sites"
                      chips
                      clearable
                      multiple
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeSite(item.id)"
                        >
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea
                      name="comment"
                      label="Comments"
                      v-model="study.comment"
                      placeholder="Comments..."
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-switch v-model="study.archived" label="Archive" />
                  </v-col>
                </v-row>
              </v-form>

              <!-- button row -->
              <v-layout row class="action-row">
                <v-btn v-if="study.id" small text @click="dialog = true" class="del-btn">Delete</v-btn>
                <v-spacer />
                <v-btn @click="$router.back()">Cancel</v-btn>
                <v-btn
                  color="primary"
                  :loading="saving"
                  :disabled="!maySave"
                  @click.prevent="save"
                  class="spaced-button"
                  type="submit"
                >
                  Save
                </v-btn>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- delete confirmation dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Confirmation for Deleting Study
        </v-card-title>

        <v-card-text>
          <p class="confirm-p">
          Are you sure that you want to delete study <b>{{ study.name }}</b>?</p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="dialog = false">Cancel</v-btn>

          <v-spacer />
          <v-btn color="error" @click="deleteStudy">Yes, Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
  name: 'EditStudy',
  props: ['id'],
  data() {
    return {
      siteOptions: [],
      study: {
        id: '',
        name: '',
        comment: '',
        archived: false,
        site_ids: [],
      },
      saving: false,
      dialog: false
    };
  },
  computed: {
    ...mapState({
      authUser: state => state.user
    }),
    showSiteSelect() {
      return this.authUser && this.authUser.role === 'CIID admin';
    },
    maySave() {
      return this.study.name;
    }
  },
  async created() {
    if (!this.authUser || this.authUser.role !== 'CIID admin') {
      console.log('Not authorized to view this page');
      return;
    }

    if (this.id) {
      // Convert the param ID to an integer.
      const id = parseInt(this.id, 10);
      await this.getStudy(id);
    }

    let title = 'Study';
    if (this.study.id) {
      title = `Study: ${this.study.name}`;
    } else {
      title = 'Add new study';
    }
    this.$store.dispatch('setNavData', { title, backPath: '/studies' });

    if (this.authUser && this.authUser.role === 'CIID admin') {
      this.getSites();
    }
  },
  methods: {
    async getSites() {
      const result = await api.post('/sites/getSites');
      const sites = result.data;
      if (sites && sites.length > 0) {
        // Allow only the USA sites, for now.
        this.siteOptions = sites.filter(s => !s.international);
      }
    },
    async getStudy(id) {
      console.log('in getStudy');
      console.log('id:', id);
      const result = await api.post('/studies/getStudy', { id });
      if (result.data) {
        this.study = result.data;
        console.log('got study:', this.study);
      }
    },
    removeSite(id) {
      this.study.sites = this.study.sites.filter(s => s.id !== id);
    },
    async save() {
      console.log('in save');
      if (!this.maySave) {
        return;
      }
      this.saving = true;
      try {
        if (this.study.id) {
          console.log('calling updateStudy');
          await api.post('/studies/updateStudy', { study: this.study });
          console.log('done calling updateStudy');
        } else {
          console.log('calling addStudy');
          await api.post('/studies/addStudy', { study: this.study });
          console.log('done with addStudy');
        }

        // Show confirmation message.
        this.$snotify.success('Saved the Study', 'Saved');
        // Go back to the previous page.
        this.$router.back();
      } catch (err) {
        console.log('save study error:', err);
        this.$snotify.error('Error while saving the study', 'Error');
      } finally {
        this.saving = false;
      }
    },
    async deleteStudy() {
      if (!this.study.id) {
        return;
      }
      this.saving = true;
      try {
        await api.post('/studies/deleteStudy', { id: this.study.id });
        // Show confirmation message.
        this.$snotify.success('Deleted the Study', 'Deleted');
        this.$router.back();
      } catch (err) {
        console.log('deleteStudy error:', err);
        this.$snotify.error('Error while deleting the study', 'Error');
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
