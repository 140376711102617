// Convert any simple strings into { name } objects.
export function cleanNamedItems(items) {
  const cleaned = [];
  items.forEach((p) => {
    if (typeof p === 'string') {
      const name = p.trim();
      if (name) {
        cleaned.push({ name });
      }
    } else {
      cleaned.push(p);
    }
  });
  return cleaned;
}
