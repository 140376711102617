<template>
  <v-form>
    <v-card>
      <v-container>
        <v-card-title class="headline grey lighten-2" primary-title>
          Blunt Pulmonary Contusion 18
        </v-card-title>
        <v-card-text>
          <div v-if="loading" id="loading-container">
            <v-progress-circular indeterminate color="grey" />
            Loading...
          </div>

          <h4 class="section-title">Chest injuries for BPC18 calculation</h4>

          <v-row>
            <v-col xs12 md5>
              <v-select
                label="Right Upper Lung"
                v-model="bpc18.right_upper"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Right Middle Lung"
                v-model="bpc18.right_middle"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Right Lower Lung"
                v-model="bpc18.right_lower"
                :items="options"
                item-value="value"
                item-text="text"
              />
            </v-col>

            <v-col xs12 md5 offset-md1>
              <v-select
                label="Left Upper Lung"
                v-model="bpc18.left_upper"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Left Middle Lung"
                v-model="bpc18.left_middle"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Left Lower Lung"
                v-model="bpc18.left_lower"
                :items="options"
                item-value="value"
                item-text="text"
              />
            </v-col>
          </v-row>

          <v-alert :value="true" color="info" text>
            BPC18 score: {{ this.score }}
          </v-alert>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn @click="$emit('cancel')"> Cancel </v-btn>

          <v-btn color="primary" disabled v-if="saving">
            <v-progress-circular indeterminate color="primary" size="25" />
            Saving...
          </v-btn>
          <v-btn color="primary" @click.prevent="save" v-else> Save </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { sum } from 'lodash';
import api from '@/api.js';

export default {
  name: 'Bpc18Form',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true },
  },
  data() {
    return {
      loading: false,
      saving: false,
      options: [
        { value: null, text: 'Select...' },
        { value: 0, text: 'No injury' },
        { value: 1, text: 'Minor' },
        { value: 2, text: 'Moderate' },
        { value: 3, text: 'Severe' },
      ],
      bpc18: {},
      origScore: 0,
    };
  },
  async created() {
    this.getBpc18(this.siteId, this.patientId);
  },
  computed: {
    score() {
      return (
        sum([
          this.bpc18.left_upper,
          this.bpc18.left_middle,
          this.bpc18.left_lower,
          this.bpc18.right_upper,
          this.bpc18.right_middle,
          this.bpc18.right_lower,
        ]) || this.origScore
      );
    },
  },
  methods: {
    async getBpc18(siteId, patientId) {
      this.loading = true;
      try {
        const result = await api.post('/patients/getBpc18', {
          siteId,
          patientId,
        });
        if (result.data) {
          this.bpc18 = result.data;
          this.origScore = this.bpc18.score;
        }
      } catch (err) {
        console.log('getBpc18 error:', err);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      // Insert the patient ID and score into the bpc18 object.
      this.bpc18.patient_id = this.patientId;
      this.bpc18.score = this.score;

      this.saving = true;
      try {
        await api.post('/patients/saveBpc18', {
          siteId: this.siteId,
          bpc18: this.bpc18,
        });
        // Tell the parent component that we're done saving.
        this.$emit('save', this.score);
      } catch (err) {
        console.log('saveBpc18 error:', err);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped>
#loading-container {
  padding-top: 10px;
}
.section-title {
  margin-top: 15px;
}
</style>
