<template>
  <v-container>
    <v-layout justify-center column>
      <v-flex fluid>
        <v-card class="elevation-6 mx-auto" max-width="600px">
          <v-card-text>
            <v-container>
              <!-- site form -->
              <v-form>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      id="Name"
                      label="Site Name"
                      v-model="site.name"
                      :rules="nameRule"
                      required
                      v-on:keyup.enter="save"
                    />
                  </v-col>
                </v-row>

                <!-- Show the international checkbox only when I'm adding a new site. -->
                <v-checkbox
                  v-model="site.international"
                  v-if="!Boolean(site.id)"
                  label="International (not USA)"
                  type="checkbox"
                />
                <!-- Show the international status. -->
                <div v-else>
                  <p v-if="site.international">International Site</p>
                  <p v-else>USA Site</p>
                </div>
              </v-form>

              <!-- button row -->
              <v-layout row class="action-row">
                <v-btn
                  v-if="site.id"
                  small
                  text
                  @click="dialog = true"
                  class="del-btn"
                >
                  Delete
                </v-btn>
                <v-spacer />
                <v-btn to="/sites">Cancel</v-btn>
                <v-btn
                  color="primary"
                  :loading="saving"
                  :disabled="!maySave"
                  @click.prevent="save"
                  class="spaced-button"
                  type="submit"
                >
                  Save
                </v-btn>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- delete confirmation dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Confirmation for Deleting Site
        </v-card-title>

        <v-card-text>
          <p class="confirm-p">
            Are you sure that you want to delete the site {{ site.name }}, and
            all its patients and other associated data?
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="dialog = false">Cancel</v-btn>

          <v-spacer />
          <v-btn color="error" @click="deleteSite">Yes, Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/api';

export default {
  name: 'Site',
  props: ['id'],
  data() {
    return {
      site: {
        name: '',
        international: false
      },
      saving: false,
      dialog: false
    };
  },
  computed: {
    nameRule() {
      if (!this.site.name) {
        return ['Required'];
      }
      return [];
    },
    maySave() {
      return Boolean(this.site.name);
    }
  },
  async created() {
    if (this.id) {
      // Convert the param ID to an integer.
      const id = parseInt(this.id, 10);
      await this.getSite(id);
    }

    let title = 'Site';
    if (this.site.id) {
      title = `Site: ${this.site.name}`;
    } else {
      title = 'Add new site';
    }
    this.$store.dispatch('setNavData', { title, backPath: '/sites' });
  },
  methods: {
    async getSite(id) {
      const result = await api.post('/sites/getSite', { id });
      if (result.data) {
        this.site = result.data;
      }
    },
    async save() {
      this.saving = true;
      await api.post('/sites/saveSite', { site: this.site });
      this.saving = false;
      // Show confirmation message.
      this.$snotify.success('Saved the Site', 'Saved');
      // Go back to the sites list page.
      this.$router.push('/sites');
    },
    async deleteSite() {
      this.saving = true;
      await api.post('/sites/deleteSite', { id: this.site.id });
      this.saving = false;
      // Show confirmation message.
      this.$snotify.success('Deleted the Site', 'Deleted');
      // Go back to the sites list page.
      this.$router.push('/sites');
    }
  }
};
</script>
