<template>
  <div>
    <v-layout row>
        <table>
          <thead>
            <tr>
              <th></th>
              <th colspan="5" class="side-header">Right</th>
              <th class="text-xs-center center-cell">Sternum</th>
              <th colspan="5" class="side-header">Left</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rib</td>
              <td class="text-xs-center">P</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">A</td>
              <td></td>
              <td class="text-xs-center">A</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">P</td>
              <td>Rib</td>
            </tr>

            <tr v-for="rib of ribs" v-bind:key="rib">
              <!-- right side -->
              <td class="shrink text-xs-right">{{ rib }}</td>
              <template v-for="location in rightLocations">
                <td :key="`${rib}-right-${location}`" class="text-xs-center">
                  <FractureCell
                    :fracture="getFracture(rib, 'right', location)"
                    v-on:clickFracture="clickFracture"
                  />
                </td>
              </template>

              <!-- sternum -->
              <!-- Show the sternum box only for the first two rows. -->
              <td
                v-if="rib < 4"
                :key="`${rib}-sternum`"
                class="text-xs-center center-cell"
              >
                <FractureCell
                  :fracture="getFracture(rib, 'sternum', sternumLocation(rib))"
                  v-on:clickFracture="clickFracture"
                />
              </td>
              <!-- empty cell for the other rib rows-->
              <td class="center-cell" v-else></td>

              <!-- left side -->
              <template v-for="location in leftLocations">
                <td :key="`${rib}-left-${location}`" class="text-xs-center">
                  <FractureCell
                    :fracture="getFracture(rib, 'left', location)"
                    v-on:clickFracture="clickFracture"
                  />
                </td>
              </template>
              <td class="shrink">{{ rib }}</td>
            </tr>
          </tbody>
        </table>
      </v-layout>

      <!-- legend row -->
      <v-layout row>
        <v-col v-if="showLegends">
          <RibLocationLegend />
        </v-col>

        <v-col v-if="showLegends">
          <FractureTypeLegend />
        </v-col>
        </v-layout>
      </v-layout>

      <!-- fracture type pop-up dialog -->
      <v-dialog
        v-model="typeDialog"
        max-width="500"
        @keydown.esc="cancelFractureType"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Choose fracture type
          </v-card-title>

          <v-card-text>
            <b>Location:</b>
            <p>
              Rib: {{ fracture.rib }}, {{ fracture.side }},
              {{ fracture.location }}
              <span v-if="fracture.type"> , {{ fracture.type }} </span>
            </p>

            <p><b>Fracture type:</b></p>
            <v-radio-group v-model="fracture.type" column @change="addFracture">
              <v-radio
                label="Undisplaced"
                value="undisplaced"
                color="primary"
              />
              <v-radio label="Offset" value="offset" color="primary" />
              <v-radio label="Displaced" value="displaced" color="primary" />
            </v-radio-group>

            <v-alert text :value="true" icon="mdi-information">
              For fracture examples, please close this form and click the "Show
              Examples" button.
            </v-alert>
          </v-card-text>

          <v-divider />
          <v-card-actions>
            <v-btn text v-if="fracture.type" @click="removeFracture">
              Delete
            </v-btn>

            <v-spacer />

            <v-btn color="primary" text @click="cancelFractureType">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import RibLocationLegend from '@/components/RibLocationLegend.vue';
import FractureTypeLegend from '@/components/FractureTypeLegend.vue';
import FractureCell from '@/components/FractureCell.vue';

export default {
  name: 'FracturesGrid',
  components: {
    RibLocationLegend,
    FractureTypeLegend,
    FractureCell,
  },
  props: {
    title: { type: String, required: true },
    fractures: { type: Array, required: true },
    showLegends: { type: Boolean, required: false, default: false },
  },
  methods: {
    sternumLocation(rib) {
      if (rib === 1) {
        return 'manubrium';
      }
      if (rib === 2) {
        return 'body';
      }
      if (rib === 3) {
        return 'zyphoid';
      }
    },
    // Find the fracture by its position.
    getFracture(rib, side, location) {
      const fracture = this.fractures.find(
        (f) => f.rib === rib && f.side === side && f.location === location
      );
      if (fracture) {
        return fracture;
      }
      // Return a new fracture, without a type.
      return { rib, side, location, type: null };
    },
    clickFracture(fracture) {
      this.fracture = fracture;
      this.typeDialog = true;
    },
    cancelFractureType() {
      this.typeDialog = false;
      this.fracture = {};
    },
    addFracture() {
      const frac = cloneDeep(this.fracture);
      this.fracture = {};
      this.$emit('addFracture', frac);
      this.cancelFractureType();
    },
    removeFracture() {
      this.$emit('removeFracture', this.fracture);
      this.cancelFractureType();
    },
  },
  data() {
    const rightLocations = [
      'posterior',
      'posterior-lateral',
      'lateral',
      'antero-lateral',
      'anterior',
    ];
    const leftLocations = rightLocations.slice().reverse();

    return {
      ribs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      sides: ['right', 'sternum', 'left'],
      rightLocations,
      leftLocations,
      fracture: {},
      typeDialog: false,
    };
  },
};
</script>

<style scoped>
.side-header {
  border-bottom: 2px solid #4186d8;
}
.shrink {
  margin: 0px;
  padding: 0px;
  height: 20px;
}
.center-cell {
  display: flex;
  justify-content: center;
}
.score-alert {
  margin-bottom: 20px;
}
</style>
