import Vue from 'vue';
import store from './store';
import Router from 'vue-router';
import Home from './views/Home.vue';

// for authentication pages
import Login from './views/Login.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import SetPassword from './views/SetPassword.vue';

// analytics
import CiidAnalytics from './views/CiidAnalytics.vue';
import Analytics from './views/Analytics.vue';

// patient pages
import Patient from './views/Patient.vue';
import Patients from './views/Patients.vue';

// inpatient pages
import IpDays from './views/IpDays.vue';
import IpDateRange from './views/IpDateRange.vue';
import IpDay from './views/IpDay.vue';
import IpPainMeds from './views/IpPainMeds.vue';
import IpDailyOutcomes from './views/IpDailyOutcomes.vue';
import IpAdditionalOutcomes from './views/IpAdditionalOutcomes.vue';
import IpPfts from './views/IpPfts.vue';

// outpatient pages
import OpVisits from './views/OpVisits.vue';
import OpVisit from './views/OpVisit.vue';
import OpVitals from './views/OpVitals.vue';
import OpPainMeds from './views/OpPainMeds.vue';
import OpPfts from './views/OpPfts.vue';
import OpQol from './views/OpQol.vue';

// site pages
import Sites from './views/Sites.vue';
import Site from './views/Site.vue';

// user pages
import Users from './views/Users.vue';
import User from './views/User.vue';

// study pages
import Studies from './views/Studies.vue';
import EditStudy from './views/EditStudy.vue';
import Study from './views/Study.vue';

// other pages
import Help from './views/Help.vue';
import Import from './views/Import.vue';

import shouldShowCiidAnalytics from './utils/shouldShowCiidAnalytics';

Vue.use(Router);

let triedSessionLogin = false;

// Make sure the user is logged in before seeing the desired page.
const guard = async (to, from, next) => {
  if (store.getters.isLoggedIn) {
    return next();
  }

  if (triedSessionLogin) {
    next({ name: 'home' });
  }

  // Try to log in by the session user ID.
  await store.dispatch('getSessionUser');
  triedSessionLogin = true;

  if (store.getters.isLoggedIn) {
    // continue
    next();
  } else {
    next({ name: 'login', query: { redirect: to.path } });
  }
};

// Guard for pages for only the CIID system admins.
const guardCiidAdmin = async (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    if (triedSessionLogin) {
      next({ name: 'home' });
      return;
    } else {
      // Try to log in by the session user ID.
      await store.dispatch('getSessionUser');
      triedSessionLogin = true;
    }
  }

  if (store.getters.isLoggedIn && store.state.user.role === 'CIID admin') {
    next();
  } else {
    next({ name: 'home' });
  }
};

// Guard for pages for the site and CIID system admins.
const guardSiteAdmin = async (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    if (triedSessionLogin) {
      next({ name: 'home' });
      return;
    } else {
      // Try to log in by the session user ID.
      await store.dispatch('getSessionUser');
      triedSessionLogin = true;
    }
  }

  if (
    store.getters.isLoggedIn &&
    ['Site admin', 'CIID admin'].includes(store.state.user.role)
  ) {
    next();
  } else {
    next({ name: 'home' });
  }
};

const guardImportPage = async (to, from, next) => {
  const redirectPage = { name: 'home' };

  if (!store.getters.isLoggedIn) {
    next(redirectPage);
    return;
  } else {
    // Try to log in by the session user ID.
    await store.dispatch('getSessionUser');
    triedSessionLogin = true;
  }

  const user = store.state.user;

  // For now, only Phillip and CIID admins can go to the import file pae.
  if (
    user &&
    (user.role =
      'CIID admin' ||
      (user.first_name === 'Phillip' && user.last_name === 'Antippa'))
  ) {
    next();
  } else {
    next(redirectPage);
  }
};

// If the user goes to the home page but still has a valid session,
// then forward him to the analytics page.
const initToAnalytics = async (to, from, next) => {
  if (store.getters.isLoggedIn) {
    const defaultPage = shouldShowCiidAnalytics(store.state.user)
      ? 'ciidAnalytics'
      : 'analytics';
    return next({ name: defaultPage });
  }

  if (triedSessionLogin) {
    // Continue to the home page.
    return next();
  }

  // Try to log in by the session user ID.
  await store.dispatch('getSessionUser');
  triedSessionLogin = true;

  if (store.getters.isLoggedIn) {
    const defaultPage = shouldShowCiidAnalytics(store.state.user)
      ? 'ciidAnalytics'
      : 'analytics';
    return next({ name: defaultPage });
  } else {
    // Continue to the home page.
    return next();
  }
};

function paramsToInts(params, intParamNames) {
  const ps = { ...params };
  intParamNames.forEach((p) => {
    if (params[p]) {
      ps[p] = parseInt(ps[p], 10);
    }
  });
  return ps;
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: initToAnalytics,
      component: Home,
    },

    // authencation routes
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/setPassword',
      name: 'setPassword',
      beforeEnter: guard,
      component: SetPassword,
      meta: { requiresAuth: true },
    },

    // analytics routes
    {
      path: '/analytics',
      name: 'analytics',
      beforeEnter: guard,
      component: Analytics,
      meta: { requiresAuth: true },
    },

    {
      path: '/ciidAnalytics',
      name: 'ciidAnalytics',
      beforeEnter: guard,
      component: CiidAnalytics,
      meta: { requiresAuth: true },
    },

    // patient routes
    {
      path: '/patients',
      name: 'patients',
      beforeEnter: guard,
      component: Patients,
      meta: { requiresAuth: true },
    },
    {
      path: '/patient/:siteId/:id?/:startStep?',
      name: 'patient',
      beforeEnter: guard,
      component: Patient,
      props: (route) =>
        paramsToInts(route.params, ['siteId', 'id', 'startStep']),
      meta: { requiresAuth: true },
    },

    // inpatient routes
    {
      path: '/ipDays/:siteId/:patientId',
      name: 'ipDays',
      beforeEnter: guard,
      component: IpDays,
      props: (route) => paramsToInts(route.params, ['siteId', 'patientId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipDay/:siteId/:patientId/:dayId?',
      name: 'ipDay',
      beforeEnter: guard,
      component: IpDay,
      props: (route) =>
        paramsToInts(route.params, ['siteId', 'patientId', 'dayId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipDateRange/:siteId/:patientId',
      name: 'ipDateRange',
      beforeEnter: guard,
      component: IpDateRange,
      props: (route) => paramsToInts(route.params, ['siteId', 'patientId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipPainMeds/:siteId/:dayId',
      name: 'ipPainMeds',
      beforeEnter: guard,
      component: IpPainMeds,
      props: (route) => paramsToInts(route.params, ['siteId', 'dayId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipDailyOutcomes/:siteId/:dayId',
      name: 'ipDailyOutcomes',
      beforeEnter: guard,
      component: IpDailyOutcomes,
      props: (route) => paramsToInts(route.params, ['siteId', 'dayId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipAdditionalOutcomes/:siteId/:dayId',
      name: 'ipAdditionalOutcomes',
      beforeEnter: guard,
      component: IpAdditionalOutcomes,
      props: (route) => paramsToInts(route.params, ['siteId', 'dayId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/ipPfts/:siteId/:dayId',
      name: 'ipPfts',
      beforeEnter: guard,
      component: IpPfts,
      props: (route) => paramsToInts(route.params, ['siteId', 'dayId']),
      meta: { requiresAuth: true },
    },

    // outpatient routes
    {
      path: '/opVisits/:siteId/:patientId',
      name: 'opVisits',
      beforeEnter: guard,
      component: OpVisits,
      props: (route) => paramsToInts(route.params, ['siteId', 'patientId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/opVisit/:siteId/:patientId/:visitId?',
      name: 'opVisit',
      beforeEnter: guard,
      component: OpVisit,
      props: (route) =>
        paramsToInts(route.params, ['siteId', 'patientId', 'visitId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/opVitals/:siteId/:visitId',
      name: 'opVitals',
      beforeEnter: guard,
      component: OpVitals,
      props: (route) => paramsToInts(route.params, ['siteId', 'visitId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/opPainMeds/:siteId/:visitId',
      name: 'opPainMeds',
      beforeEnter: guard,
      component: OpPainMeds,
      props: (route) => paramsToInts(route.params, ['siteId', 'visitId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/opPfts/:siteId/:visitId',
      name: 'opPfts',
      beforeEnter: guard,
      component: OpPfts,
      props: (route) => paramsToInts(route.params, ['siteId', 'visitId']),
      meta: { requiresAuth: true },
    },
    {
      path: '/opQol/:siteId/:visitId',
      name: 'opQol',
      beforeEnter: guard,
      component: OpQol,
      props: (route) => paramsToInts(route.params, ['siteId', 'visitId']),
      meta: { requiresAuth: true },
    },

    // site routes
    {
      path: '/sites',
      name: 'sites',
      beforeEnter: guardCiidAdmin,
      component: Sites,
      meta: { requiresAuth: true },
    },
    {
      path: '/site/:id?',
      name: 'site',
      beforeEnter: guardCiidAdmin,
      component: Site,
      props: (route) => paramsToInts(route.params, ['id']),
      meta: { requiresAuth: true },
    },

    // user routes
    {
      path: '/users',
      name: 'users',
      beforeEnter: guardSiteAdmin,
      component: Users,
      meta: { requiresAuth: true },
    },
    {
      path: '/user/:id?',
      name: 'user',
      beforeEnter: guardSiteAdmin,
      component: User,
      props: (route) => paramsToInts(route.params, ['id']),
      meta: { requiresAuth: true },
    },

    // studies routes
    {
      path: '/studies',
      name: 'studies',
      beforeEnter: guardCiidAdmin,
      component: Studies,
      meta: { requiresAuth: true },
    },
    {
      path: '/editStudy/:id?',
      name: 'editStudy',
      beforeEnter: guardCiidAdmin,
      component: EditStudy,
      props: (route) => paramsToInts(route.params, ['id']),
      meta: { requiresAuth: true },
    },
    {
      path: '/study/:id',
      name: 'Study',
      beforeEnter: guardCiidAdmin,
      component: Study,
      props: (route) => paramsToInts(route.params, ['id']),
      meta: { requiresAuth: true },
    },

    // other routes
    {
      path: '/help',
      name: 'help',
      beforeEnter: guardSiteAdmin,
      component: Help,
      meta: { requiresAuth: true },
    },
    {
      path: '/import',
      name: 'import',
      beforeEnter: guardImportPage,
      component: Import,
      meta: { requiresAuth: true },
    },

    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ],
});

router.beforeEach((to, from, next) => {
  // If we were directed to the login page,
  // then try to continue to the originally desired page.
  if (
    from.name === 'login' &&
    from.query.redirect &&
    store.getters.isLoggedIn
  ) {
    const newUrl = from.query.redirect;
    delete from.query.redirect;
    next({ path: newUrl });
  } else {
    next();
  }
});

export default router;
