<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 lg10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>
              <OpVitalsForm :siteId="siteId" :vitals="vitals" @save="save" />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import OpVitalsForm from '@/components/OpVitalsForm.vue';

export default {
  name: 'OpVitals',
  props: {
    siteId: { type: Number, required: true },
    visitId: { type: Number, required: true }
  },
  components: { OpVitalsForm },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      vitals: {},
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Vitals';
      if (this.vitals.mrn) {
        title += ` for Outpatient ${this.vitals.mrn}`;
      }
      return title;
    },
    subtitle() {
      let subtitle = '';
      if (this.vitals.visit_date) {
        subtitle += moment(this.vitals.visit_date).format('dddd M/D/YYYY');
      }
      if (this.vitals.post_injury_day) {
        subtitle += `, Post-Injury Day ${this.vitals.post_injury_day}`;
      }
      return subtitle;
    }
  },
  async created() {
    if (this.visitId) {
      await this.getVitals();
      // Make sure that the vitals object has the visit ID.
      if (!this.vitals.op_visit_id) {
        this.vitals.op_visit_id = this.visitId;
      }
    }
  },
  methods: {
    async getVitals() {
      const result = await api.post('/outpatients/getOpVitals', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.vitals = result.data;

      let title = 'Vitals';
      let backPath = '/patients';
      const patientId = this.vitals.patient_id;
      if (this.vitals) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${patientId}`,
            disabled: false,
            to: `/patients/${this.siteId}/${patientId}`
          },
          {
            text: 'Follow-up Visits',
            disabled: false,
            to: `/opVisits/${this.siteId}/${patientId}`
          },
          {
            text: `Day ${this.vitals.post_injury_day} Vitals`,
            disabled: true
          }
        ];

        title = `${this.vitals.mrn} Visit Day ${this.vitals.post_injury_day}, Vitals`;
        backPath = `/opVisits/${this.siteId}/${patientId}`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', { title, backPath });
    },
    async save() {
      this.saving = true;
      await api.post('/outpatients/saveOpVitals', {
        siteId: this.siteId,
        vitals: this.vitals
      });
      this.saving = false;
      this.$snotify.success('Saved the Vitals', 'Saved');
      this.$router.push(`/opVisits/${this.siteId}/${this.vitals.patient_id}`);
    }
  }
};
</script>
