<template>
  <v-container fluid>
    <v-layout justify-center column>
      <v-flex>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card id="ip-days-list" class="elevation-6">
          <v-card-text>
            <IpDaysTable :siteId="siteId" :patientId="patientId" :days="days" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn to="/patients" class="spaced-button">
              Close
            </v-btn>
            <v-btn color="primary" :to="`/ipDateRange/${siteId}/${patientId}`">
              Add Date Range
            </v-btn>
            <v-btn color="primary" :to="`/ipDay/${siteId}/${patientId}`">
              Add Day
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from '@/api.js';
import IpDaysTable from '@/components/IpDaysTable.vue';

export default {
  name: 'IpDays',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true }
  },
  components: {
    IpDaysTable
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      patient: {},
      days: []
    };
  },
  async created() {
    let title = 'Inpatient days';
    this.$store.dispatch('setNavData', {
      title,
      backPath: '/patients'
    });

    const patient = await this.getPatientIds();
    if (patient) {
      this.getIpDays();
      if (patient.mrn) {
        title = `${patient.mrn} Inpatient Days`;
        // Set the title in the navbar.
        this.$store.dispatch('setNavData', {
          title,
          backPath: '/patients'
        });
      }
    }
  },
  methods: {
    async getPatientIds() {
      const result = await api.post('/patients/getPatientIds', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      const patient = result.data;
      this.patient = patient;

      // Add the relevant breadcrumbs.
      if (patient) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${patient.mrn}`,
            disabled: false,
            to: `/patient/${this.siteId}/${this.patientId}`
          },
          {
            text: 'Inpatient Days',
            disabled: true
          }
        ];
      }

      return patient;
    },
    async getIpDays() {
      const result = await api.post('/inpatients/getIpDays', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      const days = result.data;
      if (days.length > 0) {
        this.days = days;
      }
    }
  }
};
</script>
