<template>
  <v-container>
    <v-layout justify-center column>
      <v-flex fluid>
        <v-card id="patient-list" class="elevation-6">
          <v-card-text>
            <SitesTable :sites="sites" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" to="/site">Add Site</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import SitesTable from '@/components/SitesTable';

export default {
  name: 'Sites',
  components: {
    SitesTable
  },
  data() {
    return {
      sites: []
    };
  },
  async created() {
    if (this.user && this.user.role === 'CIID admin') {
      this.getSiteStats();
    }
    this.$store.dispatch('setNavData', { title: 'Sites', backPath: '' });
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async getSiteStats() {
      const result = await api.post('/sites/getSiteStats');
      this.sites = result.data;
    }
  }
};
</script>

<style scoped>
.left-btn {
  margin-right: 10px;
}
.add-icon {
  margin-right: 7px;
}
#patient-list {
  margin-top: 30px;
}
</style>
