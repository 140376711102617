<template>
  <v-container>
    <v-form ref="patientForm">
      <v-layout row wrap justify-space-between>
        <v-flex xs12 md3>
          <v-text-field
            id="mrn"
            :label="mrnLabel"
            v-model="patient.mrn"
            @input="debouncedCheckMrn"
            hint="Please use a coded ID, rather than a medical record number (MRN)."
            persistent-hint
            :rules="mrnRules"
            :loading="checkingMrnConflict"
            required
          />
        </v-flex>

        <v-flex xs12 md8>
          <v-textarea
            name="comment"
            label="Comments"
            v-model="patient.comment"
            placeholder="General comments about this patient"
          />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <div v-if="studyOptions.length > 0" class="form-section">
        <div>
          <v-divider />
        </div>

        <v-layout row class="form-section"> Research studies </v-layout>

        <v-layout wrap row class="checkbox-row">
          <v-flex xs12 md4 v-for="study in studyOptions" :key="study.id">
            <v-checkbox
              v-model="patient.studyIds"
              :label="study.name"
              :value="study.id"
            />
          </v-flex>
        </v-layout>

        <v-layout row class="form-section" v-if="patientLink">
          <v-alert dense text type="info">
            To link the patient records in an external research database, use
            the patient link
            <p>
              <strong>{{ patientLink }}</strong>
            </p>
          </v-alert>
        </v-layout>
      </div>

      <!-- button row -->
      <v-layout row class="action-row form-section">
        <v-btn
          v-if="patient.id"
          small
          text
          @click="dialog = true"
          class="del-btn"
        >
          Delete
        </v-btn>
        <v-btn to="/patients">Cancel</v-btn>
        <v-spacer />
        <v-btn
          :loading="saving"
          :disabled="!maySave"
          @click.prevent="$emit('saveAndClose')"
          class="spaced-button"
        >
          Save & Close
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!maySave"
          :loading="saving"
          @click.prevent="$emit('next')"
        >
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>

      <!-- delete confirmation dialog -->
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline error white--text" primary-title>
            Confirmation for Deleting Patient
          </v-card-title>

          <v-card-text>
            <p class="confirm-p">
              Are you sure that you want to delete patient {{ patient.mrn }},
              and all the associated data?
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-btn @click="dialog = false">Cancel</v-btn>

            <v-spacer />
            <v-btn color="error" @click="deletePatient">Yes, Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import api from '@/api.js';

export default {
  name: 'PatientIdForm',
  props: {
    siteId: { type: Number, required: true },
    patient: { type: Object, required: true },
    saving: Boolean,
  },
  async created() {
    this.debouncedCheckMrn = debounce(this.checkMrnConflict, 500);
    this.getStudyOptions();
  },
  data() {
    return {
      checkingMrnConflict: false,
      mrnConflict: false,
      dialog: false,
      ptStudyIds: [],
      studyOptions: [],
      appUrl: process.env.VUE_APP_URL,
    };
  },
  computed: {
    mrnLabel() {
      const label = 'Patient ID';
      if (!this.patient.mrn) {
        return `${label} (Required)`;
      }
      return label;
    },
    mrnRules() {
      if (!this.patient.mrn) {
        return ['Required'];
      }
      if (this.mrnConflict) {
        return [
          'This MRN is already taken. Please edit the editing patient record.',
        ];
      }
      return [];
    },
    maySave() {
      return !this.checkingMrnConflict && this.patient.mrn;
    },
    patientLink() {
      if (!this.patient.studyIds || this.patient.studyIds.length === 0) {
        return '';
      }
      return `${this.appUrl}/patient/${this.patient.site_id}/${this.patient.id}`;
    },
  },
  methods: {
    async getStudyOptions() {
      const result = await api.post('/studies/getSiteStudies', {
        id: this.siteId,
      });
      this.studyOptions = result.data;
    },
    async checkMrnConflict() {
      let mrn = this.patient.mrn && this.patient.mrn.trim();
      if (!mrn) {
        this.checkingMrnConflict = false;
        this.mrnConflict = false;
        return;
      }

      this.checkingMrnConflict = true;
      const result = await api.post('/patients/checkMrnConflict', {
        siteId: this.siteId,
        id: this.patient.id,
        mrn: this.patient.mrn,
      });
      this.checkingMrnConflict = false;
      this.mrnConflict = result.data;
    },
    deletePatient() {
      this.dialog = false;
      this.$emit('deletePatient', this.patient.id);
    },
  },
};
</script>

<style>
.del-btn {
  margin-right: 10px;
}
.form-section {
  padding-top: 30px;
}
.spaced-button {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
