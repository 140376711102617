<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>
              <IpDailyOutcomesForm
                :siteId="siteId"
                :outcomes="outcomes"
                :saving="saving"
                @setCoags="setCoags"
                @setPlatelets="setPlatelets"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import IpDailyOutcomesForm from '@/components/IpDailyOutcomesForm.vue';

export default {
  name: 'IpDailyOutcomes',
  props: {
    siteId: { type: Number, required: true },
    dayId: { type: Number, required: false }
  },
  components: {
    IpDailyOutcomesForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      outcomes: {
        coags: [],
        platelets: []
      },
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Daily Outcomes';
      if (this.outcomes.mrn) {
        title += ` for Inpatient ${this.outcomes.mrn}`;
      }
      return title;
    },
    subtitle() {
      if (this.outcomes.hospital_date) {
        const date = moment(this.outcomes.hospital_date).format(
          'dddd M/D/YYYY'
        );
        return `${date}, Hospital Day ${this.outcomes.hospital_day}`;
      }
      return '';
    }
  },
  methods: {
    async getIpDailyOutcomes() {
      this.loading = true;
      const result = await api.post('/inpatients/getIpDailyOutcomes', {
        siteId: this.siteId,
        dayId: this.dayId
      });
      this.outcomes = result.data;
      this.loading = false;

      // Add the relevant breadcrumbs.
      if (this.outcomes) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.outcomes.mrn}`,
            disabled: false,
            to: `/patient/${this.siteId}/${this.outcomes.patient_id}`
          },
          {
            text: 'Inpatient Days',
            disabled: false,
            to: `/ipDays/${this.siteId}/${this.outcomes.patient_id}`
          },
          {
            text: `Day ${this.outcomes.hospital_day} Daily Outcomes`,
            disabled: true
          }
        ];

        this.$store.dispatch('setNavData', {
          title: `${this.outcomes.mrn} Day ${this.outcomes.hospital_day}, Daily Outcomes`,
          backPath: `/ipDays/${this.siteId}/${this.outcomes.patient_id}`
        });
      }
    },
    setCoags(coags) {
      this.outcomes = { ...this.outcomes, coags };
    },
    setPlatelets(platelets) {
      this.outcomes = { ...this.outcomes, platelets };
    },
    async save() {
      this.outcomes.coags = cleanNamedItems(this.outcomes.coags);
      this.outcomes.platelets = cleanNamedItems(this.outcomes.platelets);

      this.saving = true;
      await api.post('/inpatients/saveIpDailyOutcomes', {
        siteId: this.siteId,
        outcomes: this.outcomes
      });
      this.saving = false;
      this.$snotify.success('Saved the Daily Outcomes', 'Saved');
      // Go back to the inpatient page.
      this.$router.push(`/ipDays/${this.siteId}/${this.outcomes.patient_id}`);
    }
  },
  async created() {
    if (this.dayId) {
      await this.getIpDailyOutcomes();
    }
  }
};
</script>
