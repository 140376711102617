<template>
  <div id="action-row">
    <v-btn
      color="primary"
      outlined
      class="action-button"
      :to="`/patient/${siteId}`"
    >
      Add Patient
    </v-btn>

    <v-btn color="primary" outlined class="action-button" to="/patients">
      See All Patients
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ActionRow',
  props: {
    siteId: { type: Number, required: true }
  }
};
</script>

<style scoped>
#action-row {
  margin-top: 15px;
  margin-bottom: 15px;
}
.action-button {
  margin-right: 10px;
}
</style>
