<template>
  <v-container>
    <v-layout fluid>
      <v-flex fluid>
        <div class="page-section">
        <v-card class="elevation-6">
          <v-card-text>
            <StudySummary :study="study" />
          </v-card-text>
        </v-card>
        </div>

        <div class="page-section">
          <v-card id="page-section" class="elevation-6">
            <v-card-text>
              <StudyPatientsTable :patients="patients" @remove-patient="deleteStudyPatient" />
            </v-card-text>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import api from '@/api';
import StudySummary from '@/components/studies/StudySummary.vue';
import StudyPatientsTable from '@/components/studies/StudyPatientsTable.vue';

export default {
  name: 'Study',
  props: {
    id: { type: Number, required: true }
  },
  components: {
    StudySummary,
    StudyPatientsTable
  },
  data() {
    return {
      study: {},
      patients: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      filter: state => state.patientFilter
    }),
    filteredPatients() {
      let filtered = this.patients;

      if (this.filter.fromDate && this.isValidDate(this.filter.fromDate)) {
        const from = moment(this.filter.fromDate, 'MM/DD/YYYY');
        filtered = filtered.filter(
          p => p.admit_date && moment(p.admit_date) >= from
        );
      }
      if (this.filter.toDate && this.isValidDate(this.filter.toDate)) {
        const to = moment(this.filter.toDate, 'MM/DD/YYYY');
        filtered = filtered.filter(
          p => p.admit_date && moment(p.admit_date) <= to
        );
      }

      const lowerQuery = this.filter.query
        ? this.filter.query.trim().toLowerCase()
        : '';
      if (lowerQuery) {
        const textFields = ['mrn', 'comment'];
        filtered = filtered.filter(p =>
          textFields.some(
            f => p[f] && p[f].trim() && p[f].toLowerCase().includes(lowerQuery)
          )
        );
      }

      return filtered;
    }
  },
  async created() {
    this.getStudy(this.id);
    this.getStudyPatients(this.id);

    let title = 'Study';
    this.$store.dispatch('setNavData', { title, backPath: '/studies' });
  },
  methods: {
    async getStudy(id) {
      const result = await api.post('/studies/getStudy', { id })
      this.study = result.data;

      // Update the page title.
      if (this.study) {
        const title = `Study: ${this.study.name}`;
        this.$store.dispatch('setNavData', { title, backPath: '/studies' });
      }
    },
    async getStudyPatients(id) {
      const result = await api.post('/studies/getStudyPatients', { id });
      this.patients = result.data;
    },
    async deleteStudyPatient(patientId) {
      try {
        await api.post('/studies/deleteStudyPatient', { studyId: this.id, patientId })
        this.patients = this.patients.filter(p => p.id !== patientId)
        // Show confirmation message.
        this.$snotify.success('Remove the patient from the study', 'Removed');
      } catch (err) {
        this.$snotify.error('Error while saving the study', 'Error');
      }

    }
  },
};
</script>

<style scoped>
.page-section {
  padding-top: 20px;
}
</style>
