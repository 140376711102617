<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12>
        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <p>Create inpatient days, for each day within a date range.</p>

              <v-form ref="ipDateRangeForm">
                <v-row>
                  <v-col cols="3">
                    <v-menu
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="fromDateFormatted"
                          label="From Date"
                          prepend-icon="mdi-calendar"
                          hint="M/D/YYYY"
                          persistent-hint
                          readonly
                          required
                          :rules="[v => !!v || 'Required']"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        no-title
                        @input="fromDateMenu = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="3">
                    <v-menu
                      v-model="toDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      full-width
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="toDateFormatted"
                          label="To Date"
                          prepend-icon="mdi-calendar"
                          hint="M/D/YYYY"
                          persistent-hint
                          readonly
                          required
                          :rules="[v => !!v || 'Required']"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="toDate"
                        no-title
                        @input="toDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>

                <!-- button row -->
                <v-layout row class="action-row">
                  <v-spacer />
                  <v-btn
                    :to="`/ipDays/${siteId}/${patient.id}`"
                    class="spaced-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="!maySave"
                    :loading="saving"
                    @click.prevent="save"
                  >
                    Save
                  </v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';

export default {
  name: 'IpDateRange',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true }
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      patient: {},
      fromDate: '',
      toDate: '',
      fromDateMenu: false,
      toDateMenu: false,
      saving: false
    };
  },
  async created() {
    if (this.patientId) {
      await this.getPatientIds(this.patientId);
    }
  },
  methods: {
    async getPatientIds() {
      const result = await api.post('/patients/getPatientIds', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      const patient = result.data;
      this.patient = patient;

      this.breadcrumbItems.push({
        text: this.patient.mrn,
        disabled: false,
        to: `/ipDays/${this.siteId}/${this.patientId}`
      });
      this.breadcrumbItems.push({
        text: 'Inpatient Days',
        disabled: true
      });

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', {
        title: `${this.patient.mrn} Date Range`,
        backPath: `/ipDateRange/${this.siteId}/${this.patientId}`
      });

      return patient;
    },
    formatDate(d) {
      return d ? moment(d).format('M/D/YYYY') : '';
    },
    async save() {
      this.saving = true;
      await api.post('/inpatients/saveIpDateRange', {
        siteId: this.siteId,
        patientId: this.patientId,
        fromDate: this.fromDate,
        toDate: this.toDate
      });
      this.saving = false;
      this.$snotify.success('Saved the Inpatient Date Range', 'Saved');
      this.$router.push(`/ipDays/${this.siteId}/${this.patientId}`);
    }
  },
  computed: {
    title() {
      let title = `Inpatient Date Range`;
      if (this.patient.mrn) {
        title += ` for ${this.patient.mrn}`;
      }
      return title;
    },
    fromDateFormatted() {
      return this.formatDate(this.fromDate);
    },
    toDateFormatted() {
      return this.formatDate(this.toDate);
    },
    maySave() {
      return this.fromDate && this.toDate;
    }
  }
};
</script>
