import Vue from 'vue';
import Vuex from 'vuex';
import api from './api';

// Get the app version from the package.json file.
// The envirnment variable was inserted in the vue.config.js file.
const appVersion = require('../package.json').version;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appVersion,
    user: null,

    // navigation data
    navData: {
      title: '',
      backPath: '',
    },

    patientFilter: {
      siteId: null,
      fromDate: '',
      toDate: '',
      query: '',
    },
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    clearUser: (state) => {
      state.user = null;
      // Clear the nav data.
      state.navData = {
        title: '',
        backPath: '',
      };
    },
    setNavData: (state, navData) => {
      state.navData = navData;
    },
    setPatientFilter: (state, filter) => {
      state.patientFilter = filter;
    },
  },
  actions: {
    async getSessionUser({ commit }) {
      const response = await api.post('/auth/getSessionUser');
      const user = response.data;
      if (user) {
        commit('setUser', user);
      }
    },
    async setUser({ commit }, user) {
      commit('setUser', user);
    },
    async logout({ commit }) {
      // Call the logout API function to destroy the server session.
      await api.post('/auth/logout');
      // const sess = this.$router.app.$session;
      commit('clearUser');
    },
    setNavData({ commit }, navData) {
      commit('setNavData', navData);
    },
    setPatientFilter({ commit }, filter) {
      commit('setPatientFilter', filter);
    },
  },
  getters: {
    isLoggedIn: (state) => Boolean(state.user),
  },
});
