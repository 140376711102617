<template>
  <v-form>
    <v-layout wrap row>
      <v-flex xs12>
        <table>
          <tbody>
            <tr class="radio-row">
              <td>
                <p>Were you working before your injury?</p>
              </td>
              <td>
                <v-radio-group
                  v-model="qol.working_before"
                  row
                  class="yes-no-radio"
                >
                  <v-radio label="Yes" :value="true" />
                  <v-radio label="No" :value="false" />
                </v-radio-group>
              </td>
            </tr>
            <tr class="radio-row">
              <td>
                <p>Are you currently working?</p>
              </td>
              <td>
                <v-radio-group
                  v-model="qol.working_now"
                  row
                  class="yes-no-radio"
                >
                  <v-radio label="Yes" :value="true" />
                  <v-radio label="No" :value="false" />
                </v-radio-group>
              </td>
            </tr>
          </tbody>
        </table>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <v-select
          :items="workOptions"
          label="Please select option that best describes you."
          v-model="qol.work_option"
        />
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12 md6>
        <v-select
          :items="ribPainOptions"
          label="Over the last month, my rib pain has"
          v-model="qol.rib_pain"
        />
      </v-flex>
    </v-layout>

    <p class="section-start">
      Please slide each slider so that it best describes you.
    </p>

    <v-layout
      row
      wrap
      v-for="option in sliderOptions"
      v-bind:key="option.field"
    >
      <v-flex xs12 md4 :key="`min-${option.field}`">
        {{ option.minText }}
      </v-flex>
      <v-flex xs12 md4 :key="`slider-${option.field}`">
        <v-slider
          v-model="qol[option.field]"
          min="1"
          max="6"
          step="1"
          thumb-label
          ticks
          height="10"
        />
      </v-flex>
      <v-flex xs12 md4 :key="`max-${option.field}`">
        {{ option.maxText }}
      </v-flex>
    </v-layout>

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn
        :to="`/opVisits/${siteId}/${qol.patient_id}`"
        class="spaced-button"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
export default {
  name: 'OpQolForm',
  props: {
    siteId: { type: Number, required: true },
    qol: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      workOptions: [
        'Stay in bed all day, feel helpless and hopeless about life',
        'Stay in bed at least half the day, have no contact with the outside world',
        "Get out of bed but don't get dressed. Stay at home all day",
        'Get dressed in the morning. Minimal activities at home. Contact with friends via e-mail, phone',
        'Do simple chores around the house. Minimal activities outside of home two days a week',
        'Struggle but fulfill daily home responsibilities. No outside activity. Not able to work/volunteer',
        'Work/volunteer limited hours. Take part in limited social activities on weekends.',
        'Work/volunteer for a few hours daily. Can be active at least five hours a day. Can make plans to do simple activities on weekends',
        'Work/volunteer at least six hours daily. Have energy to make plans for one evening social activity during the week. Active on weekends',
        'Work/volunteer/active eight hours a daily. Take part in family life. Outside social activities limited.',
        'Go to work/volunteer each day. Normal daily activities each day. Have a social life outside of work. Take an active part in family life.'
      ],
      ribPainOptions: ['Increased', 'Stayed the same', 'Decreased'],
      sliderOptions: [
        {
          field: 'health_before',
          minText: 'I had EXCELLENT health before I broke my ribs',
          maxText: 'I was in POOR health before I broke my ribs'
        },
        {
          field: 'health_after',
          minText: 'I am back to my NORMAL health since before injury',
          maxText: 'My health is much WORSE after my rib injury'
        },
        {
          field: 'chest_tight',
          minText: 'My chest does not feel tight at all',
          maxText: 'My chest feels very tight'
        },
        {
          field: 'stairs_breath',
          minText: 'I walk up a flight of stairs and am not breathless',
          maxText: 'I walk up a flight of stairs and am very breathless'
        },
        {
          field: 'mucus',
          minText: 'I have NO mucus in my chest at all',
          maxText: 'My chest is completely FULL of mucus'
        },
        {
          field: 'cough',
          minText: 'I NEVER cough',
          maxText: 'I cough ALL the time'
        },
        {
          field: 'sleep',
          minText: 'I sleep soundly',
          maxText: "I don't sleep because of my chest condition"
        },
        {
          field: 'energy',
          minText: 'I have lots of energy',
          maxText: 'I have no energy at all'
        },
        {
          field: 'leaving_home',
          minText: 'I am CONFIDENT leaving home',
          maxText: 'I am NOT at all confident leaving my home'
        },
        {
          field: 'emotional_stop',
          minText:
            'Since my injury, emotional problems NEVER stop me from doing normal daily activities',
          maxText:
            'Since my injury, I have emotional problems that ALWAYS stop me from doing my normal daily activities'
        },
        {
          field: 'physical_stop',
          minText:
            'Since my injury, physical problems like pain and muscle weakness, NEVER stop me from doing normal daily activities',
          maxText:
            'Since my injury, physical problems like pain and muscle weakness, ALWAYS stop me from doing my normal daily activities'
        }
      ]
    };
  }
};
</script>

<style scoped>
.yes-no-radio {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 25px;
  max-height: 25px;
}
.radio-row {
  max-height: 20px;
}
.section-start {
  margin-top: 30px;
}
</style>
