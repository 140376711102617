<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>CIID Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <LoginForm v-if="step === 'login'" v-on:valid="handleValid" v-on:tempValid="handleTempValid"
              v-on:locked="handleLocked" v-on:forgot="handleForgot" v-on:archived="handleArchived" />

            <!-- account locked message -->
            <div v-else-if="step === 'locked'">
              <v-alert type="error" text elevation="2">
                <p>Your account has been locked for your safety.</p>
                <p>
                  Check your email for instructions on how to request for it to
                  be unlocked.
                </p>
              </v-alert>

              <!-- button row -->
              <v-row class="action-row">
                <v-spacer />
                <v-btn to="/" class="spaced-button" color="primary">OK</v-btn>
              </v-row>
            </div>

            <!-- account archived message -->
            <div v-else-if="step === 'archived'">
              <v-alert type="error" text elevation="2">
                <p>
                  Your account has been archived. Please contact your local CIID
                  site admin in order to re-activate your account.
                </p>
              </v-alert>

              <!-- button row -->
              <v-row class="action-row">
                <v-spacer />
                <v-btn to="/" class="spaced-button" color="primary">OK</v-btn>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
import shouldShowCiidAnalytics from '../utils/shouldShowCiidAnalytics';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {
    return {
      step: 'login',
      user: null,
    };
  },
  methods: {
    async handleValid(user) {
      await this.$store.dispatch('setUser', user);
      const defaultPage = shouldShowCiidAnalytics(user)
        ? '/ciidAnalytics'
        : '/analytics';
      this.$router.push(defaultPage);
    },
    async handleTempValid(user) {
      await this.$store.dispatch('setUser', user);
      // Go to the set password page.
      this.$router.push('/setPassword');
    },
    handleLocked() {
      this.step = 'locked';
    },
    handleArchived() {
      this.step = 'archived';
    },
    handleForgot() {
      // Go to the forgot password page.
      this.$router.push('/forgotPassword');
    },
  },
};
</script>

<style scoped>
#login-alert {
  margin-bottom: 20px;
}
</style>
