<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md8>
        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline page-title">{{ title }}</div>

              <OpVisitForm
                :siteId="siteId"
                :patient="patient"
                :visit="visit"
                :dateConflict="dateConflict"
                :saving="saving"
                @save="save"
                :deleting="deleting"
                @delete="deleteVisit"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import OpVisitForm from '@/components/OpVisitForm.vue';

export default {
  name: 'OpVisit',
  components: {
    OpVisitForm
  },
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true },
    visitId: { type: Number, required: false }
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      patient: {},
      gettingVisit: false,
      visit: {},
      usedDates: [],
      saving: false,
      deleteDialog: false,
      deleting: false
    };
  },
  async created() {
    if (this.patientId) {
      await this.getPatientIds();
      if (this.visitId) {
        this.getVisit();
      }
      // Make sure that the visit has the patient ID.
      if (!this.visit.patient_id) {
        this.visit.patient_id = this.patientId;
      }
      this.getUsedDates(this.patientId, this.visitId);
    }
  },
  methods: {
    async getPatientIds() {
      const result = await api.post('/patients/getPatientIds', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      const patient = result.data;
      this.patient = patient;

      this.breadcrumbItems.push({
        text: this.patient.mrn,
        disabled: false,
        to: `/opVisits/${this.siteId}/${this.patientId}`
      });
      this.breadcrumbItems.push({
        text: 'Follow-up Visit',
        disabled: true
      });

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', {
        title: `${this.patient.mrn} Visit`,
        backPath: `/opVisits/${this.siteId}/${this.patientId}`
      });

      return patient;
    },
    async getVisit() {
      this.gettingVisit = true;
      const result = await api.post('/outpatients/getOpVisit', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.visit = result.data;
      this.gettingVisit = false;
    },
    async getUsedDates() {
      const result = await api.post('/outpatients/getOpVisits', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      const visits = result.data;
      // Get the dates of the patient's other outpatient visits.
      this.usedDates = visits
        .filter(v => v.id !== this.visitId)
        .map(v => moment(v.visit_date).format('YYYY-MM-DD'));
    },
    async save() {
      this.saving = true;
      await api.post('/outpatients/saveOpVisit', {
        siteId: this.siteId,
        visit: this.visit
      });
      this.saving = false;
      this.$snotify.success('Saved the Follow-up Visit', 'Saved');
      this.$router.push(`/opVisits/${this.siteId}/${this.patientId}`);
    },
    async deleteVisit() {
      this.deleting = true;
      await api.post('/outpatients/deleteOpVisit', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.deleting = false;
      this.$snotify.success('Deleted the Follow-up Visit', 'Deleted');
      this.$router.push(`/opVisits/${this.siteId}/${this.patientId}`);
    }
  },
  computed: {
    title() {
      const verb = this.visitId ? 'Edit' : 'Add';
      let title = `${verb} Follow-up Visit`;
      if (this.patient.mrn) {
        title += ` for ${this.patient.mrn}`;
      }
      return title;
    },
    dateConflict() {
      return (
        this.visit.visit_date && this.usedDates.includes(this.visit.visit_date)
      );
    },
    maySave() {
      return this.visit.visit_date && !this.dateConflict;
    }
  }
};
</script>
