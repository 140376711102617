import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    rawData: { type: Array },
    title: { type: String, required: true },
    yLabel: { type: String, required: true },
    yesLabel: { type: String, required: true },
    noLabel: { type: String, required: true }
  },
  data: () => ({}),
  methods: {
    makeChartData(rawData, yesLabel, noLabel) {
      const labels = rawData.map(d => d.name);
      const yesData = rawData.map(d => d.yes);
      const noData = rawData.map(d => d.no);

      return {
        labels,
        datasets: [
          {
            label: yesLabel,
            backgroundColor: 'rgba(23, 118, 210, 0.7)',
            data: yesData
          },
          {
            label: noLabel,
            backgroundColor: 'rgba(210, 23, 25, 0.6)',
            data: noData
          }
        ]
      };
    },
    makeOptions(title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.yLabel
              },
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        },
        title: {
          display: true,
          text: title
        }
      };
    }
  },

  mounted() {
    const chartdata = this.makeChartData(
      this.rawData,
      this.yesLabel,
      this.noLabel
    );
    const options = this.makeOptions(this.title);
    this.renderChart(chartdata, options);

    // this.renderChart(this.chartdata, this.options);
  }
};
