import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    rawData: { type: Object, required: true },
    title: { type: String, required: true },
    xLabel: { type: String, required: true },
    yLabel: { type: String, required: true },
  },
  data: () => ({}),
  methods: {
    makeChartData(rawData) {
      // a color palette to choose colors from
      const colors = [
        '#5FA0E0',
        '#DFA5FF',
        '#66BAA8',
        '#7A689A',
        '#19859C',
        '#00C5FA',
        '#4D8075',
        '#CD00A3',
        '#FF006E',
        '#FF6C45',
        '#FFB93D',
        '#F9F871',
        '#F9EAFF',
        '#BEA6A1',
        '#0074BB',
        '#005938',
        '#009166',
        '#7E4F00',
        '#B84F00'
      ];

      const data = rawData;

      // Add the dataset colors.
      data.datasets.forEach((d, index) => {
        d.borderColor = colors[ index % colors.length ];
        d.fill = false;
      });

      return data;
    },
    makeOptions(title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.xLabel
              },
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.yLabel
              },
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        },
        title: {
          display: true,
          text: title
        }
      };
    }
  },

  mounted() {
    const chartdata = this.makeChartData(this.rawData);
    const options = this.makeOptions(this.title);
    this.renderChart(chartdata, options);
  }
};
