<template>
  <v-data-table :headers="headers" :items="days" :items-per-page="10">
    <!-- hospital date -->
    <template v-slot:item.hospital_date="{ item }">
      <router-link :to="`/ipDay/${siteId}/${item.patient_id}/${item.id}`">
        {{ usaDate(item.hospital_date) }}
      </router-link>
    </template>

    <!-- pain meds -->
    <template v-slot:item.has_pain_meds="{ item }">
      <v-btn
        :color="item.has_pain_meds ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/ipPainMeds/${siteId}/${item.id}`"
      >
        {{ item.has_pain_meds ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- daily outcomes -->
    <template v-slot:item.has_daily_outcomes="{ item }">
      <v-btn
        :color="item.has_daily_outcomes ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/ipDailyOutcomes/${siteId}/${item.id}`"
      >
        {{ item.has_daily_outcomes ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- additional outcomes -->
    <template v-slot:item.has_additional_outcomes="{ item }">
      <v-btn
        :color="item.has_additional_outcomes ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/ipAdditionalOutcomes/${siteId}/${item.id}`"
      >
        {{ item.has_additional_outcomes ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- PFTs -->
    <template v-slot:item.has_pfts="{ item }">
      <v-btn
        :color="item.has_pfts ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/ipPfts/${siteId}/${item.id}`"
      >
        {{ item.has_pfts ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- comment -->
    <template v-slot:item.comment="{ item }">
      <td
        @click="$router.push(`/ipDay/${siteId}/${item.patient_id}/${item.id}`)"
      >
        {{ item.comment }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'IpDaysTable',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true },
    days: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Date', value: 'hospital_date' },
        { text: 'Hospital Day', value: 'day_num' },
        { text: 'Pain Medications', value: 'has_pain_meds' },
        { text: 'Daily Outcomes', value: 'has_daily_outcomes' },
        { text: 'Additional Outcomes', value: 'has_additional_outcomes' },
        { text: 'PFTs', value: 'has_pfts' },
        { text: 'Comments', value: 'comment' }
      ]
    };
  },
  methods: {
    usaDate(d) {
      return moment(d).format('M/D/YYYY');
    }
  }
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
