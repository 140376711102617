<template>
  <v-container>
    <v-layout fluid>
      <v-flex fluid>
        <v-card id="users-list" class="elevation-6 mx-auto" max-width="800px">
          <v-card-text>
            <v-container>
              <!-- user form -->
              <v-form>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      id="First name"
                      label="First name"
                      v-model="user.first_name"
                      :rules="[(v) => !!v || 'Required']"
                      required
                      v-on:keyup.enter="save"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      id="Last name"
                      label="Last name"
                      v-model="user.last_name"
                      :rules="[(v) => !!v || 'Required']"
                      required
                      v-on:keyup.enter="save"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      id="Email"
                      label="Email"
                      v-model="user.email"
                      required
                      v-on:keyup.enter="save"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="showSiteSelect"
                    class="flex-shrink-1"
                  >
                    <v-select
                      :items="sites"
                      label="Site"
                      v-model="user.site_id"
                      item-value="id"
                      item-text="name"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="flex-shrink-1">
                    <v-select :items="roles" label="Role" v-model="user.role" />
                  </v-col>
                </v-row>

                <v-row v-if="showExportCheckbox">
                  <v-col cols="12" md="6" class="flex-shrink-1">
                    <v-select
                      :items="accountStatuses"
                      label="Account Status"
                      v-model="user.account_status"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="user.may_export"
                      label="May export data"
                      type="checkbox"
                    />
                  </v-col>
                </v-row>
              </v-form>

              <!-- button row -->
              <v-layout row class="action-row">
                <v-btn
                  v-if="user.id"
                  small
                  text
                  @click="dialog = true"
                  class="del-btn"
                >
                  Delete
                </v-btn>
                <v-spacer />
                <v-btn to="/users">Cancel</v-btn>
                <v-btn
                  color="primary"
                  :loading="saving"
                  :disabled="!maySave"
                  @click.prevent="save"
                  class="spaced-button"
                  type="submit"
                >
                  Save
                </v-btn>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- delete confirmation dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Confirmation for Deleting User
        </v-card-title>

        <v-card-text>
          <p class="confirm-p">
            Are you sure that you want to delete user {{ user.first_name }}
            {{ user.last_name }}?
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="dialog = false">Cancel</v-btn>

          <v-spacer />
          <v-btn color="error" @click="deleteUser">Yes, Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
  name: 'User',
  props: ['id'],
  data() {
    return {
      sites: [],
      roles: ['Site admin', 'user'],
      accountStatuses: ['active', 'locked', 'archived'],
      // default for a new, blank user
      user: {
        first_name: '',
        last_name: '',
        email: '',
        site_id: '',
        role: 'user',
        account_status: 'active',
      },
      saving: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.user,
    }),
    showSiteSelect() {
      return this.authUser && this.authUser.role === 'CIID admin';
    },
    showExportCheckbox() {
      return this.authUser && this.authUser.role === 'CIID admin';
    },
    maySave() {
      return (
        this.user.first_name &&
        this.user.last_name &&
        this.user.site_id &&
        this.user.role
      );
    },
  },
  async created() {
    if (this.id) {
      // Convert the param ID to an integer.
      const id = parseInt(this.id, 10);
      await this.getUser(id);
    }
    let title = 'User';
    if (this.user.id) {
      title = `User: ${this.user.first_name} ${this.user.last_name}`;
    } else {
      title = 'Add new user';
    }
    this.$store.dispatch('setNavData', { title, backPath: '/users' });

    // By default, assume the new user works at the same hospital at the auth user.
    if (this.authUser && !this.user.site_id) {
      this.user = {
        ...this.user,
        site_id: this.authUser.site_id,
      };
    }
    if (this.authUser && this.authUser.role === 'CIID admin') {
      this.getSites();
      this.roles = ['CIID admin', ...this.roles];
    }
  },
  methods: {
    async getSites() {
      const result = await api.post('/sites/getSites');
      const sites = result.data;
      if (sites && sites.length > 0) {
        this.sites = sites;
      }
    },
    async getUser(id) {
      const result = await api.post('/users/getUser', { id });
      if (result.data) {
        this.user = result.data;
      }
    },
    async save() {
      if (!this.maySave) {
        return;
      }
      this.saving = true;
      await api.post('/users/saveUser', { user: this.user });
      this.saving = false;
      // Show confirmation message.
      this.$snotify.success('Saved the User', 'Saved');
      // Go back to the users list page.
      this.$router.push('/users');
    },
    async deleteUser() {
      this.saving = true;
      await api.post('/users/deleteUser', { id: this.user.id });
      this.saving = false;
      // Show confirmation message.
      this.$snotify.success('Deleted the User', 'Deleted');
      // Go back to the users list page.
      this.$router.push('/users');
    },
  },
};
</script>
