<template>
  <v-container>
    <v-form ref="injuriesForm">
      <!-- section header row -->
      <v-layout row>
        <v-col>
          <h3 class="section-title">Rib & Sternal Fractures</h3>
        </v-col>

        <v-col>
          <v-layout row v-if="hadRepeatFx">
            <v-col>
              <h3 class="section-title">Repeat Fractures</h3>
            </v-col>
            <v-col>
              <v-btn
                color="grey darken-1"
                outlined
                @click="clearRepeatFractures"
              >
                Remove Repeat Fxs
              </v-btn>
            </v-col>
          </v-layout>
          <v-btn
            v-else
            color="grey darken-1"
            outlined
            @click="hadRepeatFx = true"
          >
            Add Repeat Fractures
          </v-btn>
        </v-col>
      </v-layout>

      <!-- repat fractures controls row -->
      <v-layout row v-if="hadRepeatFx">
        <!-- empty for the initial fractures side -->
        <v-col></v-col>

        <!-- repeat fractures side -->
        <v-col>
          <v-layout row>
            <v-col>
              <!-- Repeat fractures date picker -->
              <v-menu
                v-model="repeatFxDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="repeatFxDateFormatted"
                    label="Repeat Fxs Date"
                    prepend-icon="mdi-calendar"
                    hint="MM/DD/YYYY"
                    persistent-hint
                    required
                    :rules="repeatFxDateRules"
                    read-only
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="patient.repeat_fractures_date"
                  @input="repeatFxDateMenu = false"
                />
              </v-menu>
            </v-col>

            <v-col>
              <v-btn @click="copyOrigFractures">Copy Orig Fxs</v-btn>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

      <!-- fracture grid row -->
      <v-layout row>
        <v-col>
          <FracturesGrid
            :title="'Rib & Sternal Fractures'"
            :fractures="fractures"
            :showLegends="true"
            @addFracture="addFracture"
            @removeFracture="removeFracture"
          />
        </v-col>

        <v-col>
          <FracturesGrid
            v-if="hadRepeatFx"
            title="Repeat Fractures"
            :fractures="repeatFractures"
            :showLegends="false"
            @addFracture="addRepeatFracture"
            @removeFracture="removeRepeatFracture"
          />
        </v-col>
      </v-layout>

      <!-- scoring row -->
      <v-layout row>
        <v-col>
          <div class="rib-score-container">
            <v-alert :value="true" color="info" text class="score-alert">
              Rib score: {{ patient.rib_score || 0 }}
            </v-alert>
          </div>
        </v-col>

        <v-col v-if="hadRepeatFx">
          <div class="rib-score-container">
            <v-alert :value="true" color="info" text class="score-alert">
              Rib score: {{ patient.repeat_rib_score || 0 }}
            </v-alert>
          </div>
        </v-col>
      </v-layout>

      <v-divider class="divider" />

      <v-layout wrap row class="first-row">
        <v-flex xs12 md3>
          <span v-if="!patient.injury_date" class="red--text text--accent-2">
            Required
          </span>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="dateFormatted"
                label="Injury Date"
                prepend-icon="mdi-calendar"
                hint="MM/DD/YYYY"
                persistent-hint
                required
                :rules="injuryDateRules"
                read-only
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="patient.injury_date"
              @input="dateMenu = false"
            />
          </v-menu>
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-select
            :items="mechanismOptions"
            label="Mechanism of Injury"
            v-model="patient.mechanism"
          />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-textarea
            name="mechDetails"
            label="Details for Mechanism of Injury"
            v-model="patient.mechanism_note"
            rows="2"
            auto-grow
          />
        </v-flex>
      </v-layout>

      <!-- calculator row -->
      <v-layout wrap row>
        <v-flex xs12 md3>
          <div class="calc-btn-container">
            <v-btn color="grey darken-1" outlined @click="bpc18Dialog = true">
              <v-icon>mdi-calculator</v-icon>
              <span v-if="patient.bpc18">
                BPC18 score: {{ patient.bpc18 }}
              </span>
              <span v-else> Calculate BPC18 </span>
            </v-btn>
          </div>
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-text-field
            label="Injury Severity Score"
            type="number"
            min="0"
            max="75"
            prepend-icon="mdi-calculator"
            @click:prepend="issDialog = true"
            :rules="issRules"
            v-model="patient.iss"
          />
        </v-flex>
      </v-layout>

      <v-divider class="divider" />

      <h3>Other injuries</h3>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox v-model="patient.ich" label="Intracranial hemorrhage" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.face_fracture" label="Face fracture" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.clavicle_fracture"
            label="Clavicle fracture"
          />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.scapula_fracture"
            label="Scapula fracture"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.spine_fracture" label="Spine fracture" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.sci" label="Spinal cord injury" />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.pelvis_fracture"
            label="Pelvis fracture"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.long_bone_fracture"
            label="Long bone fracture"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.solid_organ"
            label="Solid organ injury"
          />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.bcvi"
            label="Blunt cerebrovascular injury"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.hemothorax"
            label="Hemothorax (on admission)"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.pneumothorax"
            label="Pneumothorax (on admission)"
          />
        </v-flex>
      </v-layout>

      <v-divider class="divider" />

      <h3>Emergent procedures</h3>

      <!-- checkbox row -->
      <v-layout row wrap class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.laparotomy"
            label="Emergent Laparotomy"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.thoracotomy"
            label="Emergent Thoracotomy"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.craniotomy"
            label="Emergent Craniotomy"
          />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout row wrap class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.pelvic_stabilization"
            label="Emergent Pelvic Stabilization"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.revascularization"
            label="Emergent Revascularization"
          />
        </v-flex>
      </v-layout>

      <v-divider class="divider" />

      <!-- button row -->
      <v-layout row class="action-row">
        <v-btn @click="handleBack">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn to="/patients" class="spaced-button">Cancel</v-btn>
        <v-spacer />
        <v-btn
          :loading="saving"
          @click.prevent="handleSave"
          class="spaced-button"
          :disabled="!valid"
        >
          Save & Close
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :loading="saving"
          @click.prevent="handleNext"
          :disabled="!valid"
        >
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>

      <!-- BPC18 pop-up dialog -->
      <v-dialog
        v-if="patient.id"
        v-model="bpc18Dialog"
        max-width="500"
        @keydown.esc="cancelBpc18"
      >
        <bpc18-form
          :siteId="siteId"
          :patientId="patient.id"
          @cancel="cancelBpc18"
          @save="setBpc18"
        />
      </v-dialog>

      <!-- ISS pop-up dialog -->
      <v-dialog
        v-if="patient.id"
        v-model="issDialog"
        max-width="500"
        @keydown.esc="cancelIss"
      >
        <iss-form :patientId="patient.id" @cancel="cancelIss" @save="setIss" />
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import api from '@/api.js';
import { calculateRibScore } from '@/utils/calculateRibScore.js';
import FracturesGrid from './FracturesGrid.vue';
import Bpc18Form from './Bpc18Form.vue';
import IssForm from './IssForm.vue';

export default {
  name: 'InjuriesForm',
  components: {
    FracturesGrid,
    Bpc18Form,
    IssForm,
  },
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: false },
    patient: { type: Object, required: true },
    saving: Boolean,
  },
  data() {
    return {
      fractures: [],
      hadRepeatFx: false,
      repeatFractures: [],
      bpc18Dialog: false,
      issDialog: false,
      mechanismOptions: [
        'Motor vehicle crash',
        'Motorcycle crash',
        'Fall',
        'Auto vs. Pedestrian',
        'Skiing accident',
        'Biking accident',
        'Other',
      ],
      dateMenu: false,
      repeatFxDateMenu: false,
      injuryDateRules: [
        (value) => !!value || 'Required',
        (d) => moment(d, 'MM/DD/YYYY', true).isValid() || 'MM/DD/YYYY',
        d => moment(d) <= moment() || 'Cannot be in future'
      ],
      repeatFxDateRules: [
        (d) => moment(d, 'MM/DD/YYYY', true).isValid() || 'MM/DD/YYYY',
      ],
      issRules: [
        g => g >= 0 || 'Must be in range 0 to 75',
        g => g <= 75 || 'Must be in range 0 to 75',
      ],
    };
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.patient.injury_date);
    },
    repeatFxDateFormatted() {
      return this.formatDate(this.patient.repeat_fractures_date);
    },
    valid() {
      return Boolean(this.patient.injury_date);
    },
  },
  async created() {
    if (this.patientId) {
      const promises = [this.getFractures(), this.getRepeatFractures()];
      await Promise.all(promises);

      // Check whether we have any repeat fractures data.
      this.hadRepeatFx =
        this.patient.repeat_fractures_date || this.repeatFractures.length > 0;
    }
  },
  methods: {
    formatDate(dateVal) {
      return dateVal ? moment(dateVal).format('MM/DD/YYYY') : '';
    },
    // ----- fractures section -----
    // Get the patient's fractures.
    async getFractures() {
      const result = await api.post('/patients/getFractures', {
        siteId: this.siteId,
        patientId: this.patientId,
      });
      this.fractures = result.data;
    },
    async getRepeatFractures() {
      const result = await api.post('/patients/getRepeatFractures', {
        siteId: this.siteId,
        patientId: this.patientId,
      });
      this.repeatFractures = result.data;
    },
    // Find a fracture by its position (rib number, side, and location).
    findFractureIndex(fracture) {
      if (fracture.id) {
        // Search by ID.
        const index = this.fractures.findIndex((f) => f.id === fracture.id);
        if (index > -1) {
          return index;
        }
      }

      // Search by the postition.
      return this.fractures.findIndex(
        (f) =>
          f.rib === fracture.rib &&
          f.side === fracture.side &&
          f.location === fracture.location
      );
    },
    addFracture(fracture) {
      // Make sure that the fracture has the patient ID.
      if (!fracture.patient_id) {
        fracture.patient_id = this.patient.id;
      }
      const index = this.findFractureIndex(fracture);
      if (index > -1) {
        this.fractures[index] = fracture;
      } else {
        this.fractures.push(fracture);
      }

      this.patient.rib_score = calculateRibScore(this.fractures);
    },
    addRepeatFracture(fracture) {
      // Make sure that the fracture has the patient ID.
      if (!fracture.patient_id) {
        fracture.patient_id = this.patient.id;
      }
      const index = this.findFractureIndex(fracture);
      if (index > -1) {
        this.repeatFractures[index] = fracture;
      } else {
        this.repeatFractures.push(fracture);
      }

      this.patient.repeat_rib_score = calculateRibScore(this.repeatFractures);
    },
    removeFracture(fracture) {
      const index = this.findFractureIndex(fracture);
      if (index > -1) {
        this.fractures = [
          ...this.fractures.slice(0, index),
          ...this.fractures.slice(index + 1),
        ];
      }

      this.patient.rib_score = calculateRibScore(this.fractures);
    },
    removeRepeatFracture(fracture) {
      const index = this.findFractureIndex(fracture);
      if (index > -1) {
        this.repeatFractures = [
          ...this.repeatFractures.slice(0, index),
          ...this.repeatFractures.slice(index + 1),
        ];
      }

      this.patient.repeat_rib_score = calculateRibScore(this.repeatFractures);
    },
    clearRepeatFractures() {
      this.repeatFractures = [];
      this.hadRepeatFx = false;
    },
    async saveFractures() {
      const result = await api.post('/patients/saveFractures', {
        siteId: this.siteId,
        patientId: this.patientId,
        fractures: this.fractures,
      });
      this.fractures = result.data;
    },
    async saveRepeatFractures() {
      const result = await api.post('/patients/saveRepeatFractures', {
        siteId: this.siteId,
        patientId: this.patientId,
        fractures: this.repeatFractures,
      });
      this.repeatFractures = result.data;
    },

    // Initialize the repeat fractures from the original ones.
    copyOrigFractures() {
      // Copy the fractures.
      const fracs = cloneDeep(this.fractures);
      // Remove the IDs and timestamps, so they don't cause problems when saving the fractures.
      for (let f of fracs) {
        delete f.id;
        delete f.created_at;
        delete f.updated_at;
      }
      this.repeatFractures = fracs;
      // Copy the rib score.
      this.patient.repeat_rib_score = this.patient.rib_score;
    },

    // ----- BPC18 section -----
    cancelBpc18() {
      this.bpc18Dialog = false;
    },
    setBpc18(val) {
      this.patient.bpc18 = val;
      this.bpc18Dialog = false;
    },

    // ----- ISS section -----
    cancelIss() {
      this.issDialog = false;
    },
    setIss(val) {
      this.patient.iss = val;
      this.issDialog = false;
    },

    // ----------- save section -----------
    saveFormData() {
      this.saveFractures();
      this.saveRepeatFractures();
    },
    handleBack() {
      this.saveFormData();
      this.$emit('back');
    },
    handleNext() {
      this.saveFormData();
      this.$emit('next');
    },
    handleSave() {
      this.saveFormData();
      this.$emit('saveAndClose');
    },
  },
};
</script>

<style scoped>
.first-row {
  margin-top: 20px;
}
.section-title {
  margin-bottom: 20px;
}
.divider {
  margin-top: 20px;
  margin-bottom: 10px;
}
.calc-btn-container {
  padding-top: 12px;
}
.rib-score-container {
  max-width: 200px;
}
</style>
