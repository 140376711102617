<template>
  <div>
    <h3>Study: {{ study.name }}</h3>

    <div class="form-section">Sites: {{ sitesString }}</div>

    <p v-if="study.comment" class="form-section">
      Comment: "{{ study.comment }}"
    </p>

    <p v-if="study.archived" class="form-section">Archived</p>

    <div class="form-section">
      <v-btn :to="`/editStudy/${study.id}`">Edit</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudySummary',
  props: {
    study: { type: Object, required: true },
  },
  computed: {
    sitesString() {
      if (!this.study?.sites) {
        return '';
      }
      return this.study.sites.map((s) => s.name).join(', ');
    },
  },
};
</script>

<style scoped>
.form-section {
  padding-top: 15px;
}
</style>
