import { Bar } from 'vue-chartjs';
// import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: Bar,
  // extends: HorizontalBar,
  props: {
    rawData: { type: Array }
  },
  data: () => ({
    // chartdata: {
    //   labels: ['Site A', 'Site B', 'Site C'],
    //   datasets: [
    //     {
    //       label: 'Entered',
    //       backgroundColor: 'rgba(23, 118, 210, 0.7)',
    //       data: [30, 21, 0]
    //     },
    //     {
    //       label: 'Needs entering',
    //       backgroundColor: 'rgba(210, 23, 25, 0.6)',
    //       data: [30, 21, 5]
    //     }
    //   ]
    // },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Operative Patients Entered'
            },
            ticks: {
              suggestedMin: 0
            }
          }
        ]
      },
      title: {
        display: true,
        text: 'Fracture Grid Status'
      }
    }
  }),
  methods: {
    makeChartData(rawData) {
      const labels = rawData.map(d => d.name);
      const yesData = rawData.map(d => d.has_fx);
      const noData = rawData.map(d => d.no_fx);

      return {
        labels,
        datasets: [
          {
            label: 'Has grid',
            backgroundColor: 'rgba(23, 118, 210, 0.7)',
            data: yesData
          },
          {
            label: 'Needs grid',
            backgroundColor: 'rgba(210, 23, 25, 0.6)',
            data: noData
          }
        ]
      };
    }
  },

  mounted() {
    const chartdata = this.makeChartData(this.rawData);
    this.renderChart(chartdata, this.options);

    // this.renderChart(this.chartdata, this.options);
  }
};
