<template>
  <v-data-table :headers="headers" :items="sites" :items-per-page="10">
    <!-- USA vs international location -->
    <template v-slot:item.international="{ item }">
      {{ item.international ? 'International' : 'USA' }}
    </template>

    <!-- last patient date -->
    <template v-slot:item.last_date="{ item }">
      {{ usaDate(item.last_date) }}
    </template>

    <!-- edit button -->
    <template v-slot:item.edit="{ item }">
      <v-btn small class="table-button" :to="`/site/${item.id}`">Edit</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SitesTable',
  props: {
    sites: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Site', value: 'name' },
        { text: 'Location', value: 'international' },
        { text: 'Patients', value: 'num_patients' },
        { text: 'Last Patient', value: 'last_date' },
        { text: 'Users', value: 'num_users' },
        { text: 'Edit', value: 'edit' }
      ]
    };
  },
  methods: {
    usaDate(d) {
      if (!d) {
        return null;
      }
      return moment(d).format('M/D/YYYY');
    }
  }
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
