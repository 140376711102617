// Calculate the rib score, based on the fractures from the grid.

import { countBy, uniq } from 'lodash';

// Determine whether the fractures on a side (right or left) create a flail chest condition:
const sideHasFlail = fractures => {
  if (fractures.length === 0) {
    return false;
  }

  // Find the ribs with multiple fractures.
  const counts = countBy(fractures, 'rib');
  const multi = Object.keys(counts)
    .filter(rib => counts[rib] > 1)
    // Convert from string to integer.
    .map(ribString => parseInt(ribString, 10))
    .sort();

  // Check whether there are any consecutive ribs with multiple fractures.
  return multi.some(rib => multi.includes(rib + 1));
};


// Determine whether the fractures create a flail chest condition:
// two or more fractures on two or more consecutive ribs on the same side.
const chestHasFlail = (sides, fractures) => sides.some(side => {
  const sideFractures = fractures.filter(f => f.side === side);
  return sideHasFlail(sideFractures);
});

// Check whether we have at least one fracture in each of
// three anatomic areas (anterior, lateral, and posterior).
const hasThreeAreas = fractures => {
  const fractureLocations = uniq(fractures.map(f => f.location));
  return fractureLocations.includes('anterior') &&
    // a lateral location: 'postero-lateral', 'lateral', or 'antero-lateral'
    fractureLocations.some(loc => loc.includes('lateral')) &&
    fractureLocations.includes('posterior');
};

export const calculateRibScore = fractures => {
  const sides = ['right', 'left'];

  const sixFracs = fractures.length >= 6;
  const fractureSides = fractures.map(f => f.side);
  const bilateral = sides.every(side => fractureSides.includes(side));
  const threeDisplaced = fractures.filter(f => f.type === 'displaced').length >= 3;
  const firstRib = fractures.some(f => f.rib === 1);
  const threeAreas = hasThreeAreas(fractures);
  const flail = chestHasFlail(sides, fractures);

  return sixFracs + bilateral + threeDisplaced + flail + firstRib + threeAreas;
};
