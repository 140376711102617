<template>
  <div>
    <span class="subheading">
      Fracture Type Legend
    </span>
    <!-- legend of fracture types -->
    <table>
      <tbody>
        <tr>
          <td>U:</td>
          <td>Undisplaced</td>
        </tr>
        <tr>
          <td>O:</td>
          <td>Offset</td>
        </tr>
        <tr>
          <td>D:</td>
          <td>Displaced</td>
        </tr>
      </tbody>
    </table>

    <!-- pop-up dialog with fracture examples -->
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">
          Show Examples
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Fracture Types
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="active" slider-color="primary">
            <v-tab :key="0" ripple>Undisplaced</v-tab>
            <v-tab :key="1" ripple>Offset</v-tab>
            <v-tab :key="2" ripple>Displaced</v-tab>

            <v-tab-item :key="0">
              <v-card flat>
                <v-card-text>
                  <p>
                    <b class="fracture-type">Undisplaced:</b>
                    > 90% contact between the cortical surfaces
                  </p>
                  <v-img src="/img/fracture_examples/undisplaced.jpg" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="1">
              <v-card flat>
                <v-card-text>
                  <p>
                    <b class="fracture-type">Offset:</b>
                    1% - 90% contact between the cortical surfaces
                  </p>
                  <v-img src="/img/fracture_examples/offset.jpg" />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="2">
              <v-card flat>
                <v-card-text>
                  <p>
                    <b class="fracture-type">Displaced:</b>
                    No cortical contact
                  </p>
                  <v-img src="/img/fracture_examples/displaced.jpg" />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'FractureTypeLegend',
  data() {
    return {
      dialog: false,
      active: 0
    };
  }
};
</script>

<style scoped>
.fracture-type {
  margin-right: 10px;
}
</style>
