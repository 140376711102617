<template>
  <v-data-table :headers="headers" :items="users" :items-per-page="10">
    <!-- last patient date -->
    <template v-slot:item.last_date="{ item }">{{
      usaDate(item.last_date)
    }}</template>

    <!-- account status -->
    <template v-slot:item.account_status="{ item }">
      <v-btn
        small
        class="table-button"
        color="primary"
        v-if="item.account_status === 'locked'"
        @click="() => unlock(item.id)"
      >
        Unlock
      </v-btn>
      <span v-else>{{ makeCap(item.account_status) }}</span>
    </template>

    <!-- edit button -->
    <template v-slot:item.edit="{ item }">
      <v-btn small class="table-button" :to="`/user/${item.id}`">Edit</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import { capitalize } from 'lodash';

export default {
  name: 'UsersTable',
  props: {
    users: { type: Array, required: true },
  },
  data() {
    return {
      headers: [
        { text: 'First name', value: 'first_name' },
        { text: 'Last name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Account', value: 'account_status' },
        { text: 'Pts Added', value: 'num_patients' },
        { text: 'Last Patient', value: 'last_date' },
        { text: 'Edit', value: 'edit' },
      ],
    };
  },
  methods: {
    usaDate(d) {
      if (!d) {
        return null;
      }
      return moment(d).format('M/D/YYYY');
    },
    makeCap(str) {
      return capitalize(str);
    },
    unlock(id) {
      this.$emit('unlock', id);
    },
  },
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
