<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Forgot Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert type="error" text elevation="2" v-if="unknownEmail">
              Unknown email. Please check the spelling, or ask your
              local CIID site administrator for an account.
            </v-alert>

            <!-- account locked message -->
            <div v-if="locked">
              <v-alert type="error" text elevation="2">
                <p>Your account has been locked for your safety.</p>
                <p>Check your email for instructions on how to request for it to be unlocked.</p>
              </v-alert>

              <!-- button row -->
              <v-row class="action-row">
                <v-spacer />
                <v-btn to="/" class="spaced-button" color="primary">OK</v-btn>
              </v-row>
            </div>

            <!-- If the account is not locked, then show the email form. -->
            <div v-else>
              <v-form>
                <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                  :rules="[v => !!v || 'Required']"
                  required
                  v-on:input="resetUnknown"
                  v-on:keydown.enter.prevent="forgot"
                />
              </v-form>

              <!-- button row -->
              <v-row class="action-row">
                <v-spacer />
                <v-btn to="/">Cancel</v-btn>
                <v-btn
                  color="primary"
                  :loading="loading"
                  :disabled="!email.trim()"
                  @click.prevent="forgot"
                  class="spaced-button"
                  type="submit"
                >Get Password</v-btn>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from '@/api';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      loading: false,
      locked: false,
      unknownEmail: false
    };
  },
  methods: {
    resetUnknown() {
      if (this.unknownEmail) {
        this.unknownEmail = false;
      }
    },
    async forgot() {
      if (!this.email.trim()) {
        return;
      }

      this.unknownEmail = false;
      this.loading = true;
      const result = await api.post('/auth/forgotPassword', {
        email: this.email.trim()
      });
      this.loading = false;
      const { status } = result.data;

      if (status === 'sent') {
        this.$snotify.success('The password has been emailed to you.', 'Sent');
        // Go to the forgot password page.
        this.$router.push('/login');
      } else if (status === 'unknownEmail') {
        this.unknownEmail = true;
      }
    }
  }
};
</script>

<style scoped>
#login-alert {
  margin-bottom: 20px;
}
</style>
