<template>
  <v-form>
    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-text-field
          label="Heart Rate"
          type="number"
          hint="Beats per minute"
          persistent-hint
          v-model="vitals.heart_rate"
          min="0"
          :rules="posRules"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field
          label="Respiratory Rate"
          type="number"
          hint="Breaths per minute"
          persistent-hint
          v-model="vitals.respiratory_rate"
          min="0"
          :rules="posRules"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field
          label="O2 Saturation"
          type="number"
          v-model="vitals.o2_saturation"
          min="0"
          :rules="posRules"
        />
      </v-flex>
    </v-layout>

    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-select
          :items="painOptions"
          label="Pain Score"
          item-text="label"
          item-value="value"
          v-model="vitals.pain"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-select
          :items="coughOptions"
          label="Cough Quality"
          v-model="vitals.cough"
        />
      </v-flex>

      <v-flex xs12 md2 offset-md1>
        <v-text-field
          label="O2 Requirement"
          type="number"
          v-model="vitals.o2_req"
          min="0"
          :rules="posRules"
        />
      </v-flex>

      <v-flex xs12 md2>
        <v-select
          :items="o2UnitOptions"
          label="Unit"
          v-model="vitals.o2_unit"
          :persistent-hint="Boolean(o2UnitHint)"
          :hint="o2UnitHint"
        />
      </v-flex>
    </v-layout>

    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-text-field
          label="Incentive Spirometry (% of Expcted)"
          type="number"
          prepend-icon="mdi-calculator"
          @click:prepend="spiroDialog = true"
          v-model="vitals.spiro_pct"
          min="0"
          :rules="posRules"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="vitals.xray" label="X-ray" />
      </v-flex>
    </v-layout>

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn
        :to="`/opVisits/${siteId}/${vitals.patient_id}`"
        class="spaced-button"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>

    <!-- spirometry calculator dialog -->
    <v-dialog
      v-if="vitals.patient_id"
      v-model="spiroDialog"
      max-width="500"
      @keydown.esc="spiroDialog = false"
    >
      <SpiroForm :siteId="siteId" :data="vitals" @close="spiroDialog = false" />
    </v-dialog>
  </v-form>
</template>

<script>
import SpiroForm from '@/components/SpiroForm.vue';

export default {
  name: 'OpVitalsForm',
  components: { SpiroForm },
  props: {
    siteId: { type: Number, required: true },
    vitals: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      painOptions: [
        { value: 0, label: '0: No Pain' },
        { value: 1, label: '1: Mild' },
        { value: 2, label: '2: Mild' },
        { value: 3, label: '3: Mild' },
        { value: 4, label: '4: Moderate' },
        { value: 5, label: '5: Moderate' },
        { value: 6, label: '6: Moderate' },
        { value: 7, label: '7: Severe' },
        { value: 8, label: '8: Severe' },
        { value: 9, label: '9: Very Severe' },
        { value: 10, label: '10: Worst Imaginable' }
      ],
      coughOptions: ['Adequate', 'Poor'],
      spiroDialog: false,
      o2UnitOptions: ['LPM', '% FiO2'],
      posRules: [g => g >= 0 || 'Must be >= 0'],
    };
  },
  computed: {
    o2UnitHint() {
      if (this.vitals.o2_unit === 'LPM') {
        return 'Liters per minute';
      } else if (this.vitals.o2_unit === '% FiO2') {
        return 'Fraction Inspired O2';
      }
      return '';
    }
  }
};
</script>
