// Incentive spirometry data for the Voldyne 5000 spirometer
import { range } from 'lodash';

const data = {
  // female
  Female: {
    // ages in years: 20 to 80 with step size 5
    ages: range(20, 81, 5),
    // heights in inches: 58 to 74 with step size 2
    heights: range(58, 75, 2),
    // spirometry values in ml
    spiro: [
      // 58,   60,   62,   64,   66,   68,   70,   72,   74 heights (in)
      [1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300, 3500], // age 20
      [1850, 2050, 2250, 2450, 2650, 2850, 3050, 3250, 3450], // age 25
      [1800, 2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400], // age 30
      [1750, 1950, 2150, 2350, 2550, 2750, 2950, 3150, 3350], // age 35
      [1700, 1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300], // age 40
      [1650, 1850, 2050, 2250, 2450, 2650, 2850, 3050, 3250], // age 45
      [1600, 1800, 2000, 2200, 2400, 2600, 2800, 3000, 3200], // age 50
      [1550, 1750, 1950, 2150, 2350, 2550, 2750, 2950, 3150], // age 55
      [1500, 1700, 1900, 2100, 2300, 2500, 2700, 2900, 3100], // age 60
      [1450, 1650, 1850, 2050, 2250, 2450, 2650, 2850, 3050], // age 65
      [1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800, 3000], // age 70
      [1350, 1500, 1750, 1950, 2150, 2350, 2550, 2750, 2950], // age 75
      [1300, 1500, 1700, 1900, 2100, 2300, 2500, 2700, 2900]  // age 80
    ]
  },

  // male
  Male: {
    // ages in years: 20 to 80 with step size 5
    ages: range(20, 81, 5),
    // heights in inches: 58 to 74 with step size 2
    heights: range(58, 79, 2),
    // spirometry values in ml
    spiro: [
      // 58,   60,   62,   64,   66,   68,   70,   72,   74,   76,   78 heights (in)
      [2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400, 3600, 3800, 4000], // age 20
      [1950, 2150, 2350, 2550, 2750, 2950, 3150, 3350, 3550, 3750, 3950], // age 25
      [1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300, 3500, 3700, 3900], // age 30
      [1800, 2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400, 3600, 3800], // age 35
      [1750, 1950, 2150, 2350, 2550, 2750, 2950, 3150, 3350, 3550, 3750], // age 40
      [1700, 1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300, 3500, 3700], // age 45
      [1650, 1850, 2050, 2250, 2450, 2650, 2850, 3050, 3250, 3450, 3650], // age 50
      [1550, 1750, 1950, 2150, 2350, 2550, 2750, 2950, 3150, 3350, 3550], // age 55
      [1500, 1700, 1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300, 3500], // age 60
      [1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800, 3000, 3200, 3400], // age 65
      [1350, 1550, 1750, 1950, 2150, 2350, 2550, 2750, 2950, 3150, 3350], // age 70
      [1300, 1500, 1700, 1900, 2100, 2300, 2500, 2700, 2900, 3100, 3300], // age 75
      [1250, 1450, 1650, 1850, 2050, 2250, 2450, 2650, 2850, 3050, 3250]  // age 80
    ]
  }
};

export default data;
