// TODO: Move this logic into a role defined the user record.

// list of people allowed to see the analytics page
const allowed = [
  { first_name: 'Jonathan', last_name: 'Jesneck' },
  { first_name: 'Andrew', last_name: 'Doben' },
  { first_name: 'Brian', last_name: 'Kim' },
  { first_name: 'Fredric', last_name: 'Pieracci' },
  { first_name: 'Kiara', last_name: 'Leasia' },
  { first_name: 'Christopher', last_name: 'Janowak' },
  { first_name: 'SarahAnn', last_name: 'Whitbeck' },
  { first_name: 'Phillip', last_name: 'Antippa' },
];

function shouldShowCiidAnalytics(user) {
  if (!user) {
    return false;
  }

  if (user.role === 'CIID admin') {
    return true;
  }

  const index = allowed.findIndex(
    (u) => u.first_name === user.first_name && u.last_name === user.last_name
  );
  return index > -1;
}

export default shouldShowCiidAnalytics;
