<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>

              <OpQolForm :siteId="siteId" :qol="qol" @save="save" />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import OpQolForm from '@/components/OpQolForm.vue';

export default {
  name: 'OpQol',
  props: {
    siteId: { type: Number, required: true },
    visitId: { type: Number, required: true }
  },
  components: { OpQolForm },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      qol: {},
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Quality of Life';
      if (this.qol.mrn) {
        title += ` for Outpatient ${this.qol.mrn}`;
      }
      return title;
    },
    subtitle() {
      let subtitle = '';
      if (this.qol.visit_date) {
        subtitle += moment(this.qol.visit_date).format('dddd M/D/YYYY');
      }
      if (this.qol.post_injury_day) {
        subtitle += `, Post-Injury Day ${this.qol.post_injury_day}`;
      }
      return subtitle;
    }
  },
  async created() {
    if (this.visitId) {
      await this.getQol();
      // Make sure that the qol object has the visit ID.
      if (!this.qol.op_visit_id) {
        this.qol.op_visit_id = this.visitId;
      }
    }
  },
  methods: {
    async getQol() {
      const result = await api.post('/outpatients/getOpQol', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.qol = result.data;

      let title = 'QoL';
      let backPath = '/patients';
      if (this.qol) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.qol.patient_id}`,
            disabled: false,
            to: `/patients/${this.siteId}/${this.qol.patient_id}`
          },
          {
            text: 'Follow-up Visits',
            disabled: false,
            to: `/opVisits/${this.siteId}/${this.qol.patient_id}`
          },
          {
            text: `Day ${this.qol.post_injury_day} QoL`,
            disabled: true
          }
        ];

        title = `${this.qol.mrn} Visit Day ${this.qol.post_injury_day}, QoL`;
        backPath = `/opVisits/${this.siteId}/${this.qol.patient_id}`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', { title, backPath });
    },
    async save() {
      this.saving = true;
      await api.post('/outpatients/saveOpQol', {
        siteId: this.siteId,
        qol: this.qol
      });
      this.saving = false;
      this.$snotify.success('Saved the Quality of Life', 'Saved');
      this.$router.push(`/opVisits/${this.siteId}/${this.qol.patient_id}`);
    }
  }
};
</script>
