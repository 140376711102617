<template>
  <v-form>
    <v-alert v-if="dateConflict" type="error" text>
      This patient already has an follow-up visit on {{ dateFormatted }}.
    </v-alert>

    <v-layout row wrap justify-space-between>
      <v-flex xs12 md3>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          max-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="dateFormatted"
              label="Visit Date"
              prepend-icon="mdi-calendar"
              hint="M/D/YYYY"
              persistent-hint
              readonly
              required
              :rules="dateRules"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="visit.visit_date"
            no-title
            @input="dateMenu = false"
          />
        </v-menu>
      </v-flex>

      <v-flex xs12 md8>
        <v-textarea
          name="comment"
          label="Comments"
          v-model="visit.comment"
          placeholder="General comments about this visit"
        />
      </v-flex>
    </v-layout>

    <!-- button row -->
    <v-layout row class="action-row">
      <v-btn v-if="visit.id" small @click="deleteDialog = true">Delete</v-btn>
      <v-spacer />
      <v-btn :to="`/opVisits/${siteId}/${patient.id}`" class="spaced-button">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :disabled="!maySave"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>

    <!-- delete confirmation dialog -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Confirmation for Deleting Visit
        </v-card-title>

        <v-card-text>
          <p class="confirm-p">
            Are you sure that you want to delete this outpatient follow-up
            visit, and all the associated data?
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="deleteDialog = false">Cancel</v-btn>

          <v-spacer />
          <v-btn color="error" :loading="deleting" @click="$emit('delete')">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OpVisitForm',
  props: {
    siteId: { type: Number, required: true },
    patient: { type: Object, required: true },
    visit: { type: Object, required: true },
    dateConflict: Boolean,
    saving: Boolean,
    deleting: Boolean
  },
  data() {
    return {
      dateMenu: false,
      deleteDialog: false,
      dateRules: [
        (d) => Boolean(d) || 'Required',
        (d) => moment(d, 'M/D/YYYY', true).isValid() || 'M/D/YYYY', // Valid date string
        (d) => moment(d) >= moment(this.patient.injury_date) || 'Must be after injury', 
      ],

    };
  },
  computed: {
    dateFormatted() {
      return this.visit.visit_date
        ? moment(this.visit.visit_date).format('M/D/YYYY')
        : '';
    },
    maySave() {
      return this.visit.visit_date && !this.dateConflict;
    }
  }
};
</script>
