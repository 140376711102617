<template>
  <v-form>
    <!-- checkbox row -->
    <v-layout wrap row class="checkbox-row">
      <v-flex xs12 md3>
        <v-checkbox v-model="outcomes.mech_vent" label="Mech. Ventilation" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="outcomes.tracheostomy" label="Tracheostomy" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="outcomes.icu" label="ICU" />
      </v-flex>
    </v-layout>

    <!-- checkbox row -->
    <v-layout wrap row class="checkbox-row">
      <v-flex xs12 md3>
        <v-checkbox v-model="outcomes.death" label="Death" />
      </v-flex>
    </v-layout>

    <!-- Other Surgeries row -->
    <v-layout row class="top-space">
      <v-combobox
        label="Other Surgeries"
        chips
        clearable
        multiple
        hint="You can add new surgery types."
        persistent-hint
        :items="procOptions"
        item-value="id"
        item-text="name"
        :value="outcomes.procedures"
        @input="handleProcsChange"
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="removeProcedure(item)"
          >
            {{ item.name || item }}
          </v-chip>
        </template>
      </v-combobox>
    </v-layout>

    <!-- Adverse Effects row -->
    <v-layout row>
      <v-combobox
        label="Adverse Events"
        chips
        clearable
        multiple
        hint="You can add new event types."
        persistent-hint
        :items="eventOptions"
        item-value="id"
        item-text="name"
        :value="outcomes.events"
        @input="handleEventsChange"
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="removeEvent(item)"
          >
            {{ item.name || item }}
          </v-chip>
        </template>
      </v-combobox>
    </v-layout>

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn
        :to="`/ipDays/${this.siteId}/${outcomes.patient_id}`"
        class="spaced-button"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import api from '@/api.js';

export default {
  name: 'IpDailyOutcomesForm',
  props: {
    siteId: { type: Number, required: true },
    outcomes: { type: Object, required: true },
    saving: Boolean,
  },
  data() {
    return {
      procOptions: [],
      eventOptions: [],
    };
  },
  methods: {
    // surgery section
    async getProcedureOptions() {
      const result = await api.post('/inpatients/getIpProcedureOptions', {
        siteId: this.siteId,
      });
      this.procOptions = result.data;
    },
    handleProcsChange(newProcs) {
      this.$emit('setProcedures', newProcs);
    },
    removeProc(item) {
      const index = this.outcomes.procedures.indexOf(item);
      if (index > -1) {
        const newProcs = [
          ...this.outcomes.procedures.slice(0, index),
          ...this.outcomes.procedures.slice(index + 1),
        ];
        this.$emit('setProcedures', newProcs);
      }
    },

    // events section
    async getEventOptions() {
      const result = await api.post('/inpatients/getIpEventOptions', {
        siteId: this.siteId,
      });
      this.eventOptions = result.data;
    },
    handleEventsChange(newEvents) {
      if (newEvents.length > 0) {
        // Get the last selected event.
        const pos = newEvents.length - 1;
        const last = newEvents[pos];
        if (typeof last === 'string') {
          // Check whether the string matches a select option.
          const opt = this.eventOptions.find((o) => o.name === last);
          if (opt) {
            const already = newEvents.some((e) => e.id && e.id === opt.id);
            if (!already) {
              // Convert the string to the adverse event object with the same name.
              newEvents[pos] = opt;
            }
          }
        }
      }

      this.$emit('setEvents', newEvents);
    },
    removeEvent(item) {
      const index = this.outcomes.events.indexOf(item);
      if (index > -1) {
        const newEvents = [
          ...this.outcomes.events.slice(0, index),
          ...this.outcomes.events.slice(index + 1),
        ];
        this.$emit('setEvents', newEvents);
      }
    },
  },
  created() {
    this.getProcedureOptions();
    this.getEventOptions();
  },
};
</script>

<style scoped>
.top-space {
  margin-top: 30px;
}
</style>
