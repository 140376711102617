<template>
  <v-form>
    <v-alert type="error" text elevation="2" v-if="unknownEmail">
      Unknown email. Please check the spelling, or ask your local CIID site
      administrator for an account.
    </v-alert>

    <v-alert type="error" text elevation="2" v-if="invalid">
      Invalid password.
      <div v-if="triesLeft">
        To protect your security and patient data, you have {{ triesLeft }}
        {{ triesLeft === 1 ? 'try' : 'tries' }} left.
      </div>
    </v-alert>

    <v-text-field
      prepend-icon="mdi-email"
      name="email"
      label="Email"
      type="text"
      v-model="email"
      :rules="[(v) => !!v || 'Required']"
      required
      v-on:input="resetUnknown"
    />

    <v-text-field
      prepend-icon="mdi-lock"
      name="password"
      label="Password"
      :type="showPassword ? 'text' : 'password'"
      v-model="password"
      :rules="[(v) => !!v || 'Required']"
      required
      v-on:keydown.enter.prevent="login"
      v-on:input="resetInvalid"
    />
    <v-checkbox v-model="showPassword" label="Show password" />

    <!-- button row -->
    <v-row class="action-row">
      <v-btn
        text
        class="spaced-button"
        color="primary"
        @click.prevent="$emit('forgot')"
        >Forgot password</v-btn
      >
      <v-spacer />
      <v-btn to="/">Cancel</v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="!okToSubmit"
        @click.prevent="login"
        class="spaced-button"
        type="submit"
        >Log in</v-btn
      >
    </v-row>
  </v-form>
</template>

<script>
import api from '@/api';

export default {
  name: 'LoginForm',
  props: ['user'],
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      unknownEmail: false,
      invalid: false,
      triesLeft: null,
      showPassword: false,
    };
  },
  computed: {
    okToSubmit() {
      return this.email.trim() && this.password.trim();
    },
  },
  methods: {
    resetUnknown() {
      if (this.unknownEmail) {
        this.unknownEmail = false;
      }
    },
    resetInvalid() {
      if (this.invalid) {
        this.invalid = false;
      }
    },
    async login() {
      if (!this.okToSubmit) {
        return;
      }

      this.unknownEmail = false;
      this.invalid = false;
      this.loading = true;
      const result = await api.post('/auth/login', {
        email: this.email.trim(),
        password: this.password.trim(),
      });
      this.loading = false;
      const { status, user, triesLeft } = result.data;

      if (status === 'valid') {
        this.$emit('valid', user);
      } else if (status === 'tempValid') {
        this.$emit('tempValid', user);
      } else if (status === 'unknownEmail') {
        this.unknownEmail = true;
      } else if (status === 'invalid') {
        this.invalid = true;
        this.triesLeft = triesLeft;
      } else if (status === 'locked') {
        this.$emit('locked');
      } else if (status === 'archived') {
        this.$emit('archived');
      }
    },
  },
};
</script>

<style scoped>
#welcome {
  margin-bottom: 10px;
}
.v-input--selection-controls {
  margin-top: 0px;
}
</style>
