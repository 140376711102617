var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"component-container"}},[_vm._m(0),_c('v-row',[_c('v-flex',{attrs:{"id":"heatmap-container"}},[_c('table',[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"side-header",attrs:{"colspan":"5"}},[_vm._v("Right")]),_c('th',{staticClass:"text-xs-center center-cell"},[_vm._v("St.")]),_c('th',{staticClass:"side-header",attrs:{"colspan":"5"}},[_vm._v("Left")]),_c('th')])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Rib")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("P")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("PL")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("L")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("AL")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("A")]),_c('td'),_c('td',{staticClass:"text-xs-center"},[_vm._v("A")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("AL")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("L")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("PL")]),_c('td',{staticClass:"text-xs-center"},[_vm._v("P")]),_c('td',[_vm._v("Rib")])]),_vm._l((_vm.ribs),function(rib){return _c('tr',{key:rib},[_c('td',{staticClass:"shrink text-xs-right"},[_vm._v(_vm._s(rib))]),_vm._l((_vm.rightLocations),function(location){return [_c('td',{key:(rib + "-right-" + location),staticClass:"text-xs-center count-cell",style:({
                  backgroundColor: _vm.getCellColor(
                    _vm.fxCounts[rib]['right'][location]
                  )
                })},[_vm._v(" "+_vm._s(_vm.fxCounts[rib]['right'][location])+" ")])]}),(rib < 4)?_c('td',{key:(rib + "-sternum"),staticClass:"text-xs-center center-cell count-cell",style:({
                backgroundColor: _vm.getCellColor(_vm.fxCounts[rib]['sternum'])
              })},[_vm._v(" "+_vm._s(_vm.fxCounts[rib]['sternum'])+" ")]):_c('td',{staticClass:"center-cell"}),_vm._l((_vm.leftLocations),function(location){return [_c('td',{key:(rib + "-left-" + location),staticClass:"text-xs-center count-cell",style:({
                  backgroundColor: _vm.getCellColor(
                    _vm.fxCounts[rib]['left'][location]
                  )
                })},[_vm._v(" "+_vm._s(_vm.fxCounts[rib]['left'][location])+" ")])]}),_c('td',{staticClass:"shrink"},[_vm._v(_vm._s(rib))])],2)})],2)])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"title-container"}},[_c('b',[_vm._v("Fracture Occurrence")])])}]

export { render, staticRenderFns }