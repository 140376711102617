<template>
  <v-form>
    <!-- date row -->
    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="dateFormatted"
              label="PFT Date"
              prepend-icon="mdi-calendar"
              hint="M/D/YYYY"
              persistent-hint
              readonly
              required
              :rules="dateRules"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="pfts.pft_date"
            no-title
            @input="dateMenu = false"
          />
        </v-menu>
      </v-flex>
    </v-layout>

    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-text-field label="FVC" type="number" v-model="pfts.fvc" min="0" :rules="posRules" />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field label="FEV1" type="number" v-model="pfts.fev1" min="0" :rules="posRules" />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field label="FEV1/FVC" type="number" v-model="pfts.fev1_fvc" min="0" :rules="posRules" />
      </v-flex>
    </v-layout>

    <v-layout wrap row>
      <v-flex xs12 md3>
        <v-text-field label="PEFR" type="number" v-model="pfts.pefr" min="0" :rules="posRules" />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field label="PIFR" type="number" v-model="pfts.pifr" min="0" :rules="posRules" />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-text-field label="Other" v-model="pfts.other" />
      </v-flex>
    </v-layout>

    <v-layout wrap row>
      <v-flex xs12>
        <v-textarea name="comment" label="Comments" v-model="pfts.comment" />
      </v-flex>
    </v-layout>

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn :to="backUrl" class="spaced-button">
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PftsForm',
  props: {
    pfts: { type: Object, required: true },
    backUrl: { type: String, required: true },
    saving: Boolean
  },
  data() {
    return {
      dateMenu: false,
      dateRules: [
        (d) => Boolean(d) || 'Required',
        (d) => moment(d, 'M/D/YYYY', true).isValid() || 'M/D/YYYY', // Valid date string
        (d) => moment(d) >= moment(this.pfts.injury_date) || 'Must be after injury', 
      ],
      posRules: [g => g >= 0 || 'Must be >= 0'],
    };
  },
  computed: {
    dateFormatted() {
      return this.pfts.pft_date
        ? moment(this.pfts.pft_date).format('M/D/YYYY')
        : '';
    }
  }
};
</script>
