var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patients,"items-per-page":10},scopedSlots:_vm._u([{key:"item.mrn",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.mrn)+" ")])]}},{key:"item.demoSummary",fn:function(ref){
var item = ref.item;
return [(item.yob)?_c('router-link',{attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/2")}},[_vm._v(" "+_vm._s(_vm.cleanDemo(item))+" ")]):_c('v-btn',{staticClass:"table-button",attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/2"),"small":"","color":"primary"}},[_vm._v(" Add ")])]}},{key:"item.injuriesSummary",fn:function(ref){
var item = ref.item;
return [(item.injury_date)?_c('router-link',{attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/3")}},[_vm._v(" "+_vm._s(item.num_fx)+" fx ")]):_c('v-btn',{staticClass:"table-button",attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/3"),"small":"","color":"primary"}},[_vm._v(" Add ")])]}},{key:"item.admitSummary",fn:function(ref){
var item = ref.item;
return [(item.admit_date)?_c('router-link',{attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/4")}},[_vm._v(" "+_vm._s(_vm.cleanDate(item.admit_date))+" ")]):_c('v-btn',{staticClass:"table-button",attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/4"),"small":"","color":"primary"}},[_vm._v("Add")])]}},{key:"item.operationSummary",fn:function(ref){
var item = ref.item;
return [(item.num_plates)?_c('router-link',{attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/5")}},[_vm._v(" "+_vm._s(item.num_plates)+" plate"),(item.num_plates !== 1)?_c('span',[_vm._v("s")]):_vm._e()]):_c('v-btn',{staticClass:"table-button",attrs:{"to":("/patient/" + _vm.siteId + "/" + (item.id) + "/5"),"small":"","color":item.had_operation ? 'default' : 'primary'}},[_vm._v(" "+_vm._s(item.had_operation ? 'Edit' : 'Add')+" ")])]}},{key:"item.inpatientSummary",fn:function(ref){
var item = ref.item;
return [(item.ip_days)?_c('router-link',{attrs:{"to":("/ipDays/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.ip_days)+" day"),(item.ip_days !== 1)?_c('span',[_vm._v("s")]):_vm._e()]):_c('v-btn',{staticClass:"table-button",attrs:{"small":"","color":"primary","to":("/ipDays/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" Add ")])]}},{key:"item.outpatientSummary",fn:function(ref){
var item = ref.item;
return [(item.op_visits)?_c('router-link',{attrs:{"to":("/opVisits/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.op_visits)+" visit"),(item.op_visits !== 1)?_c('span',[_vm._v("s")]):_vm._e()]):_c('v-btn',{staticClass:"table-button",attrs:{"small":"","color":"primary","to":("/opVisits/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" Add ")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.$router.push(("/patient/" + _vm.siteId + "/" + (item.id)))}}},[_vm._v(" "+_vm._s(item.comment)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }