import { findLastIndex } from 'lodash';

// Get the array indexes for the query value.
// If the query is not included in the array, then return the indexes
// of the closeest values less than and greater than the query value.
// Note that I assume that the values are already sorted.
const getNearIndexes = (vals, query) => {
  // If the query is less than the min value, then return the min value index.
  if (query < vals[0]) {
    return [0, 1];
  }

  // If the query is greater than the max value, then return the max value index.
  if (query > vals[vals.length - 1]) {
    return [vals.length - 2, vals.length - 1];
  }

  // Return the indexes of the closest values less than and greater than the query value.
  const lastLessIndex = findLastIndex(vals, val => val <= query);
  return [lastLessIndex, lastLessIndex + 1];
};


// Bilinear interpolate the value at the query point (x, y).
// I assume that x1 < x < x2 and that y1 < y < y2.
const interpolate = (values, allXs, allYs, x, y) => {
  // Get the nearby x indexes.
  const [i0, i1] = getNearIndexes(allXs, x);
  // Get the x values.
  const x1 = allXs[i0];
  const x2 = allXs[i1];

  // Get the nearby y indexes.
  const [j0, j1] = getNearIndexes(allYs, y);
  // Get the y values.
  const y1 = allYs[j0];
  const y2 = allYs[j1];

  // Get the matrix values at the nearby points.
  const val11 = values[i0][j0]; // value at (x1, y1)
  const val12 = values[i0][j1]; // value at (x1, y2)
  const val21 = values[i1][j0]; // value at (x2, y1)
  const val22 = values[i1][j1]; // value at (x2, y2)

  // Interpolate between the four nearest points.
  return 1.0 / ((x2 - x1) * (y2 - y1)) * (
    val11 * (x2 - x) * (y2 - y) +
    val21 * (x - x1) * (y2 - y) +
    val12 * (x2 - x) * (y - y1) +
    val22 * (x - x1) * (y - y1)
  );
};

export default interpolate;
