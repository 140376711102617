<template>
  <v-form>
    <v-layout row>
      <v-flex xs12 md3>
        <v-select
          :items="painOptions"
          label="Pain Score"
          item-text="label"
          item-value="value"
          v-model="outcomes.pain"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-select
          :items="agitationOptions"
          label="Agitation"
          item-text="label"
          item-value="value"
          v-model="outcomes.agitation"
        />
      </v-flex>

      <!-- Spiro field and calculator -->
      <v-flex xs12 md3 offset-md1>
        <v-text-field
          label="Incentive Spirometry (% of Expected)"
          prepend-icon="mdi-calculator"
          @click:prepend="spiroDialog = true"
          type="number"
          min="0"
          :rules="spiroRules"
          v-model="outcomes.spiro_pct"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 md3>
        <v-text-field
          label="Respiratory Rate"
          hint="Breaths per minute"
          persistent-hint
          type="number"
          min="0"
          max="120"
          :rules="respRules"
          v-model="outcomes.respiratory_rate"
        />
      </v-flex>

      <v-flex xs12 md3 offset-md1>
        <v-select
          :items="coughOptions"
          label="Cough"
          v-model="outcomes.cough"
        />
      </v-flex>

      <v-flex xs12 md2 offset-md1>
        <v-text-field
          label="O2 Requirement"
          type="number"
          min="0"
          :rules="o2Rules"
          v-model="outcomes.o2_req"
        />
      </v-flex>

      <v-flex xs12 md2>
        <v-select
          :items="o2UnitOptions"
          label="Unit"
          v-model="outcomes.o2_unit"
          :persistent-hint="Boolean(o2UnitHint)"
          :hint="o2UnitHint"
        />
      </v-flex>
    </v-layout>

    <!-- checkbox row -->
    <v-layout wrap row class="checkbox-row">
      <v-flex xs12 md3>
        <v-checkbox v-model="outcomes.chest_tube" label="Chest Tube" />
      </v-flex>
    </v-layout>

    <Anticoagulation
      :siteId="siteId"
      :data="outcomes"
      :coags="outcomes.coags"
      :platelets="outcomes.platelets"
      @setCoags="setCoags"
      @setPlatelets="setPlatelets"
    />

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn
        :to="`/ipDays/${this.siteId}/${outcomes.patient_id}`"
        class="spaced-button"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        :loading="saving"
        @click.prevent="$emit('save')"
      >
        Save
      </v-btn>
    </v-layout>

    <!-- spirometry calculator dialog -->
    <v-dialog
      v-if="outcomes.patient_id"
      v-model="spiroDialog"
      max-width="400"
      @keydown.esc="spiroDialog = false"
    >
      <SpiroForm
        :siteId="siteId"
        :data="outcomes"
        @close="spiroDialog = false"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import Anticoagulation from '@/components/Anticoagulation.vue';
import SpiroForm from '@/components/SpiroForm.vue';

export default {
  name: 'IpDailyOutcomesForm',
  components: { Anticoagulation, SpiroForm },
  props: {
    siteId: { type: Number, required: true },
    outcomes: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      painOptions: [
        { value: 0, label: '0: No Pain' },
        { value: 1, label: '1: Mild' },
        { value: 2, label: '2: Mild' },
        { value: 3, label: '3: Mild' },
        { value: 4, label: '4: Moderate' },
        { value: 5, label: '5: Moderate' },
        { value: 6, label: '6: Moderate' },
        { value: 7, label: '7: Severe' },
        { value: 8, label: '8: Severe' },
        { value: 9, label: '9: Very Severe' },
        { value: 10, label: '10: Worst Imaginable' }
      ],
      agitationOptions: [
        { value: -5, label: '-5: Unarousable sedation' },
        { value: -4, label: '-4: Deep sedation' },
        { value: -3, label: '-3: Moderate sedation' },
        { value: -2, label: '-2: Light sedation' },
        { value: -1, label: '-1: Drowsy' },
        { value: 0, label: '0: Alert and calm' },
        { value: 1, label: '+1: Restless' },
        { value: 2, label: '+2 Agitated' },
        { value: 3, label: '+3 Very agitated' },
        { value: 4, label: '+4 Combative' }
      ],
      coughOptions: ['Adequate', 'Poor'],
      spiroDialog: false,
      o2UnitOptions: ['LPM', '% FiO2'],
      spiroRules: [g => g >= 0 || 'Must be >= 0'],
      respRules: [g => (g >= 0) && (g <= 120) || 'Must be in range 0 to 120'],
      o2Rules: [g => g >= 0 || 'Must be >= 0'],
    };
  },
  computed: {
    o2UnitHint() {
      if (this.outcomes.o2_unit === 'LPM') {
        return 'Liters per minute';
      } else if (this.outcomes.o2_unit === '% FiO2') {
        return 'Fraction Inspired O2';
      }
      return '';
    }
  },
  methods: {
    setCoags(newCoags) {
      this.$emit('setCoags', newCoags);
    },
    setPlatelets(newPlatelets) {
      this.$emit('setPlatelets', newPlatelets);
    }
  }
};
</script>
