<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      Opiates Calculator
    </v-card-title>
    <v-card-text>
      <p id="sub-title">
        <b>Total Dosage for the Day</b>
      </p>
      <p>
        Instructions: Fill in any administered doses in the Dose column.
      </p>

      <v-form>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Drug</th>
              <th>Route</th>
              <th>Unit</th>
              <th>Dose</th>
              <th>MME Factor</th>
              <th>MME Dose</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="d in drugs" :key="d.drug" v-bind:class="{ 'filled-row': d.dose, 'unfilled-row': !d.dose }">
              <td>{{ d.drug }}</td>
              <td>{{ d.route }}</td>
              <td>
                <span v-if="d.unit === 'mcg'">
                  <b>{{ d.unit }}</b>
                </span>
                <span v-else>
                  {{ d.unit }}
                </span>
              </td>

              <td>
                <v-text-field class="dose-input" type="number" v-model="d.dose" @input="calculateEquivDose(d)" />
              </td>

              <td class="factor-col">{{ d.mmeFactor }}</td>

              <td class="mme-dose-col">
                <span v-if="d.equiv_dose">
                  {{ d.equiv_dose }}
                </span>
              </td>
            </tr>

            <tr id="total-row">
              <td><b>TOTAL</b></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ totalDose }}</td>
            </tr>
          </tbody>

        </v-simple-table>
      </v-form>
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn @click="$emit('cancel')">
        Cancel
      </v-btn>
      <v-btn color="primary" @click.prevent="save" :loading="saving">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { cloneDeep, round, sum } from 'lodash';
import { drugOptions } from '../utils/narcUtils';

export default {
  name: 'NarcoticsForm',
  props: {
    narcs: { type: Array, required: true }, // incoming saved narcs
    saving: Boolean
  },
  data() {
    return {
      drugs: [],
      totalDose: 0,
    };
  },
  // On mounting the component, copy the narcs into the local variable myNarcs.
  mounted() {
    // Get a clean copy of the drug options, to avoid any previous dose information
    // from the previous time this form was opened.
    this.drugs = cloneDeep(drugOptions);

    // Merge the saved given drugs with the drug options.
    if (this.narcs.length > 0) {

      for (const n of this.narcs) {
        const drug = this.drugs.find(d => n.drug === d.drug);

        if (drug) {
          drug.dose = n.dose;
          drug.equiv_dose = n.equiv_dose;
        }
      }

      this.calculateTotalDose();
    }
  },
  methods: {
    calculateEquivDose(drug) {
      const dose = drug.dose ? parseFloat(drug.dose) : 0;
      drug.equiv_dose = round(dose * drug.mmeFactor, 2);
      this.calculateTotalDose();
    },
    calculateTotalDose() {
      this.totalDose = round(sum(this.drugs.map(n => n.equiv_dose)), 1);
    },
    async save() {
      // Find the drugs that were given to the patient (had a non-zero dose value).
      const givenDrugs = this.drugs.filter(d => d.equiv_dose);
      this.$emit('save', givenDrugs, this.totalDose);
    }
  }
};
</script>

<style scoped>
#sub-title {
  margin-top: 10px;
}

.filled-row {
  background-color: #E1F5FE;
  font-weight: 600;
}

.unfilled-row {
  color: #999999;
}

.dose-input {
  width: 60px;
  margin-top: 0px;
  padding-top: 0px;
}

.factor-col {
  color: #999999;
}

.mme-dose-col {
  font-weight: 600;
  color: #1976D2;
}

#total-row {
  font-weight: 600;
  background-color: #4FC3F7;
  color: #1A237E;
}
</style>
