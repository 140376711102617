import { round } from 'lodash';

// List the available drug options, with their morphine milligram equivalent (MME) factors.
export const drugOptions = [
  { drug: '(PO) Hydrocodone', route: 'PO', unit: 'mg', mmeFactor: 1 },
  { drug: '(IV) Hydromorophone', route: 'IV', unit: 'mg', mmeFactor: 20 },
  { drug: '(PO) Hydromorophone', route: 'PO', unit: 'mg', mmeFactor: 5 },
  { drug: '(PO) Morphine', route: 'PO', unit: 'mg', mmeFactor: 1 },
  { drug: '(IV) Morphine', route: 'IV', unit: 'mg', mmeFactor: 3 },
  { drug: '(PO) Oxycodone', route: 'PO', unit: 'mg', mmeFactor: 1.5 },
  { drug: '(PO) Tramadol', route: 'PO', unit: 'mg', mmeFactor: 0.2 },
  {
    drug: '(IV) Fentanyl',
    route: 'IV',
    unit: 'mcg',
    mmeFactor: round(1 / 300, 3),
  },
];
