<template>
  <v-form>
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Incentive Spirometry
      </v-card-title>
      <v-card-text>
        <p v-if="gettingDemoData">
          Loading data...
        </p>

        <v-alert :value="true" color="error" v-if="needDemoData" text>
          In order to calculate spirometry data, we need more patient
          information: year of birth, sex, and height. Please enter the data on
          the
          <v-btn color="error" :to="`/patient/${siteId}/${data.patient_id}/2`">
            Patient Demographics page
          </v-btn>.
        </v-alert>

        <div v-if="!gettingDemoData && !needDemoData">
          <v-select
            label="Spirometer Model"
            v-model="data.spiro_model"
            :items="models"
            item-value="value"
            item-text="text"
          />

          <v-text-field
            label="IS Volume"
            hint="mL"
            persistent-hint
            type="number"
            v-model="data.spiro_volume"
            @input="calculatePct"
            min="0"
            max="5000"
            :rules="volRules"
          />

          <v-layout>
            <v-flex xs12 sm6 md5>
              <v-alert :value="true" color="info" text class="result">
                {{ data.spiro_pct }}% of expected
              </v-alert>
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="valid" color="primary" @click.prevent="$emit('close')">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import calculateSpiroPct from '../utils/calculateSpiroPct';

export default {
  name: 'SpiroForm',
  props: {
    siteId: { type: Number, required: true },
    data: { type: Object, required: true }
  },
  data() {
    return {
      gettingDemoData: false,
      sex: null,
      yob: null,
      age: null,
      height: null,
      models: ['Voldyne 5000', 'Vyaire AirLife 2500'],
      volRules: [ v => (v >= 0) && (v <= 5000) || 'Must be in range 0 to 5000']
    };
  },
  computed: {
    needDemoData() {
      return ![this.sex, this.yob, this.height].every(Boolean);
    },
    valid() {
      return [
        this.age,
        this.sex,
        this.height,
        this.data.spiro_model,
        this.data.spiro_volume,
        this.data.spiro_pct
      ].every(Boolean);
    }
  },
  created() {
    this.getDemoData();
  },
  methods: {
    async getDemoData() {
      this.gettingDemoData = true;
      const result = await api.post('/patients/getPatient', {
        siteId: this.siteId,
        id: this.data.patient_id
      });
      const patient = result.data;
      this.gettingDemoData = false;
      if (patient) {
        this.yob = patient.yob;
        this.age = moment().diff(patient.yob, 'years');
        this.sex = patient.sex;
        this.height = patient.height;
      }
    },
    calculatePct() {
      if (!this.data.spiro_model || !this.data.spiro_volume) {
        this.data.spiro_pct = null;
      }

      if (typeof this.data.spiro_volume === 'string') {
        this.data.spiro_volume = parseFloat(this.data.spiro_volume);
      }

      const mayCalculate = [
        this.age,
        this.sex,
        this.height,
        this.data.spiro_model,
        this.data.spiro_volume
      ].every(Boolean);

      if (mayCalculate) {
        this.data.spiro_pct = calculateSpiroPct(
          this.sex,
          this.age,
          this.height,
          this.data.spiro_model,
          this.data.spiro_volume
        );
      }
    }
  }
};
</script>

<style scoped>
.result {
  margin-top: 20px;
}
</style>
