<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading">{{ subtitle }}</div>
              <IpAdditionalOutcomesForm
                :siteId="siteId"
                :outcomes="outcomes"
                :saving="saving"
                @setProcedures="setProcedures"
                @setEvents="setEvents"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import IpAdditionalOutcomesForm from '@/components/IpAdditionalOutcomesForm.vue';

export default {
  name: 'IpAdditionalOutcomes',
  props: {
    siteId: { type: Number, required: true },
    dayId: { type: Number, required: false }
  },
  components: {
    IpAdditionalOutcomesForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      outcomes: {
        procedures: [],
        events: []
      },
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Additional Outcomes';
      if (this.outcomes.mrn) {
        title += ` for Inpatient ${this.outcomes.mrn}`;
      }
      return title;
    },
    subtitle() {
      if (!this.outcomes.hospital_date) {
        return '';
      }
      const date = moment(this.outcomes.hospital_date).format('dddd M/D/YYYY');
      return `${date}, Hospital Day ${this.outcomes.hospital_day}`;
    }
  },
  methods: {
    async getIpAdditionalOutcomes() {
      this.loading = true;
      const result = await api.post('/inpatients/getIpAdditionalOutcomes', {
        siteId: this.siteId,
        dayId: this.dayId
      });
      this.outcomes = result.data;
      this.loading = false;

      // Add the relevant breadcrumbs.
      if (this.outcomes) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.outcomes.mrn}`,
            disabled: false,
            to: `/patient/${this.siteId}/${this.outcomes.patient_id}`
          },
          {
            text: 'Inpatient Days',
            disabled: false,
            to: `/ipDays/${this.siteId}/${this.outcomes.patient_id}`
          },
          {
            text: `Day ${this.outcomes.hospital_day} Additional Outcomes`,
            disabled: true
          }
        ];

        this.$store.dispatch('setNavData', {
          title: `${this.outcomes.mrn} Day ${this.outcomes.hospital_day}, Add Outcomes`,
          backPath: `/ipDays/${this.siteId}/${this.outcomes.patient_id}`
        });
      }
    },
    setProcedures(procedures) {
      this.outcomes = { ...this.outcomes, procedures };
    },
    setEvents(events) {
      this.outcomes = { ...this.outcomes, events };
    },
    async save() {
      this.outcomes.procedures = cleanNamedItems(this.outcomes.procedures);
      this.outcomes.events = cleanNamedItems(this.outcomes.events);

      this.saving = true;
      await api.post('/inpatients/saveIpAdditionalOutcomes', {
        siteId: this.siteId,
        outcomes: this.outcomes
      });
      this.saving = false;
      this.$snotify.success('Saved the Additional Outcomes', 'Saved');
      // Go back to the inpatient page.
      this.$router.push(`/ipDays/${this.siteId}/${this.outcomes.patient_id}`);
    }
  },
  async created() {
    if (this.dayId) {
      await this.getIpAdditionalOutcomes();
    }
  }
};
</script>
