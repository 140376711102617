var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.studies,"items-per-page":10},scopedSlots:_vm._u([{key:"item.sites",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sites.map(function (s) { return s.name; }).join(', '))+" ")]}},{key:"item.last_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.usaDate(item.last_date))+" ")]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.archived ? 'Yes' : 'No')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"table-button",attrs:{"small":"","to":("/study/" + (item.id))}},[_vm._v("Open")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }