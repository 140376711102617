import { Bar } from 'vue-chartjs';
// import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: Bar,
  // extends: HorizontalBar,
  props: {
    rawData: { type: Array }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Patients Entered'
            },
            ticks: {
              suggestedMin: 0
            }
          }
        ]
      },
      title: {
        display: true,
        text: 'Number of Patients Entered'
      },
      height: 200
    }
  }),
  methods: {
    makeChartData(rawData) {
      const labels = rawData.map(d => d.name);
      const data = rawData.map(d => d.num_patients);

      return {
        labels,
        datasets: [
          {
            label: 'Patients Entered',
            backgroundColor: 'rgba(23, 118, 210, 0.7)',
            data
          }
        ]
      };
    }
  },

  mounted() {
    const chartdata = this.makeChartData(this.rawData);
    this.renderChart(chartdata, this.options);
  }
};
