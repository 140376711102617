<template>
  <v-container>
    <v-layout fluid>
      <v-flex fluid>
        <PatientsFilter
          :siteId="filter.siteId"
          :filter="filter"
          :showSiteSelect="user && user.role === 'CIID admin'"
          :sites="sites"
          @setSite="setSite"
          :showAddButton="showAddButton"
          :showExportButton="showExportButton"
          :exporting="exporting"
          @export="handleExport"
        />

        <v-card id="patient-list" class="elevation-6">
          <v-card-text>
            <PatientsTable
              :siteId="filter.siteId"
              :patients="filteredPatients"
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import api from '@/api';
import PatientsFilter from '@/components/PatientsFilter';
import PatientsTable from '@/components/PatientsTable';

export default {
  name: 'Patients',
  components: {
    PatientsFilter,
    PatientsTable,
  },
  data() {
    return {
      sites: [],
      patients: [],
      exporting: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      filter: (state) => state.patientFilter,
    }),
    showAddButton() {
      const u = this.user;
      const f = this.filter;

      // Hide the button if I'm a CIID admin and browsing patients for another site.
      if (u && u.role === 'CIID admin' && u.site_id !== f.siteId) {
        return false;
      }
      return true;
    },
    showExportButton() {
      return this.user?.may_export;
    },
    filteredPatients() {
      let filtered = this.patients;

      if (this.filter.fromDate && this.isValidDate(this.filter.fromDate)) {
        const from = moment(this.filter.fromDate, 'MM/DD/YYYY');
        filtered = filtered.filter(
          (p) => p.admit_date && moment(p.admit_date) >= from
        );
      }
      if (this.filter.toDate && this.isValidDate(this.filter.toDate)) {
        const to = moment(this.filter.toDate, 'MM/DD/YYYY');
        filtered = filtered.filter(
          (p) => p.admit_date && moment(p.admit_date) <= to
        );
      }

      const lowerQuery = this.filter.query
        ? this.filter.query.trim().toLowerCase()
        : '';
      if (lowerQuery) {
        const textFields = ['mrn', 'comment'];
        filtered = filtered.filter((p) =>
          textFields.some(
            (f) =>
              p[f] && p[f].trim() && p[f].toLowerCase().includes(lowerQuery)
          )
        );
      }

      return filtered;
    },
  },
  async created() {
    // If the filter doesn't have a site ID yet,  then default to the user's site ID.
    if (this.user.site_id && !this.filter.siteId) {
      this.setSite(this.user.site_id);
    } else {
      this.getPatients(this.filter.siteId);
    }
    if (this.user && this.user.role === 'CIID admin') {
      this.getSites();
    }
    this.$store.dispatch('setNavData', { title: 'Patients', backPath: '' });
  },
  methods: {
    async getPatients(siteId) {
      const result = await api.post('/patients/getPatients', { siteId });
      this.patients = result.data;
    },
    async getSites() {
      const result = await api.post('/sites/getSites');
      const sites = result.data;
      if (sites && sites.length > 0) {
        this.sites = sites;
      }
    },
    isValidDate(str) {
      return moment(str, 'MM/DD/YYYY', true).isValid();
    },
    setSite(siteId) {
      this.$store.dispatch('setPatientFilter', { ...this.filter, siteId });
      this.getPatients(siteId);
    },
    async handleExport(siteId) {
      // Add today's date to the file name.
      const fileName = `CIID data ${moment().format('YYYY-MM-DD')}.xlsx`;
      this.exporting = true;

      try {
        // Call the API to get the xlsx file data.
        const result = await api({
          method: 'post',
          url: '/exports/exportData',
          data: { siteId }, // I don't think this actually gets passed to the API.
          headers: {
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // Due to an axios bug, I can't pass data in when the content type is a spreadsheet.
            // So I'm sneaking it in here in the request headers.
            site_id: siteId,
          },
          responseType: 'blob', // I could also use 'arraybuffer' here.
        });

        const fileUrl = window.URL.createObjectURL(new Blob([result.data]));
        // Simulate the user clicking on a link to download the file.
        const link = document.createElement('a');
        link.setAttribute('href', fileUrl);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        // Click the link to activate the download.
        link.click();
        // Clean up and remove the link.
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
      } catch (err) {
        console.log('error with exportData:', err);
        this.$snotify.error('Error exporting data', 'Error');
      } finally {
        this.exporting = false;
      }
    },
  },
};
</script>

<style scoped>
.page-title {
  margin-bottom: 30px;
}
.left-btn {
  margin-right: 10px;
}
.add-icon {
  margin-right: 7px;
}
#patient-list {
  margin-top: 30px;
}
/* .pt-link {
  color: '#64B5F6';
} */
</style>
