import 'chartjs-chart-box-and-violin-plot';
import { generateChart } from 'vue-chartjs';
const BoxPlot = generateChart('boxplot', 'boxplot');

// const boxplotData = {
//   // define label tree
//   labels: ['St. Francis', 'Denver Health', 'Indiana'],
//   datasets: [
//     {
//       label: 'BMI',
//       backgroundColor: 'rgba(23, 118, 210, 0.7)',
//       borderColor: 'rgb(23, 118, 210)',
//       borderWidth: 1,
//       outlierColor: '#999999',
//       padding: 10,
//       itemRadius: 0,
//       data: [
//         randomValues(100, 60, 100),
//         randomValues(100, 0, 100),
//         randomValues(100, 0, 40)
//       ]
//     }
//   ]
// };

export default {
  extends: BoxPlot,
  props: {
    title: { type: String },
    yLabel: { type: String },
    rawData: { type: Array }
  },

  data: () => ({}),
  methods: {
    makeChartData(rawData) {
      const labels = rawData.map(d => d.name);
      const data = rawData.map(d => d.data);

      return {
        labels,
        datasets: [
          {
            label: this.yLabel,
            backgroundColor: 'rgba(23, 118, 210, 0.7)',
            borderColor: 'rgb(23, 118, 210)',
            borderWidth: 1,
            outlierColor: '#999999',
            padding: 10,
            itemRadius: 0,
            data
          }
        ]
      };
    },
    makeOptions(title, yLabel) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: yLabel
              }
            }
          ]
        },
        height: 200
      };
    }
  },
  mounted() {
    const chartdata = this.makeChartData(this.rawData);
    const options = this.makeOptions(this.title, this.yLabel);
    this.renderChart(chartdata, options);
  }
};
