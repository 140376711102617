<template>
  <v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
    <v-layout justify-center align-center column pa-5>
      <div class="display-2 font-weight-black white--text text-xs-center mb-3">
        Chest Injury International Database
      </div>
      <div>
        <v-btn class="login-button" color="white" large outlined to="/login">
          <span class="headline">
            Log in
          </span>
        </v-btn>
      </div>

      <v-card
        max-width="550px"
        class="db-info"
        color="#047AAA"
        dark
        raised
        hover
      >
        <v-card-text>
          The Chest Injury International Database (CIID) is a HIPAA-compliant
          cloud-based data repository for the members of the
          <a href="https://cwisociety.org" target="_blank" class="white--text">
            Chest Wall Injury Society
          </a>
          to support an international research effort. It has been designed by
          thought-leaders from within the society and is powered by technology
          from
          <a
            href="https://www.fireflylab.org"
            target="_blank"
            class="white--text"
          >
            Firefly Lab </a
          >.
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'HomeHero'
};
</script>

<style scoped>
.home-hero {
  background: url('/img/skeleton.jpg');
  background-size: cover;
  width: 100%;
  height: 100%;
}
.db-info {
  margin-top: 100px;
}
</style>
