<template>
  <HomeHero />
</template>

<script>
import HomeHero from '@/components/HomeHero.vue';

export default {
  name: 'Home',
  components: {
    HomeHero
  }
};
</script>
