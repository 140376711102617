<template>
  <v-container fluid>
    <v-layout justify-center column>
      <v-flex>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card id="op-visits-list" class="elevation-6">
          <v-card-text>
            <OpVisitsTable
              :siteId="siteId"
              :patientId="patientId"
              :visits="visits"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn to="/patients" class="spaced-button">Close</v-btn>
            <v-btn color="primary" :to="`/opVisit/${siteId}/${patient.id}`">
              Add Visit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from '@/api.js';
import OpVisitsTable from '@/components/OpVisitsTable.vue';

export default {
  name: 'OpVisits',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true }
  },
  components: {
    OpVisitsTable
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      patient: {},
      gettingVisits: false,
      visits: []
    };
  },
  async created() {
    if (this.patientId) {
      let title = 'Follow-up Visits';
      await this.getPatientIds();
      if (this.patientId) {
        this.getVisits();

        // Add the relevant breadcrumbs.
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.patient.mrn}`,
            disabled: false,
            to: `/patient/${this.siteId}/${this.patient.id}`
          },
          {
            text: 'Follow-up Visits',
            disabled: true
          }
        ];

        title = `${this.patient.mrn} Follow-up Visits`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', {
        title,
        backPath: '/patients'
      });
    }
  },
  methods: {
    async getPatientIds() {
      const result = await api.post('/patients/getPatientIds', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.patient = result.data;
    },
    async getVisits() {
      this.gettingVisits = true;
      const result = await api.post('/outpatients/getOpVisits', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.visits = result.data;
      this.gettingVisits = false;
    }
  }
};
</script>
