import { isEmpty, sum } from 'lodash';
import {
  drugOptions,
  getDefaultRoute,
  getUnit,
  calculateEquivNarcDose,
} from '../utils/narcUtils';

function isNumber(val) {
  return typeof val === 'number' && val !== undefined && val !== null;
}

function parseInteger(line, separator, minVal, maxVal) {
  // Remove the separator text.
  const text = line.replace(separator, '');
  // Extract the digits (possibly negative values).
  const digits = text.match(/-?\d+/g);
  if (digits && digits.length > 0) {
    const score = parseInt(digits[0], 10);
    if (isNaN(score)) {
      return undefined;
    }
    if (isNumber(minVal) && score < minVal) {
      return undefined;
    }
    if (isNumber(maxVal) && score > maxVal) {
      return undefined;
    }
    return score;
  }
}

function parsePercent(line, separator) {
  // Remove the separator text.
  const text = line.replace(separator, '');
  // Extract the percent value.
  const percents = text.match(/\d{1,3}\s?%/g);
  if (percents && percents.length > 0) {
    const pctNumber = percents[0].replace('%', '');
    return parseInt(pctNumber, 10);
  }
}

function parseML(line, separator) {
  // Remove the separator text.
  const text = line.replace(separator, '');
  // Extract the percent value.
  const percents = text.match(/\d{2,4}\s?mL/g);
  if (percents && percents.length > 0) {
    const pctNumber = percents[0].replace('mL', '');
    return parseInt(pctNumber, 10);
  }
}

function parseL(line, separator) {
  // Remove the separator text.
  const text = line.replace(separator, '');
  // Extract the percent value.
  const percents = text.match(/\d{1,2}\s?L/g);
  if (percents && percents.length > 0) {
    const pctNumber = percents[0].replace('L', '');
    return parseInt(pctNumber, 10);
  }
}

function parsePainScore(line) {
  let text = line;
  // Keep the string in front of the parentheses.
  if (text.includes('(')) {
    text = text.split('(')[0].trim();
  }
  return parseInteger(text, 'Pain Score:', 0, 10);
}

function parseNarcRoute(narcStr, drug) {
  const words = narcStr.trim().toLowerCase().split(/\s+/);
  const foundRoute = ['IV', 'PO'].find((r) => words.includes(r.toLowerCase()));
  if (foundRoute) {
    return foundRoute;
  }
  return getDefaultRoute(drug);
}

function parseNarcDose(narcStr, unit) {
  // Search for a number before the unit.
  const regex = unit === 'mcg' ? /\d+(\s+)?mcg/ : /\d+(\s+)?mg/;
  const doseMatch = narcStr.match(regex);

  if (doseMatch && doseMatch.length > 0) {
    const doseStr = doseMatch[0];
    const doseVal = parseInt(doseStr.replace('mg', ''));
    if (doseVal) {
      return doseVal;
    }
  }

  return null;
}

function parseNarcotic(narcStr) {
  // Change "dilaudid" to hydromorphone.
  if (narcStr.includes('dilaudid')) {
    narcStr = narcStr.replace('dilaudid', 'Hydromorphone');
  } else if (narcStr.includes('Dilaudid')) {
    narcStr = narcStr.replace('Dilaudid', 'Hydromorphone');
  }

  // Look for any of the known drugs.
  const drug = drugOptions.find((d) =>
    narcStr.toLowerCase().includes(d.toLowerCase())
  );
  if (!drug) {
    return null;
  }

  const route = parseNarcRoute(narcStr, drug);
  const unit = getUnit(drug);
  const dose = parseNarcDose(narcStr, unit);
  const equivDose = calculateEquivNarcDose(drug, dose, route);
  // Check whether the narcotic info is valid and complete enough for storage.
  const valid = drug && dose && unit && route && equivDose;

  return { drug, dose, unit, route, equivDose, valid };
}

function parseNarcotics(line) {
  const narcStr = line.split(':')[1].trim();
  let narcParts = [];

  if (narcStr.includes(' and ')) {
    narcParts = narcStr.split(/(\s+)and(\s+)/);
  } else if (narcStr.includes(',')) {
    narcParts = narcStr.split(',');
  } else {
    narcParts = [narcStr];
  }

  narcParts = narcParts.map((p) => p.trim()).filter(Boolean);

  return narcParts.map(parseNarcotic).filter(Boolean);
}

function parseLocalPainControl(line) {
  let localStr = parseString(line, 'Loco Regional:');
  if (!localStr) {
    return null;
  } else {
    localStr = localStr.trim();
  }

  const onMatch = localStr.match(/(\s+)on(\s+)/);
  if (onMatch) {
    localStr = localStr.split(/(\s+)on(\s+)/)[0].trim();
  }

  const placedMatch = localStr.match(/(\s+)placed(\s+)/);
  if (placedMatch) {
    localStr = localStr.split(/(\s+)placed(\s+)/)[0].trim();
  }

  return localStr;
}

function parseBoolean(line, separator) {
  const text = line.split(separator)[1];
  if (text.trim().toLowerCase().includes('yes')) {
    return true;
  }
}

function parseString(line, separator) {
  const val = line.split(separator)[1].trim();
  if (val !== 'None' && val !== 'No Data Recorded') {
    return val;
  }
}

// Parse the data values from the Epic dot phrase text.
export function parseDotPhrase(phrase) {
  if (!phrase) {
    return null;
  }

  let painScore;
  let narcotics = [];
  let dailyNarcs; // total narcotic equivalent dose
  let acetaminophen;
  let ibuprofen;
  let gabapentin;
  let robaxin;
  let localPainCtrl; // Boolean value for whether any local pain controls were used
  let localPainCtrlName; // local pain control name
  let agitation;
  let respiratoryRate;
  let cough;
  let o2Saturation;
  let o2Unit;
  let spiroVolume;
  let o2Requirement;
  let o2Device;
  let fio2Requirement;

  const lines = phrase
    .trim()
    .split('\n')
    .map((l) => l.trim());

  const painScoreLine = lines.find((l) => l.includes('Pain Score:'));
  if (painScoreLine) {
    painScore = parsePainScore(painScoreLine);
  }

  let narcLine = lines.find((l) => l.includes('24 hour narcotics:'));
  if (!narcLine) {
    narcLine = lines.find((l) => l.includes('24 hour opiates:'));
  }
  if (!narcLine) {
    // deal with the typo "optiates"
    narcLine = lines.find((l) => l.includes('24 hour optiates:'));
  }
  if (narcLine) {
    narcotics = parseNarcotics(narcLine);

    if (!isEmpty(narcotics)) {
      dailyNarcs = sum(narcotics.map((n) => n.equivDose));
    }
  }

  const acetaminophenLine = lines.find((l) => l.includes('Acetaminophen:'));
  if (acetaminophenLine) {
    acetaminophen = parseBoolean(acetaminophenLine, 'Acetaminophen:');
  }

  const ibuprofenLine = lines.find((l) => l.includes('Ibuprofen:'));
  if (ibuprofenLine) {
    ibuprofen = parseBoolean(ibuprofenLine, 'Ibuprofen:');
  }

  const gabapentinLine = lines.find((l) => l.includes('Gabapentin:'));
  if (gabapentinLine) {
    gabapentin = parseBoolean(gabapentinLine, 'Gabapentin:');
  }

  const robaxinLine = lines.find((l) => l.includes('Robaxin:'));
  if (robaxinLine) {
    robaxin = parseBoolean(robaxinLine, 'Robaxin:');
  }

  const locoRegionalLine = lines.find((l) => l.includes('Loco Regional:'));
  if (locoRegionalLine) {
    const ctrlName = parseLocalPainControl(locoRegionalLine);
    if (ctrlName) {
      localPainCtrl = true;
      localPainCtrlName = ctrlName;
    }
  }

  const agitationLine = lines.find((l) => l.includes('RASS:'));
  if (agitationLine) {
    agitation = parseInteger(agitationLine, 'RASS:', -5, 4);
  }

  const resRateLine = lines.find((l) => l.includes('Respiratory Rate:'));
  if (resRateLine) {
    respiratoryRate = parseInteger(resRateLine, 'Respiratory Rate:', 0, 200);
  }

  const coughLine = lines.find((l) => l.includes('Cough Quality:'));
  if (coughLine) {
    const val = parseString(coughLine, 'Cough Quality:');
    if (['adequate', 'poor'].includes(val.toLowerCase())) {
      cough = val;
    }
  }

  const o2SaturationLine = lines.find((l) => l.includes('O2 Saturation:'));
  if (o2SaturationLine) {
    o2Saturation = parsePercent(o2SaturationLine, 'O2 Saturation:');
  }

  const spiroLine = lines.find((l) => l.includes('Incentive Spirometry:'));
  if (spiroLine) {
    spiroVolume = parseML(spiroLine, 'Incentive Spirometry:');
  }

  const o2ReqLine = lines.find((l) => l.includes('O2 Requirement:'));
  if (o2ReqLine) {
    o2Requirement = parseL(o2ReqLine, 'O2 Requirement:');
    o2Unit = 'LPM';
  }

  const o2DeviceLine = lines.find((l) => l.includes('O2 Device:'));
  if (o2DeviceLine) {
    o2Device = parseString(o2DeviceLine, 'O2 Device:');
  }

  const fio2Line = lines.find((l) => l.includes('FiO2 Requirement:'));
  if (fio2Line) {
    if (fio2Line.includes('%')) {
      fio2Requirement = parsePercent(fio2Line, 'FiO2 Requirement:') + '%';
    } else {
      fio2Requirement = parseString(fio2Line, 'FiO2 Requirement:');
    }
  }

  // If "chest tube" is mentioned anywhere, then assume a chest tube was used.
  const chestTube = lines.some((l) => l.toLowerCase().includes('chest tube'));

  return {
    painScore,
    narcotics,
    dailyNarcs,
    acetaminophen,
    ibuprofen,
    gabapentin,
    robaxin,
    localPainCtrl,
    localPainCtrlName,
    agitation,
    respiratoryRate,
    cough,
    o2Saturation,
    spiroVolume,
    o2Requirement,
    o2Unit,
    o2Device,
    fio2Requirement,
    chestTube,
  };
}
