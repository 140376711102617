<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12>
        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subtitle page-title">{{ subtitle }}</div>

              <v-alert v-if="dateConflict" type="error" text>
                This patient already has an inpatient day on
                {{ dateFormatted }}.
              </v-alert>

              <v-form ref="ipDayForm">
                <v-layout row wrap justify-space-between>
                  <v-flex xs12 md3>
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="dateFormatted"
                          label="Hospital Date"
                          prepend-icon="mdi-calendar"
                          hint="M/D/YYYY"
                          persistent-hint
                          readonly
                          required
                          :rules="dateRules"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="ipDay.hospital_date"
                        no-title
                        @input="dateMenu = false"
                      />
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 md8>
                    <v-textarea
                      name="comment"
                      label="Comments"
                      v-model="ipDay.comment"
                      placeholder="General comments about this day"
                    />
                  </v-flex>
                </v-layout>

                <!-- dot phrase import section -->
                <div v-if="showDotPhrase" class="page-section">
                  <h3>Epic Dot Phrase Import</h3>
                  <p>Paste the dot phrase here, for automated data import.</p>
                  <v-layout>
                    <v-flex md6>
                      <v-textarea
                        name="dotPhrase"
                        label="Dot Phrase Text"
                        v-model="ipDay.dot_phrase"
                        placeholder="Paste dot phrase text here..."
                        outlined
                        auto-grow
                        clearable
                        clear-icon="mdi-close-circle"
                      />
                    </v-flex>

                    <v-flex md5 ml-5 v-if="parsedPhrase">
                      <v-alert text color="info">
                        <b>Parsed Data</b>

                        <v-simple-table dense v-if="ipDay.dot_phrase">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Data Field</th>
                                <th class="text-left">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-left">Pain Score</td>
                                <td class="text-left">
                                  {{ parsedPhrase.painScore }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Narcotics</td>
                                <td class="text-left">
                                  <div
                                    v-for="narc in parsedPhrase.narcotics"
                                    :key="narc.drug"
                                  >
                                    {{ narc.drug }}
                                    {{ narc.dose }}
                                    {{ narc.unit }}
                                    {{ narc.route }}
                                    (Equiv: {{ narc.equivDose }})
                                    <span v-if="!narc.valid">
                                      <i>[Invalid]</i>
                                    </span>
                                  </div>
                                  <div v-if="parsedPhrase.narcotics.length > 1">
                                    (Total equiv. dose:
                                    {{ parsedPhrase.dailyNarcs }})
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Acetaminophen</td>
                                <td class="text-left">
                                  <v-icon v-if="parsedPhrase.acetaminophen">
                                    mdi-check
                                  </v-icon>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Ibuprofen</td>
                                <td class="text-left">
                                  <v-icon v-if="parsedPhrase.ibuprofen">
                                    mdi-check
                                  </v-icon>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Gabapentin</td>
                                <td class="text-left">
                                  <v-icon v-if="parsedPhrase.gabapentin">
                                    mdi-check
                                  </v-icon>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Robaxin</td>
                                <td class="text-left">
                                  <v-icon v-if="parsedPhrase.robaxin">
                                    mdi-check
                                  </v-icon>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Loco-Regional</td>
                                <td class="text-left">
                                  {{ parsedPhrase.localPainCtrlName }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Agitation (RASS)</td>
                                <td class="text-left">
                                  <span
                                    v-if="isDefined(parsedPhrase.agitation)"
                                  >
                                    {{ parsedPhrase.agitation }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Respiratory Rate</td>
                                <td class="text-left">
                                  <span v-if="parsedPhrase.respiratoryRate">
                                    {{ parsedPhrase.respiratoryRate }}
                                    breaths/min
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Cough Quality</td>
                                <td class="text-left">
                                  {{ parsedPhrase.cough }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">O2 Saturation</td>
                                <td class="text-left">
                                  <span v-if="parsedPhrase.o2Saturation">
                                    {{ parsedPhrase.o2Saturation }}%
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Incentive Spirometry</td>
                                <td class="text-left">
                                  <span v-if="parsedPhrase.spiroVolume">
                                    {{ parsedPhrase.spiroVolume }} mL
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">O2 Requirement</td>
                                <td class="text-left">
                                  <span v-if="parsedPhrase.o2Requirement">
                                    {{ parsedPhrase.o2Requirement }} L
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">O2 Device</td>
                                <td class="text-left">
                                  {{ parsedPhrase.o2Device }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">FiO2 Requirement</td>
                                <td class="text-left">
                                  <span v-if="parsedPhrase.fio2Requirement">
                                    {{ parsedPhrase.fio2Requirement }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left">Chest Tube</td>
                                <td class="text-left">
                                  <v-icon v-if="parsedPhrase.chestTube">
                                    mdi-check
                                  </v-icon>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-alert>
                    </v-flex>
                  </v-layout>
                </div>

                <!-- button row -->
                <v-layout row class="action-row">
                  <v-btn v-if="ipDay.id" small @click="deleteDialog = true">
                    Delete
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    :to="`/ipDays/${siteId}/${patient.id}`"
                    class="spaced-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="!maySave"
                    :loading="saving"
                    @click.prevent="save"
                  >
                    Save
                  </v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- delete confirmation dialog -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Confirmation for Deleting Day
        </v-card-title>

        <v-card-text>
          <p class="confirm-p">
            Are you sure that you want to delete this inpatient hospital day,
            and all the associated data?
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn @click="deleteDialog = false">Cancel</v-btn>

          <v-spacer />
          <v-btn color="error" :loading="deleting" @click="deleteDay">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { parseDotPhrase } from '../utils/parseDotPhrase';

export default {
  name: 'IpDay',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true },
    dayId: { type: Number, required: false },
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients',
        },
      ],
      patient: {},
      ipDay: {},
      showDotPhrase: false,
      dateConflict: false,
      dateMenu: false,
      saving: false,
      deleteDialog: false,
      deleting: false,
      dateRules: [
        (d) => Boolean(d) || 'Required',
        (d) => moment(d, 'M/D/YYYY', true).isValid() || 'M/D/YYYY', // Valid date string
        (d) => moment(d) >= moment(this.patient.injury_date) || 'Must be after injury', 
      ],
    };
  },
  async created() {
    await this.getPatientIds(this.patientId);
    if (this.dayId) {
      this.getIpDay(this.patientId, this.dayId);
    }
    this.showDotPhrase = await api.post('/inpatients/getShouldUseDotPhrase', {
      siteId: this.siteId,
    });
  },
  methods: {
    async getPatientIds() {
      const result = await api.post('/patients/getPatientIds', {
        siteId: this.siteId,
        patientId: this.patientId,
      });
      this.patient = result.data;

      this.breadcrumbItems.push({
        text: this.patient.mrn,
        disabled: false,
        to: `/ipDays/${this.siteId}/${this.patientId}`,
      });
      this.breadcrumbItems.push({
        text: 'Inpatient Days',
        disabled: true,
      });

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', {
        title: `${this.patient.mrn} Inpatient Day`,
        backPath: `/ipDays/${this.siteId}/${this.patientId}`,
      });
    },
    async getIpDay() {
      const result = await api.post('/inpatients/getIpDay', {
        siteId: this.siteId,
        patientId: this.patientId,
        dayId: this.dayId,
      });
      this.ipDay = result.data;
    },
    async checkDateConflict() {
      const result = await api.post('/inpatients/checkIpDayConflict', {
        siteId: this.siteId,
        patientId: this.patientId,
        dayId: this.dayId,
        date: this.ipDay.hospital_date,
      });
      this.dateConflict = result.data;
    },
    isDefined(val) {
      return val !== undefined && val !== null;
    },
    async save() {
      this.saving = true;
      await api.post('/inpatients/saveIpDay', {
        siteId: this.siteId,
        patientId: this.patientId,
        dayId: this.ipDay.id,
        date: this.ipDay.hospital_date,
        comment: this.ipDay.comment,
        dotPhrase: this.ipDay.dot_phrase,
        parsedPhrase: this.parsedPhrase,
      });
      this.saving = false;
      this.$snotify.success('Saved the Inpatient Day', 'Saved');
      this.$router.push(`/ipDays/${this.siteId}/${this.patient.id}`);
    },
    async deleteDay() {
      this.deleting = true;
      await api.post('/inpatients/deleteIpDay', {
        siteId: this.siteId,
        dayId: this.ipDay.id,
      });
      this.deleting = false;
      this.$snotify.success('Deleted the Inpatient Day', 'Deleted');
      this.$router.push(`/ipDays/${this.siteId}/${this.patient.id}`);
    },
  },
  computed: {
    title() {
      const verb = this.dayId ? 'Edit' : 'Add';
      let title = `${verb} Inpatient Day`;
      if (this.patient.mrn) {
        title += ` for ${this.patient.mrn}`;
      }
      return title;
    },
    subtitle() {
      let subtitle = '';
      if (this.patient.admit_date && this.ipDay.hospital_date) {
        // Calculate the number of days between admit date and the given inpatient hospital date.
        const day = moment(this.ipDay.hospital_date).diff(
          this.patient.admit_date,
          'days'
        );
        subtitle = `Hospital Day ${day}`;
      }

      return subtitle;
    },
    dateFormatted() {
      return this.ipDay.hospital_date
        ? moment(this.ipDay.hospital_date).format('M/D/YYYY')
        : '';
    },
    parsedPhrase() {
      if (!this.ipDay.dot_phrase) {
        return null;
      }
      return parseDotPhrase(this.ipDay.dot_phrase);
    },
    maySave() {
      return this.ipDay.hospital_date && !this.dateConflict;
    },
  },
  watch: {
    dateFormatted() {
      if (this.ipDay.hospital_date) {
        this.checkDateConflict();
      }
    },
  },
};
</script>

<style scoped>
.page-section {
  padding-top: 30px;
}
</style>
