<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>
              <PftsForm
                :pfts="pfts"
                :backUrl="`/opVisits/${siteId}/${pfts.patient_id}`"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import PftsForm from '@/components/PftsForm.vue';

export default {
  name: 'OpPfts',
  props: {
    siteId: { type: Number, required: true },
    visitId: { type: Number, required: true }
  },
  components: { PftsForm },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      pfts: {},
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Pulmonary Function Tests';
      if (this.pfts.mrn) {
        title += ` for Outpatient ${this.pfts.mrn}`;
      }
      return title;
    },
    subtitle() {
      let subtitle = '';
      if (this.pfts.visit_date) {
        subtitle += moment(this.pfts.visit_date).format('dddd M/D/YYYY');
      }
      if (this.pfts.post_injury_day) {
        subtitle += `, Post-Injury Day ${this.pfts.post_injury_day}`;
      }
      return subtitle;
    }
  },
  async created() {
    if (this.visitId) {
      await this.getPfts();
      // Make sure that the pfts object has the visit ID.
      if (!this.pfts.op_visit_id) {
        this.pfts.op_visit_id = this.visitId;
      }
    }
  },
  methods: {
    async getPfts() {
      const result = await api.post('/outpatients/getOpPfts', {
        siteId: this.siteId,
        visitId: this.visitId
      });
      this.pfts = result.data;

      let title = 'PFTs';
      let backPath = '/patients';
      if (this.pfts) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.pfts.patient_id}`,
            disabled: false,
            to: `/patients/${this.siteId}/${this.pfts.patient_id}`
          },
          {
            text: 'Follow-up Visits',
            disabled: false,
            to: `/opVisits/${this.siteId}/${this.pfts.patient_id}`
          },
          {
            text: `Day ${this.pfts.post_injury_day} PFTs`,
            disabled: true
          }
        ];

        title = `${this.pfts.mrn} Visit Day ${this.pfts.post_injury_day},  PFTs`;
        backPath = `/opVisits/${this.siteId}/${this.pfts.patient_id}`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', { title, backPath });
    },
    async save() {
      this.saving = true;
      await api.post('/outpatients/saveOpPfts', {
        siteId: this.siteId,
        pfts: this.pfts
      });
      this.saving = false;
      this.$snotify.success('Saved the PFTs', 'Saved');
      this.$router.push(`/opVisits/${this.siteId}/${this.pfts.patient_id}`);
    }
  }
};
</script>
