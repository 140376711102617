<template>
  <v-card class="elevation-6">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="3" v-if="showSiteSelect" class="flex-shrink-1">
            <v-select
              :items="sites"
              label="Site"
              v-model="filter.siteId"
              item-value="id"
              item-text="name"
              @input="(siteId) => $emit('setSite', siteId)"
            />
          </v-col>

          <!-- date section -->
          <v-col cols="4" class="flex-shrink-1">
            <v-row>
              <v-text-field
                label="Admit From"
                hint="MM/DD/YYYY"
                persistent-hint
                clearable
                v-model="filter.fromDate"
                class="date-field"
              />
              <v-text-field
                label="Admit To"
                hint="MM/DD/YYYY"
                persistent-hint
                clearable
                v-model="filter.toDate"
                class="date-field to-date"
              />
            </v-row>
          </v-col>

          <!-- search bar -->
          <v-col cols="showSiteSelect ? 5 : 9" class="flex-grow-1">
            <v-text-field
              prepend-icon="mdi-magnify"
              label="Search patients"
              v-model="filter.query"
              clearable
              clear-icon="mdi-close"
            />
          </v-col>
        </v-row>

        <v-row v-if="showAddButton || showExportButton">
          <v-spacer />
          <span v-if="showExportButton" id="export-btn-span">
            <v-btn color="light" v-if="exporting" disabled>
              <v-progress-circular indeterminate size="25" />
              Exporting...
            </v-btn>
            <v-btn
              color="light"
              v-else
              @click.prevent="() => $emit('export', filter.siteId)"
            >
              Export Data
            </v-btn>
          </span>

          <v-btn color="primary" :to="`/patient/${siteId}`">Add Patient</v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PatientsFilter',
  props: {
    siteId: { type: Number, required: true },
    filter: { type: Object, required: true },
    showSiteSelect: Boolean,
    sites: { type: Array, required: true },
    showAddButton: Boolean,
    showExportButton: Boolean,
    exporting: Boolean,
  },
  data() {
    return {
      exporting: false,
    };
  },
};
</script>

<style scoped>
.date-field {
  max-width: 125px;
}
.to-date {
  margin-left: 5px;
}
#export-btn-span {
  padding-right: 15px;
}
</style>
