<template>
  <v-container>
    <v-form ref="hospitalCareForm">
      <v-layout wrap row>
        <v-flex xs12 md3>
          <span v-if="!patient.admit_date" class="red--text text--accent-2">
            Required
          </span>
          <v-menu
            v-model="admitDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="admitDateFormatted"
                label="Admit Date"
                prepend-icon="mdi-calendar"
                hint="M/D/YYYY"
                persistent-hint
                required
                read-only
                :rules="[v => !!v || 'Required']"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="patient.admit_date"
              no-title
              @input="admitDateMenu = false"
            />
          </v-menu>
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-text-field
            label="Hospital LOS"
            hint="Number of days"
            persistent-hint
            type="number"
            min="0"
            v-model="patient.hospital_los"
          />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-text-field
            label="ICU LOS"
            hint="Number of days in ICU"
            persistent-hint
            type="number"
            min="0"
            v-model="patient.icu_los"
          />
        </v-flex>
      </v-layout>

      <v-layout wrap row>
        <v-flex xs12 md3>
          <v-checkbox v-model="patient.mortality" label="Mortality" />

          <v-menu
            v-model="mortalityDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            full-width
            max-width="290px"
            v-if="patient.mortality"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="mortalityDateFormatted"
                label="Mortality Date"
                prepend-icon="mdi-calendar"
                hint="M/D/YYYY"
                persistent-hint
                required
                :rules="[v => !!v || 'Required']"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="patient.mortality_date"
              no-title
              @input="mortalityDateMenu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.readmit" label="Readmit" />
          <span class="caption">Patient had readmission after this case.</span>
        </v-flex>
      </v-layout>

      <!-- readmission row -->
      <v-layout wrap row v-if="patient.readmit">
        <v-flex xs12 md3>
          <v-menu
            v-model="readmitDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            full-width
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="readmitDateFormatted"
                label="Readmit Date"
                prepend-icon="mdi-calendar"
                hint="M/D/YYYY"
                persistent-hint
                readonly
                required
                :rules="readmitDateRules"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="patient.readmit_date"
              no-title
              @input="readmitDateMenu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-text-field
            label="Readmit Diagnosis"
            v-model="patient.readmit_diagnosis"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-text-field
            label="Readmit LOS"
            hint="Number of hospital days during readmission"
            persistent-hint
            type="number"
            min="0"
            max="100"
            v-model="patient.readmit_los"
            :rules="readmitLosRules"
          />
        </v-flex>
      </v-layout>

      <!-- button row -->
      <v-layout row class="action-row">
        <v-btn @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn to="/patients" class="spaced-button">Cancel</v-btn>
        <v-spacer />
        <v-btn
          :loading="saving"
          @click.prevent="$emit('saveAndClose')"
          class="spaced-button"
          :disabled="!valid"
        >
          Save & Close
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :loading="saving"
          @click.prevent="$emit('next')"
          :disabled="!valid"
        >
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  name: 'HospitalCareForm',
  props: {
    patient: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      admitDateMenu: false,
      readmitDateMenu: false,
      mortalityDateMenu: false,
      readmitDateRules: [
        (d) => Boolean(d) || 'Required',
        (d) => moment(d, 'M/D/YYYY', true).isValid() || 'M/D/YYYY', // Valid date string
        (d) => moment(d) >= moment(this.patient.admit_date) || 'Must be after initial admit', 
        (d) => moment(d) <= moment() || 'Cannot be in future' // Can't be born in the future.
      ],
      readmitLosRules: [
        l => (l >= 0) && (l <= 100) || 'Must be in range 0 to 100',
      ],
    };
  },
  computed: {
    admitDateFormatted() {
      return this.formatDate(this.patient.admit_date);
    },
    readmitDateFormatted() {
      return this.formatDate(this.patient.readmit_date);
    },
    mortalityDateFormatted() {
      return this.formatDate(this.patient.mortality_date);
    },
    valid() {
      return Boolean(this.patient.admit_date);
    }
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('M/D/YYYY') : '';
    }
  }
};
</script>
