<template>
  <v-container>
    <v-layout justify-center column>
      <v-flex fluid>
        <v-card id="video-card" class="elevation-6 mx-auto" max-width="700">
          <v-row justify="space-around">
            <v-col cols="auto">
              <p>CIID Tutorial and Walk-Through Video</p>
              <youtube
                video-id="mmiSHnFo94k"
                player-width="650"
                player-height="550"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Help',
  async created() {
    this.$store.dispatch('setNavData', { title: 'Help' });
  }
};
</script>

<style scoped>
#video-card {
  margin-top: 30px;
}
</style>
