<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>
              <PftsForm
                :pfts="pfts"
                :backUrl="`/ipDays/${siteId}/${pfts.patient_id}`"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import PftsForm from '@/components/PftsForm.vue';

export default {
  name: 'IpPfts',
  props: {
    siteId: { type: Number, required: true },
    dayId: { type: Number, required: false }
  },
  components: { PftsForm },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      pfts: {},
      loading: false,
      saving: false,
    };
  },
  computed: {
    title() {
      let title = 'Pulmonary Function Tests';
      if (this.pfts.mrn) {
        title += ` for ${this.pfts.mrn}`;
      }
      return title;
    },
    subtitle() {
      if (this.pfts.hospital_date) {
        const date = moment(this.pfts.hospital_date).format('dddd M/D/YYYY');
        return `${date}, Hospital Day ${this.pfts.hospital_day}`;
      }
      return '';
    }
  },
  async created() {
    if (this.dayId) {
      await this.getPfts();
    }
  },
  methods: {
    async getPfts() {
      const result = await api.post('/inpatients/getIpPfts', {
        siteId: this.siteId,
        dayId: this.dayId
      });
      this.pfts = result.data;

      // If we don't have a PFT date, then assume it's the hospital date.
      if (!this.pfts.pft_date) {
        this.pfts.pft_date = this.pfts.hospital_date;
      }

      if (this.pfts) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.pfts.mrn}`,
            disabled: false,
            to: `/patients/${this.siteId}/${this.pfts.patient_id}`
          },
          {
            text: 'Inpatient Days',
            disabled: false,
            to: `/ipDays/${this.siteId}/${this.pfts.patient_id}`
          },
          {
            text: `Day ${this.pfts.hospital_day} PFTs`,
            disabled: true
          }
        ];

        this.$store.dispatch('setNavData', {
          title: `${this.pfts.mrn} Day ${this.pfts.hospital_day}, PFTs`,
          backPath: `/ipDays/${this.siteId}/${this.pfts.patient_id}`
        });
      }
    },
    async save() {
      this.saving = true;
      await api.post('/inpatients/saveIpPfts', {
        siteId: this.siteId,
        pfts: this.pfts
      });
      this.saving = false;
      this.$snotify.success('Saved the PFTs', 'Saved');
      // Go back to the inpatient page.
      this.$router.push(`/ipDays/${this.siteId}/${this.pfts.patient_id}`);
    }
  }
};
</script>
