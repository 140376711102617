<template>
  <v-container>
    <v-layout fluid>
      <v-flex fluid>
        <v-card id="users-list" class="elevation-6">
          <v-card-text>
            <!-- Site select -->
            <v-row>
              <v-col cols="4" v-if="showSiteSelect" class="flex-shrink-1">
                <v-select
                  :items="sites"
                  label="Site"
                  v-model="siteId"
                  item-value="id"
                  item-text="name"
                  @input="handleSiteId"
                />
              </v-col>
            </v-row>

            <UsersTable :users="users" v-on:unlock="handleUnlock" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <span v-if="showExportButton" id="export-btn-span">
              <v-btn color="light" v-if="exporting" disabled class="left-btn">
                <v-progress-circular indeterminate size="25" />
                Exporting...
              </v-btn>
              <v-btn
                color="light"
                v-else
                @click="handleExportUsers"
                class="left-btn"
              >
                Export Users
              </v-btn>
            </span>

            <v-btn color="primary" to="/user">Add User</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import api from '@/api';
import UsersTable from '@/components/UsersTable';

export default {
  name: 'Users',
  components: {
    UsersTable,
  },
  data() {
    return {
      siteId: '',
      sites: [],
      users: [],
      exporting: false,
    };
  },
  async created() {
    if (this.user) {
      this.siteId = this.user.site_id;
    }
    if (this.user && this.user.role === 'CIID admin') {
      this.getSites();
    }
    if (this.user && ['CIID admin', 'Site admin'].includes(this.user.role)) {
      this.getUsers(this.siteId);
    }
    this.$store.dispatch('setNavData', { title: 'Users', backPath: '' });
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    showSiteSelect() {
      return this.user && this.user.role === 'CIID admin';
    },
    showExportButton() {
      return this.user && this.user.role === 'CIID admin';
    },
  },
  methods: {
    async getSites() {
      const result = await api.post('/sites/getSites');
      const sites = result.data;
      if (sites && sites.length > 0) {
        this.sites = sites;
      }
    },
    async getUsers(id) {
      const result = await api.post('/users/getUsersWithStats', {
        siteId: id,
      });
      this.users = result.data;
    },
    handleSiteId(id) {
      this.siteId = id;
      this.getUsers(id);
    },
    async handleUnlock(id) {
      this.users = this.users.map((u) => {
        if (u.id !== id) {
          return u;
        }

        return { ...u, account_status: 'active' };
      });

      await api.post('/auth/unlockAccount', { id });
      this.$snotify.success('Unlocked the account', 'Unlocked');
    },
    async handleExportUsers() {
      // Add today's date to the file name.
      const fileName = `CIID Users ${moment().format('YYYY-MM-DD')}.xlsx`;
      this.exporting = true;

      try {
        // Call the API to get the xlsx file data.
        const result = await api({
          method: 'post',
          url: '/exports/exportUsers',
          headers: {
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'blob', // I could also use 'arraybuffer' here.
        });

        const fileUrl = window.URL.createObjectURL(new Blob([result.data]));
        // Simulate the user clicking on a link to download the file.
        const link = document.createElement('a');
        link.setAttribute('href', fileUrl);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        // Click the link to activate the download.
        link.click();
        // Clean up and remove the link.
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileUrl);
      } catch (err) {
        console.log('error with exportUsers:', err);
        this.$snotify.error('Error exporting data', 'Error');
      } finally {
        this.exporting = false;
      }
    },
  },
};
</script>

<style scoped>
.left-btn {
  margin-right: 10px;
}
.add-icon {
  margin-right: 7px;
}
#users-list {
  margin-top: 30px;
}
</style>
