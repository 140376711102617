<template>
  <div @click="$emit('clickFracture', fracture)" class="">
    <strong v-if="fracture.type" class="primary--text d-flex justify-center">
      {{ fracture.type[0].toUpperCase() }}
    </strong>

    <v-checkbox v-else class="shrink" disabled />
  </div>
</template>

<script>
export default {
  name: 'FractureCell',
  props: {
    fracture: { type: Object, required: true },
  },
};
</script>

<style scoped>
.shrink {
  margin: 0px;
  padding: 0px;
  height: 20px;
}
</style>
