<template>
  <v-container>
    <ActionRow :siteId="this.user.site_id" />

    <!-- data completeness section -->
    <div class="section-container">
      <span class="text-h5 text--secondary">Data Volume</span>
      <v-layout>
        <v-row>
          <v-col v-if="ptCountsOverTime">
            <v-card id="pt-counts-time" class="elevation-6 chart">
              <v-card-text>
                <TimeLineChart :rawData="ptCountsOverTime" title="Number of Patients Over Time" xLabel="Month"
                  yLabel="Patients Entered" countField="num_patients" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="patientCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <PtCountBarChart :rawData="patientCounts" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="lagCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="lagCounts" title="Patient Data Entry Lag"
                  yLabel="Patients With Admit Dates" yesLabel="Prospective" noLabel="Retrospective" />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- patient record completeness -->
          <v-col v-if="ptCompleteCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="ptCompleteCounts" title="Patient Record Completeness Status"
                  yLabel="Patients" yesLabel="Complete" noLabel="Not Complete" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <div class="section-container">
      <span class="text-h5 text--secondary">Demographics</span>
      <v-layout>
        <v-row>
          <v-col v-if="ages.length > 0">
            <v-card id="ages" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Age on Admission" yLabel="Age (Years)" :rawData="ages" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="bmis.length > 0">
            <v-card id="bmis" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Body Mass Index" yLabel="BMI" :rawData="bmis" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="gcss.length > 0">
            <v-card id="gcss" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Glascow Coma Score" yLabel="GCS on Admission" :rawData="gcss" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <!-- rib fractures section -->
    <div class="section-container">
      <span class="text-h5 text--secondary">
        Surgical Stabilization of Rib Fractures
      </span>
      <v-layout>
        <v-row>
          <v-col v-if="fxLocationCounts.length > 0">
            <v-card id="fx-heatmap" class="elevation-6 chart">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6" class="flex-shrink-1">
                    <v-select :items="fxTypes" label="Fracture Type" v-model="fxType" item-value="id" item-text="label"
                      @change="handleFxTypeChange" />
                  </v-col>
                </v-row>
                <FxHeatmap :rawData="fxLocationCounts" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="fxGridCounts.length > 0">
            <v-card id="fx-grid-counts" class="elevation-6 chart">
              <v-card-text>
                <FxGridBarChart :rawData="fxGridCounts" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="admitToCaseLags.length > 0">
            <v-card id="or-time-counts" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Amit to Case Date Lag for Operative Patients" yLabel="Admit to Case Lag (Days)"
                  :rawData="admitToCaseLags" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="orTimeCounts.length > 0">
            <v-card id="or-time-counts" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="orTimeCounts" title="OR Time Status for Operative Patients"
                  yLabel="Operative Patients" yesLabel="Has OR Time" noLabel="Needs OR Time" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="positionCounts.length > 0">
            <v-card id="position-counts" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="positionCounts" title="Patient Position Status for Operative Patients"
                  yLabel="Operative Patients" yesLabel="Has Position" noLabel="Needs Position" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="fractureTypeCounts.length > 0">
            <v-card id="fracture-type-counts" class="elevation-6 chart">
              <v-card-text>
                <StackedBarChart title="Fracture Types" yLabel="Patients With Fractures" :rawData="fractureTypeCounts"
                  sectionField="type" countField="num_fractures" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <div class="section-container">
      <span class="text-h5 text--secondary">
        Associated Injuries and Procedures
      </span>
      <v-layout>
        <v-row>
          <v-col v-if="bpc18s.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Blunt Pulmonary Contusion 18" yLabel="BPC 18" :rawData="bpc18s" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="isses.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <BoxPlotChart title="Injury Severity Score" yLabel="ISS" :rawData="isses" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="mechanisms.length > 0">
            <v-card id="mechs-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBarChart title="Mechansisms Of Injury" yLabel="Patients With Injury Mechansisms"
                  :rawData="mechanisms" sectionField="mechanism" countField="num_patients" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <!-- Inpatient section -->
    <div class="section-container">
      <span class="text-h5 text--secondary">Daily Inpatient Follow-Up</span>
      <v-layout>
        <v-row>
          <v-col v-if="ipDayCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="ipDayCounts" title="Inpatient Day Status" yLabel="Patients"
                  yesLabel="Has At Least 1 Day" noLabel="No Days Entered" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="ipPainMedsCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="ipPainMedsCounts" title="Inpatient Pain Meds Status" yLabel="Patients"
                  yesLabel="Has a Pain Meds Record" noLabel="No Pain Meds Entered" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="ipDailyNarcsCounts.length > 0">
            <v-card id="ip-narcs" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="ipDailyNarcsCounts" title="Inpatient Daily Opiate Status"
                  yLabel="Patients" yesLabel="Has a Daily Narc" noLabel="No Opiates Entered" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="ipSpiroCounts.length > 0">
            <v-card id="ip-narcs" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="ipSpiroCounts" title="Inpatient Spirometry Status" yLabel="Patients"
                  yesLabel="Has a Spiro Record" noLabel="No Spiro Entered" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="losCounts.length > 0">
            <v-card id="los-counts" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="losCounts" title="Hospital Length of Stay Status" yLabel="Patients"
                  yesLabel="Has a LOS value" noLabel="No LOS Entered" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>

    <!-- Outpatient section -->
    <div class="section-container">
      <span class="text-h5 text--secondary">Outpatient Follow-Up Visits</span>
      <v-layout>
        <v-row>
          <v-col v-if="opVisitCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="opVisitCounts" title="Outpatient Visits Status"
                  yLabel="Patients (60 Days After Admit)" yesLabel="Has a Visit" noLabel="No Visits Entered" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="opVitalsCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="opVitalsCounts" title="Outpatient Vital Signs Status"
                  yLabel="Outpatients" yesLabel="Has a Vitals Record" noLabel="No Vitals Entered" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col v-if="opPainMedsCounts.length > 0">
            <v-card id="patient-list" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="opPainMedsCounts" title="Outpatient Pain Meds Status"
                  yLabel="Outpatients" yesLabel="Has a Pain Meds Record" noLabel="No Pain Meds Entered" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="opNarcsCounts.length > 0">
            <v-card id="op-narcs" class="elevation-6 chart">
              <v-card-text>
                <StackedBinaryBarChart :rawData="opNarcsCounts" title="Outpatient Opiate Status" yLabel="Outpatients"
                  yesLabel="Has a Narc Record" noLabel="No Opiates Entered" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { orderBy } from 'lodash';
import api from '@/api.js';
import ActionRow from '../components/analytics/ActionRow';
import PtCountBarChart from '../components/analytics/PtCountBarChart';
import FxGridBarChart from '../components/analytics/FxGridBarChart';
import StackedBinaryBarChart from '../components/analytics/StackedBinaryBarChart';
import BoxPlotChart from '../components/analytics/BoxPlotChart';
import StackedBarChart from '../components/analytics/StackedBarChart';
import TimeLineChart from '../components/analytics/TimeLineChart';
import FxHeatmap from '../components/analytics/FxHeatmap';

export default {
  name: 'Analytics',
  components: {
    ActionRow,
    PtCountBarChart,
    FxGridBarChart,
    StackedBinaryBarChart,
    BoxPlotChart,
    StackedBarChart,
    TimeLineChart,
    FxHeatmap,
  },
  data() {
    return {
      patientCounts: [],
      ptCountsOverTime: null,
      fxGridCounts: [],
      fxLocationCounts: [],
      orTimeCounts: [],
      lagCounts: [],
      bmis: [],
      ages: [],
      admitToCaseLags: [],
      gcss: [],
      bpc18s: [],
      isses: [],
      ipDayCounts: [],
      ipPainMedsCounts: [],
      ipDailyNarcsCounts: [],
      ipSpiroCounts: [],
      losCounts: [],
      opVisitCounts: [],
      opVitalsCounts: [],
      opPainMedsCounts: [],
      opNarcsCounts: [],
      ptCompleteCounts: [],
      mechanisms: [],
      positionCounts: [],
      fractureTypeCounts: [],

      // Fracture type for the fracture heatmap
      fxType: 'all',
      fxTypes: [
        { id: 'all', label: 'All Types' },
        { id: 'displaced', label: 'Displaced' },
        { id: 'undisplaced', label: 'Undisplaced' },
        { id: 'offset', label: 'Offset' },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  async created() {
    this.getPatientCounts();
    this.getPatientCountsOverTime();
    this.getFxGridCounts();
    this.getFxLocationCounts();
    this.getOrTimeCounts();
    this.getLagCounts();
    this.getBmis();
    this.getAges();
    this.getAdmitToCaseLags();
    this.getGcss();
    this.getBpc18s();
    this.getIsses();
    this.getIpDayCounts();
    this.getIpPainMedsCounts();
    this.getIpDailyNarcsCounts();
    this.getIpSpiroCounts();
    this.getHospitalLosCounts();
    this.getOpVisitCounts();
    this.getOpVitalsCounts();
    this.getOpPainMedsCounts();
    this.getOpNarcsCounts();
    this.getPatientCompleteCounts();
    this.getMechanismsOfInjury();
    this.getPositionCounts();
    this.getFractureTypeCounts();

    this.$store.dispatch('setNavData', { title: 'CIID System Analytics', backPath: '' });
  },
  methods: {
    async getPatientCounts() {
      const result = await api.post('/analytics/getPatientCounts');
      let counts = result.data;
      // Order the rows for the plot.
      counts = orderBy(counts, ['num_patients', 'name'], ['desc', 'asc']);
      this.patientCounts = counts;
    },
    async getPatientCountsOverTime() {
      const result = await api.post('/analytics/getPatientCountsOverTime');
      this.ptCountsOverTime = result.data;
    },
    async getFxGridCounts() {
      const result = await api.post('/analytics/getFxGridCounts');
      let counts = result.data;
      // Order the rows for the plot.
      counts = orderBy(counts, ['total'], ['desc']);
      this.fxGridCounts = counts;
    },
    // Whenever I change the fracture type, then fetch the fracture counts for the given type.
    handleFxTypeChange() {
      this.getFxLocationCounts(this.fxType);
    },
    async getFxLocationCounts(fxType) {
      const result = await api.post('/analytics/countFracturesByLocation', {
        fxType,
      });
      this.fxLocationCounts = result.data;
    },
    async getOrTimeCounts() {
      const result = await api.post('/analytics/getOrTimeCounts');
      let counts = result.data;
      // Order the rows for the plot.
      counts = orderBy(counts, ['total'], ['desc']);
      this.orTimeCounts = counts;
    },
    async getLagCounts() {
      const result = await api.post('/analytics/getPatientEntryLagCounts');
      let counts = result.data;
      // Order the rows for the plot.
      counts = orderBy(counts, ['total'], ['desc']);
      this.lagCounts = counts;
    },
    async getBmis() {
      const result = await api.post('/analytics/getBmis');
      this.bmis = result.data;
    },
    async getAges() {
      const result = await api.post('/analytics/getAges');
      this.ages = result.data;
    },
    async getAdmitToCaseLags() {
      const result = await api.post('/analytics/getAdmitToCaseLags');
      this.admitToCaseLags = result.data;
    },
    async getGcss() {
      const result = await api.post('/analytics/getGcss');
      this.gcss = result.data;
    },
    async getBpc18s() {
      const result = await api.post('/analytics/getBpc18s');
      this.bpc18s = result.data;
    },
    async getIsses() {
      const result = await api.post('/analytics/getIsses');
      this.isses = result.data;
    },
    async getIpDayCounts() {
      const result = await api.post('/analytics/getIpDayCounts');
      this.ipDayCounts = result.data;
    },
    async getIpPainMedsCounts() {
      const result = await api.post('/analytics/getIpPainMedsCounts');
      this.ipPainMedsCounts = result.data;
    },
    async getIpDailyNarcsCounts() {
      const result = await api.post('/analytics/getIpDailyNarcsCounts');
      this.ipDailyNarcsCounts = result.data;
    },
    async getIpSpiroCounts() {
      const result = await api.post('/analytics/getIpSpiroCounts');
      this.ipSpiroCounts = result.data;
    },
    async getHospitalLosCounts() {
      const result = await api.post('/analytics/getHospitalLosCounts');
      this.losCounts = result.data;
    },
    async getOpVisitCounts() {
      const result = await api.post('/analytics/getOpVisitCounts');
      this.opVisitCounts = result.data;
    },
    async getOpVitalsCounts() {
      const result = await api.post('/analytics/getOpVitalsCounts');
      this.opVitalsCounts = result.data;
    },
    async getOpPainMedsCounts() {
      const result = await api.post('/analytics/getOpPainMedsCounts');
      this.opPainMedsCounts = result.data;
    },
    async getOpNarcsCounts() {
      const result = await api.post('/analytics/getOpNarcsCounts');
      this.opNarcsCounts = result.data;
    },
    async getPatientCompleteCounts() {
      const result = await api.post('/analytics/getPatientCompleteCounts');
      this.ptCompleteCounts = result.data;
    },
    async getMechanismsOfInjury() {
      const result = await api.post('/analytics/getMechanismsOfInjury');
      this.mechanisms = result.data;
    },
    async getPositionCounts() {
      const result = await api.post('/analytics/getPositionCounts');
      this.positionCounts = result.data;
    },
    async getFractureTypeCounts() {
      const result = await api.post('/analytics/getFractureTypeCounts');
      this.fractureTypeCounts = result.data;
    },
  },
};
</script>

<style scoped>
.row {
  margin-top: 3px;
  margin-bottom: 3px;
}

.chart {
  max-width: 500px;
  margin-right: 10px;
}

.section-container {
  padding-top: 20px;
}
</style>
