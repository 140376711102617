<template>
  <div>
    <v-layout wrap row class="top-row">
      <v-flex xs12 md3>
        <v-checkbox
          v-model="data.anti_coag_platelet"
          label="Anticoagulation / Antiplatelet"
        />
      </v-flex>

      <v-flex xs12 md2 offset-md1 v-if="data.anti_coag_platelet">
        <v-checkbox v-model="data.alpha_coag" label="α-Coagulation" />
      </v-flex>

      <v-flex xs12 md5 offset-md1 v-if="data.alpha_coag">
        <v-combobox
          label="Coagulation Options"
          chips
          clearable
          multiple
          hint="You can add new options."
          persistent-hint
          :items="coagOptions"
          item-value="id"
          item-text="name"
          :value="coags"
          @input="handleCoagChange"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="removeCoag(item)"
            >
              {{ item.name || item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-flex>
    </v-layout>

    <v-layout wrap row class="top-row" v-if="data.anti_coag_platelet">
      <v-flex xs12 md2 offset-md4>
        <v-checkbox v-model="data.alpha_platelet" label="α-Platelet" />
      </v-flex>

      <v-flex xs12 md5 offset-md1 v-if="data.alpha_platelet">
        <v-combobox
          label="Platelet Options"
          chips
          clearable
          multiple
          hint="You can add new options."
          persistent-hint
          :items="plateletOptions"
          item-value="id"
          item-text="name"
          :value="platelets"
          @input="handlePlateletChange"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="removePlatelet(item)"
            >
              {{ item.name || item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import api from '@/api.js';

export default {
  name: 'Anticoagulation',
  props: {
    siteId: { type: Number, required: true },
    data: { type: Object, required: true },
    coags: { type: Array, required: true },
    platelets: { type: Array, required: true }
  },
  data() {
    return {
      coagOptions: [],
      plateletOptions: []
    };
  },
  methods: {
    // ----------- coagulation section -----------
    async getCoagOptions() {
      const result = await api.post('/coagulation/getCoagOptions', {
        siteId: this.siteId
      });
      this.coagOptions = result.data;
    },
    removeCoag(item) {
      const index = this.coags.indexOf(item);
      if (index > -1) {
        const newCoags = [
          ...this.coags.slice(0, index),
          ...this.coags.slice(index + 1)
        ];
        this.handleCoagChange(newCoags);
      }
    },
    handleCoagChange(newCoags) {
      this.$emit('setCoags', newCoags);
    },

    // ----------- platelet section -----------
    async getPlateletOptions() {
      const result = await api.post('/coagulation/getPlateletOptions', {
        siteId: this.siteId
      });
      this.plateletOptions = result.data;
    },
    removePlatelet(item) {
      const index = this.platelets.indexOf(item);
      if (index > -1) {
        const newPlatelets = [
          ...this.platelets.slice(0, index),
          ...this.platelets.slice(index + 1)
        ];
        this.handlePlateletChange(newPlatelets);
      }
    },
    handlePlateletChange(newPlatelets) {
      this.$emit('setPlatelets', newPlatelets);
    }
  },

  created() {
    this.getCoagOptions();
    this.getPlateletOptions();
  }
};
</script>

<style scoped>
.top-row {
  margin-top: 20px;
}
</style>
