<template>
  <v-data-table :headers="headers" :items="patients" :items-per-page="10">
    <!-- MRN -->
    <template v-slot:item.mrn="{ item }">
      <router-link :to="`/patient/${item.site_id}/${item.id}`">
        {{ item.mrn }}
      </router-link>
    </template>

    <!-- patient link -->
    <template v-slot:item.patient_link="{ item }">
      <router-link :to="`/patient/${item.site_id}/${item.id}`">
        {{ getPatientLink(item) }}
      </router-link>
    </template>

    <!-- edit button -->
    <template v-slot:item.remove="{ item }">
      <v-btn icon @click="removePatient(item.id)">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'StudyPatientsTable',
  props: {
    patients: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Site', value: 'site' },
        { text: 'MRN', value: 'mrn' },
        { text: 'Link', value: 'patient_link' },
        { text: 'Comments', value: 'comment' },
        { text: 'Remove', value: 'remove' }
      ],
      appUrl: process.env.VUE_APP_URL
    };
  },
  methods: {
    getPatientLink(patient) {
      return `${this.appUrl}/patient/${patient.site_id}/${patient.id}`;
    },
    async removePatient(patientId) {
      this.$emit('remove-patient', patientId)
    }
  }
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
