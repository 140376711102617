<template>
  <v-container>
    <v-form ref="demographicForm" lazy-validation>
      <v-layout wrap row>
        <v-flex xs12 md3>
          <v-text-field
            type="number"
            :label="patient.yob ? 'Year of Birth' : 'Year of Birth (Required)'"
            hint="YYYY"
            :rules="yobRules"
            persistent-hint
            required
            v-model="patient.yob"
          />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <div>
            <span v-if="patient.sex">Sex:</span>
            <span v-else class="red--text">Sex (Required for Spirometry)</span>
          </div>
          <v-radio-group v-model="patient.sex" row>
            <v-radio label="Male" value="Male" />
            <v-radio label="Female" value="Female" />
            <v-radio label="Other" value="Other" />
          </v-radio-group>
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-select :items="raceOptions" label="Race" v-model="patient.race" />
        </v-flex>
      </v-layout>

      <v-layout wrap row>
        <v-flex xs12 md3>
          <v-text-field label="Occupation" v-model="patient.occupation" />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-select
            :items="insuranceOptions"
            label="Insurance"
            v-model="patient.insurance"
          />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-text-field
            type="number"
            :label="
              patient.height ? 'Height (cm)' : 'Height (cm) (Required for Spirometry)'
            "
            v-model="patient.height"
            @input="calculateBmi"
            :rules="heightRules"
            required
            hint="cm"
            persistent-hint
          />
        </v-flex>
      </v-layout>

      <!-- calculator row -->
      <v-layout wrap row>
        <v-flex xs12 md3>
          <v-text-field
            type="number"
            label="Weight (kg)"
            v-model="patient.weight"
            @input="calculateBmi"
            :rules="weightRules"
            hint="kg"
            persistent-hint
          />
        </v-flex>

        <v-flex xs12 md3 offset-md1>
          <v-text-field
            type="number"
            label="Body Mass Index"
            v-model="patient.bmi"
            :rules="bmiRules"
          />
        </v-flex>

        <!-- GCS field and calculator -->
        <v-flex xs12 md3 offset-md1>
          <v-dialog v-model="gcsDialog" width="400">
            <template v-slot:activator="on">
              <v-text-field
                label="Glascow Coma Scale"
                prepend-icon="mdi-calculator"
                @click:prepend="openGcsDialog"
                type="number"
                :rules="gcsRules"
                v-model="patient.admit_gcs"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2" primary-title
                >Glascow Coma Scale</v-card-title
              >
              <!-- GCS form -->
              <v-card-text>
                <v-select
                  :items="eyeResponseOptions"
                  label="Eye Opening Response"
                  v-model="eyeResponse"
                  item-text="text"
                />
                <v-select
                  :items="verbalResponseOptions"
                  label="Best Verbal Response"
                  v-model="verbalResponse"
                  item-text="text"
                />
                <v-select
                  :items="motorResponseOptions"
                  label="Best Motor Response"
                  v-model="motorResponse"
                  item-text="text"
                />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="gcsDialog = false">Cancel</v-btn>
                <v-btn
                  v-if="eyeResponse && verbalResponse && motorResponse"
                  color="primary"
                  @click="calculateGcs"
                  >Calculate</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox
            v-model="patient.cur_tobacco"
            label="Current tobacco use"
          />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.past_tobacco" label="Past tobacco use" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.asthma" label="Asthma" />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox v-model="patient.copd" label="COPD" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.pneumonia" label="Pneumonia on Admit" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.diabetes" label="Diabetes Mellitus" />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox v-model="patient.hypertension" label="Hypertension" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox v-model="patient.steroids" label="Steroids" />
        </v-flex>
        <v-flex xs12 md3 offset-md1>
          <v-checkbox
            v-model="patient.history_cancer"
            label="History of Cancer"
          />
        </v-flex>
      </v-layout>

      <!-- checkbox row -->
      <v-layout wrap row class="checkbox-row">
        <v-flex xs12 md3>
          <v-checkbox v-model="patient.osteoporosis" label="Osteoporosis" />
        </v-flex>
      </v-layout>

      <Anticoagulation
        :siteId="siteId"
        :data="patient"
        :coags="coags"
        :platelets="platelets"
        @setCoags="setCoags"
        @setPlatelets="setPlatelets"
      />

      <!-- button row -->
      <v-layout row class="action-row">
        <v-btn @click="handleBack">
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>
        <v-btn to="/patients" class="spaced-button">Cancel</v-btn>
        <v-spacer />
        <v-btn
          :loading="saving"
          @click.prevent="handleSave"
          class="spaced-button"
          :disabled="!valid"
        >
          Save & Close
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!valid"
          :loading="saving"
          @click.prevent="handleNext"
        >
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { round } from 'lodash';
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import Anticoagulation from '@/components/Anticoagulation.vue';

export default {
  name: 'DemographicForm',
  components: { Anticoagulation },
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: false },
    patient: { type: Object, required: true },
    saving: Boolean,
  },
  data() {
    return {
      raceOptions: [
        'White',
        'Black',
        'Asian',
        'Pacific Islander',
        'Hispanic/Latino',
        'Native American',
        'Other',
      ],
      insuranceOptions: ['Commercial', 'Government', 'Uninsured'],
      yobRules: [
        (value) => !!value || 'Required',
        (y) => moment(y, 'YYYY', true).isValid() || 'YYYY', // Valid year string
        (y) => moment(y) > moment(1900, 'YYYY'), // Can't be impossibly old.
        (y) => moment(y) < moment() // Can't be born in the future.
      ],
      heightRules: [
        h => h >= 50 || 'Must be in range 50 to 300 cm',
        h => h <= 300 || 'Must be in range 50 to 300 cm',
      ],
      weightRules: [
        w => w >= 25 || 'Must be in range 25 to 300 kg',
        w => w <= 300 || 'Must be in range 25 to 300 kg',
      ],
      bmiRules: [
        b => b >= 10 || 'Must be in range 10 to 100',
        b => b <= 100 || 'Must be in range 10 to 100',
      ],
      // coma scale section
      gcsDialog: false,
      gcsRules: [
        g => g >= 3 || 'Must be in range 3 to 15',
        g => g <= 15 || 'Must be in range 3 to 15',
      ],
      eyeResponse: null,
      verbalResponse: null,
      motorResponse: null,
      eyeResponseOptions: [
        { value: 1, text: 'No response' },
        { value: 2, text: 'To pain' },
        { value: 3, text: 'To speech' },
        { value: 4, text: 'Spontaneously' },
      ],
      verbalResponseOptions: [
        { value: 1, text: 'No response' },
        { value: 2, text: 'Incomprehensive sounds' },
        { value: 3, text: 'Inappropriate words' },
        { value: 4, text: 'Confused' },
        { value: 5, text: 'Oriented to time, place, and person' },
      ],
      motorResponseOptions: [
        { value: 1, text: 'No response' },
        { value: 2, text: 'Abnormal extension (decerebrate)' },
        { value: 3, text: 'Abnormal flexion (decorticate)' },
        { value: 4, text: 'Flexion withdrawal with pain' },
        { value: 5, text: 'Moves to localised pain' },
        { value: 6, text: 'Obeys commands' },
      ],
      coags: [],
      platelets: [],
    };
  },
  computed: {
    valid() {
      return Boolean(this.patient.yob);
    },
  },
  created() {
    if (this.patientId) {
      this.getCoags();
      this.getPlatelets();
    }
  },
  methods: {
    calculateBmi() {
      if (this.patient.height && this.patient.weight) {
        // BMI = kg / m^2.
        this.patient.bmi = round(
          // Convert the height from cm to m.
          this.patient.weight / Math.pow(this.patient.height / 100.0, 2),
          1
        );
      }
    },
    openGcsDialog() {
      this.gcsDialog = true;
    },
    calculateGcs() {
      this.gcsDialog = false;
      this.patient.admit_gcs =
        this.eyeResponse + this.verbalResponse + this.motorResponse;
    },

    // ----------- coagulation section -----------
    async getCoags() {
      const result = await api.post('/coagulation/getCoags', {
        siteId: this.siteId,
        patientId: this.patientId,
      });
      this.coags = result.data;
    },
    setCoags(newCoags) {
      this.coags = newCoags;
    },
    async saveCoags() {
      const cleaned = cleanNamedItems(this.coags);
      const result = await api.post('/coagulation/saveCoags', {
        siteId: this.siteId,
        patientId: this.patientId,
        coags: cleaned,
      });
      this.coags = result.data;
    },

    // ----------- platelet section -----------
    async getPlatelets() {
      const result = await api.post('/coagulation/getPlatelets', {
        siteId: this.siteId,
        patientId: this.patientId,
      });
      this.platelets = result.data;
    },
    setPlatelets(newPlatelets) {
      this.platelets = newPlatelets;
    },
    async savePlatelets() {
      const cleaned = cleanNamedItems(this.platelets);
      const result = await api.post('/coagulation/savePlatelets', {
        siteId: this.siteId,
        patientId: this.patientId,
        platelets: cleaned,
      });
      this.platelets = result.data;
    },

    // ----------- save section -----------
    saveFormData() {
      this.saveCoags();
      this.savePlatelets();
    },
    handleBack() {
      this.saveFormData();
      this.$emit('back');
    },
    handleNext() {
      this.saveFormData();
      this.$emit('next');
    },
    handleSave() {
      this.saveFormData();
      this.$emit('saveAndClose');
    },
  },
};
</script>
