<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10>
        <v-breadcrumbs :items="breadcrumbItems" />

        <v-card class="elevation-6">
          <v-container>
            <v-card-text>
              <div class="headline">{{ title }}</div>
              <div class="subheading page-title">{{ subtitle }}</div>
              <IpPainMedsForm
                :siteId="siteId"
                :dayId="dayId"
                :meds="meds"
                @setLocals="setLocals"
                :saving="saving"
                @save="save"
              />
            </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import IpPainMedsForm from '@/components/IpPainMedsForm.vue';

export default {
  name: 'IpPainMeds',
  props: {
    siteId: { type: Number, required: true },
    dayId: { type: Number, required: false }
  },
  components: {
    IpPainMedsForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Patients',
          disabled: false,
          to: '/patients'
        }
      ],
      meds: {
        locals: []
      },
      loading: false,
      saving: false
    };
  },
  computed: {
    title() {
      let title = 'Pain Medications';
      if (this.meds.mrn) {
        title += ` for ${this.meds.mrn}`;
      }
      return title;
    },
    subtitle() {
      if (this.meds.hospital_date) {
        const date = moment(this.meds.hospital_date).format('dddd M/D/YYYY');
        return `${date}, Hospital Day ${this.meds.hospital_day}`;
      }
      return '';
    }
  },
  methods: {
    async getIpMeds() {
      this.loading = true;
      const result = await api.post('/inpatients/getIpPainMeds', {
        siteId: this.siteId,
        dayId: this.dayId
      });
      this.meds = result.data;
      this.loading = false;

      let title = 'Pain Meds';
      let backPath = '/patients';
      // Add the relevant breadcrumbs.
      if (this.meds) {
        this.breadcrumbItems = [
          ...this.breadcrumbItems,
          {
            text: `Patient ${this.meds.mrn}`,
            disabled: false,
            to: `/patient/${this.siteId}/${this.meds.patient_id}`
          },
          {
            text: 'Inpatient Days',
            disabled: false,
            to: `/ipDays/${this.siteId}/${this.meds.patient_id}`
          },
          {
            text: `Day ${this.meds.hospital_day} Pain Meds`,
            disabled: true
          }
        ];

        title = `${this.meds.mrn} Day ${this.meds.hospital_day}, Pain Meds`;
        backPath = `/ipDays/${this.siteId}/${this.meds.patient_id}`;
      }

      // Set the title in the navbar.
      this.$store.dispatch('setNavData', { title, backPath });
    },
    setLocals(newLocals) {
      this.meds.locals = newLocals;
    },
    async save() {
      this.meds.locals = cleanNamedItems(this.meds.locals);
      this.saving = true;
      await api.post('/inpatients/saveIpPainMeds', {
        siteId: this.siteId,
        meds: this.meds
      });
      this.saving = false;
      this.$snotify.success('Saved the Meds', 'Saved');
      // Go back to the inpatient page.
      this.$router.push(`/ipDays/${this.siteId}/${this.meds.patient_id}`);
    }
  },
  async created() {
    await this.getIpMeds();
  }
};
</script>

<style scoped>
.page-title {
  margin-bottom: 30px;
}
</style>
