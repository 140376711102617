<template>
  <div>
    <h3>Plates</h3>
    <v-row>
      <v-select
        :items="plateTypeOptions"
        :value="plateType"
        :label="!plateType ? 'Plate Type (Required)' : 'Plate Type'"
        class="plate-select"
        @input="setPlateType"
      />
    </v-row>

    <v-row>
      <v-flex>
        <table>
          <thead>
            <tr>
              <th></th>
              <th colspan="5" class="side-header">Right</th>
              <th class="text-xs-center center-cell">Sternum</th>
              <th colspan="5" class="side-header">Left</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rib</td>
              <td class="text-xs-center">P</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">A</td>
              <td></td>
              <td class="text-xs-center">A</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">P</td>
              <td>Rib</td>
            </tr>

            <tr v-for="rib of ribs" v-bind:key="rib">
              <!-- right side -->
              <td class="shrink text-xs-right">{{ rib }}</td>
              <template v-for="location in rightLocations">
                <td :key="`${rib}-right-${location}`" class="text-xs-center">
                  <v-checkbox
                    class="shrink"
                    :input-value="hasPlate(rib, 'right', location)"
                    @click.native="
                      changePlate({ rib, side: 'right', location })
                    "
                  />
                </td>
              </template>

              <!-- sternum -->
              <!-- Show the sternum box only for the first three rows. -->
              <td
                v-if="rib < 4"
                :key="`${rib}-sternum`"
                class="text-xs-center center-cell"
              >
                <v-checkbox
                  class="shrink"
                  :input-value="hasPlate(rib, 'sternum', sternumLocation(rib))"
                  @click.native="
                    changePlate({
                      rib,
                      side: 'sternum',
                      location: sternumLocation(rib)
                    })
                  "
                />
              </td>
              <!-- empty cell for the other rib rows-->
              <td class="center-cell" v-else></td>

              <!-- left side -->
              <template v-for="location in leftLocations">
                <td :key="`${rib}-left-${location}`" class="text-xs-center">
                  <v-checkbox
                    class="shrink"
                    :input-value="hasPlate(rib, 'left', location)"
                    @click.native="changePlate({ rib, side: 'left', location })"
                  />
                </td>
              </template>
              <td class="shrink">{{ rib }}</td>
            </tr>
          </tbody>
        </table>
      </v-flex>

      <v-flex>
        <v-layout row>
          <v-flex>
            <RibLocationLegend />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import RibLocationLegend from '@/components/RibLocationLegend.vue';

export default {
  name: 'PlateGrid',
  components: {
    RibLocationLegend
  },
  props: {
    plateType: { type: String, required: false },
    plates: { type: Array, required: true }
  },
  data() {
    const rightLocations = [
      'posterior',
      'posterior-lateral',
      'lateral',
      'antero-lateral',
      'anterior'
    ];
    const leftLocations = rightLocations.slice().reverse();

    return {
      ribs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      sides: ['right', 'sternum', 'left'],
      rightLocations,
      leftLocations,
      plateTypeOptions: [
        'Acrylic',
        'Acute',
        'Bioabsorbable',
        'KLS',
        'Sig Medical',
        'Straccos',
        'Stryker',
        'Synthes MatrixRIB',
        'Zimmer Biomet RIBFIX Blu',
        'Other'
      ]
    };
  },
  methods: {
    sternumLocation(rib) {
      if (rib === 1) {
        return 'manubrium';
      }
      if (rib === 2) {
        return 'body';
      }
      if (rib === 3) {
        return 'zyphoid';
      }
    },
    hasPlate(rib, side, location) {
      return this.plates.some(
        p => p.rib === rib && p.side === side && p.location === location
      );
    },
    changePlate(plate) {
      const event = this.hasPlate(plate.rib, plate.side, plate.location)
        ? 'removePlate'
        : 'addPlate';
      // If we're adding a plate, include the plate type.
      if (event === 'addPlate' && !plate.type) {
        plate.type = this.plateType;
      }

      this.$emit(event, plate);
    },
    setPlateType(type) {
      this.$emit('setPlateType', type);
    }
  }
};
</script>

<style scoped>
.side-header {
  border-bottom: 2px solid #4186d8;
}
.shrink {
  margin: 0px;
  padding: 0px;
  height: 20px;
}
.center-cell {
  display: flex;
  justify-content: center;
}
.plate-select {
  max-width: 200px;
}
</style>
