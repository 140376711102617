var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-layout',{staticClass:"checkbox-row",attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-checkbox',{attrs:{"label":"Mech. Ventilation"},model:{value:(_vm.outcomes.mech_vent),callback:function ($$v) {_vm.$set(_vm.outcomes, "mech_vent", $$v)},expression:"outcomes.mech_vent"}})],1),_c('v-flex',{attrs:{"xs12":"","md3":"","offset-md1":""}},[_c('v-checkbox',{attrs:{"label":"Tracheostomy"},model:{value:(_vm.outcomes.tracheostomy),callback:function ($$v) {_vm.$set(_vm.outcomes, "tracheostomy", $$v)},expression:"outcomes.tracheostomy"}})],1),_c('v-flex',{attrs:{"xs12":"","md3":"","offset-md1":""}},[_c('v-checkbox',{attrs:{"label":"ICU"},model:{value:(_vm.outcomes.icu),callback:function ($$v) {_vm.$set(_vm.outcomes, "icu", $$v)},expression:"outcomes.icu"}})],1)],1),_c('v-layout',{staticClass:"checkbox-row",attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-checkbox',{attrs:{"label":"Death"},model:{value:(_vm.outcomes.death),callback:function ($$v) {_vm.$set(_vm.outcomes, "death", $$v)},expression:"outcomes.death"}})],1)],1),_c('v-layout',{staticClass:"top-space",attrs:{"row":""}},[_c('v-combobox',{attrs:{"label":"Other Surgeries","chips":"","clearable":"","multiple":"","hint":"You can add new surgery types.","persistent-hint":"","items":_vm.procOptions,"item-value":"id","item-text":"name","value":_vm.outcomes.procedures},on:{"input":_vm.handleProcsChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeProcedure(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name || item)+" ")])]}}])})],1),_c('v-layout',{attrs:{"row":""}},[_c('v-combobox',{attrs:{"label":"Adverse Events","chips":"","clearable":"","multiple":"","hint":"You can add new event types.","persistent-hint":"","items":_vm.eventOptions,"item-value":"id","item-text":"name","value":_vm.outcomes.events},on:{"input":_vm.handleEventsChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeEvent(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name || item)+" ")])]}}])})],1),_c('v-layout',{staticClass:"action-row",attrs:{"row":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"spaced-button",attrs:{"to":("/ipDays/" + (this.siteId) + "/" + (_vm.outcomes.patient_id))}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.$emit('save')}}},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }