<template>
  <div id="component-container">
    <div id="title-container">
      <b>Fracture Occurrence</b>
    </div>
    <v-row>
      <v-flex id="heatmap-container">
        <table>
          <thead>
            <!-- side labels -->
            <tr>
              <th></th>
              <th colspan="5" class="side-header">Right</th>
              <th class="text-xs-center center-cell">St.</th>
              <th colspan="5" class="side-header">Left</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- column labels -->
            <tr>
              <td>Rib</td>
              <td class="text-xs-center">P</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">A</td>
              <td></td>
              <td class="text-xs-center">A</td>
              <td class="text-xs-center">AL</td>
              <td class="text-xs-center">L</td>
              <td class="text-xs-center">PL</td>
              <td class="text-xs-center">P</td>
              <td>Rib</td>
            </tr>

            <tr v-for="rib of ribs" v-bind:key="rib">
              <!-- right side -->
              <td class="shrink text-xs-right">{{ rib }}</td>
              <template v-for="location in rightLocations">
                <td
                  :key="`${rib}-right-${location}`"
                  class="text-xs-center count-cell"
                  v-bind:style="{
                    backgroundColor: getCellColor(
                      fxCounts[rib]['right'][location]
                    )
                  }"
                >
                  {{ fxCounts[rib]['right'][location] }}
                </td>
              </template>

              <!-- sternum -->
              <!-- Show the sternum box only for the first three rows. -->
              <td
                v-if="rib < 4"
                :key="`${rib}-sternum`"
                class="text-xs-center center-cell count-cell"
                v-bind:style="{
                  backgroundColor: getCellColor(fxCounts[rib]['sternum'])
                }"
              >
                {{ fxCounts[rib]['sternum'] }}
              </td>
              <!-- empty cell for the other rib rows-->
              <td class="center-cell" v-else></td>

              <!-- left side -->
              <template v-for="location in leftLocations">
                <td
                  :key="`${rib}-left-${location}`"
                  class="text-xs-center count-cell"
                  v-bind:style="{
                    backgroundColor: getCellColor(
                      fxCounts[rib]['left'][location]
                    )
                  }"
                >
                  {{ fxCounts[rib]['left'][location] }}
                </td>
              </template>
              <td class="shrink">{{ rib }}</td>
            </tr>
          </tbody>
        </table>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import { max, min } from 'lodash';

export default {
  name: 'FxHeatmap',
  props: {
    rawData: { type: Array, required: true }
  },
  data() {
    const rightLocations = [
      'posterior',
      'posterior-lateral',
      'lateral',
      'antero-lateral',
      'anterior'
    ];
    const leftLocations = rightLocations.slice().reverse();
    const ribs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const sides = ['right', 'sternum', 'left'];

    return {
      ribs,
      sides,
      rightLocations,
      leftLocations
    };
  },
  methods: {
    initCounts: function() {
      // Initialize the coutns to 0.
      const counts = {};
      for (const r of this.ribs) {
        counts[r] = {};

        // right
        counts[r]['right'] = {};
        for (const l of this.rightLocations) {
          counts[r]['right'][l] = 0;
        }

        // sternum
        if (r < 4) {
          counts[r]['sternum'] = 0;
        }

        // left
        counts[r]['left'] = {};
        for (const l of this.leftLocations) {
          counts[r]['left'][l] = 0;
        }
      }

      return counts;
    },
    getCellColor: function(val) {
      const startColor = [23, 118, 210];
      const endColor = [210, 23, 25];
      const defaultColor = 'lavender';

      if (!this.valRange) {
        return defaultColor;
      }

      const [minVal, maxVal] = this.valRange;
      if (minVal == maxVal) {
        return defaultColor;
      }

      // percent from min to max value.
      const p = (val - minVal) / (maxVal - minVal);
      // RGB color value at the given percent.
      const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * p);
      const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * p);
      const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * p);

      return `rgb(${r}, ${g}, ${b})`;
    }
  },
  computed: {
    fxCounts: function() {
      const counts = this.initCounts();

      // Copy the counts from the raw data.
      this.rawData.forEach(c => {
        if (c.side === 'sternum') {
          counts[c.rib][c.side] = c.num_fx;
        } else {
          counts[c.rib][c.side][c.location] = c.num_fx;
        }
      });

      return counts;
    },
    valRange: function() {
      // Get the value range.
      let values = [];
      for (const r of this.ribs) {
        const rVals = Object.values(this.fxCounts[r]['right']);
        values = values.concat(rVals);
        if (r < 4) {
          values.push(this.fxCounts[r]['sternum']);
        }
        const lVals = Object.values(this.fxCounts[r]['left']);
        values = values.concat(lVals);
      }

      return [min(values), max(values)];
    }
  }
};
</script>

<style scoped>
#component-container {
  height: 400px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
#title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
#heatmap-container {
  padding-top: 15px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.side-header {
  border-bottom: 2px solid #4186d8;
}
.count-cell {
  width: 30px;
  text-align: center;
  font-size: 0.7em;
  color: white;
}
</style>
