import { uniq } from 'lodash';
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    rawData: { type: Array },
    sectionField: { type: String, required: true },
    countField: { type: String, required: true },
    title: { type: String, required: true },
    yLabel: { type: String, required: true }
  },
  data: () => ({}),
  methods: {
    makeChartData(rawData, sectionField, countField) {
      // a color palette to choose colors from
      const colors = [
        '#5FA0E0',
        '#DFA5FF',
        '#66BAA8',
        '#4D8075',
        '#19859C',
        '#00C5FA',
        '#7A689A',
        '#CD00A3',
        '#FF006E',
        '#FF6C45',
        '#FFB93D',
        '#F9F871',
        '#F9EAFF',
        '#BEA6A1',
        '#0074BB',
        '#005938',
        '#009166',
        '#7E4F00',
        '#B84F00'
      ];

      // Use the sites as the x-axis bar labels.
      const sites = uniq(rawData.map(d => d.site));
      sites.sort();

      // Use the mechanisms for the bar section colors.
      const sections = uniq(rawData.map(d => d[sectionField]));
      sections.sort();

      const datasets = sections.map((sec, index) => {
        const data = [];
        sites.forEach(site => {
          const row = rawData.find(
            d => d[sectionField] === sec && d.site === site
          );
          const val = row ? row[countField] : 0;
          data.push(val);
        });

        const backgroundColor = colors[index % colors.length];

        return { label: sec, backgroundColor, data };
      });

      return { labels: sites, datasets };
    },
    makeOptions(title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.yLabel
              },
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        },
        title: {
          display: true,
          text: title
        }
      };
    }
  },

  mounted() {
    const chartdata = this.makeChartData(
      this.rawData,
      this.sectionField,
      this.countField
    );
    const options = this.makeOptions(this.title);
    this.renderChart(chartdata, options);
  }
};
