<template>
  <v-data-table :headers="headers" :items="visits" :items-per-page="10">
    <!-- visit date -->
    <template v-slot:item.visit_date="{ item }">
      <router-link :to="`/opVisit/${siteId}/${patientId}/${item.id}`">
        {{ usaDate(item.visit_date) }}
      </router-link>
    </template>

    <!-- vitals -->
    <template v-slot:item.has_vitals="{ item }">
      <v-btn
        :color="item.has_vitals ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/opVitals/${siteId}/${item.id}`"
      >
        {{ item.has_vitals ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- pain meds -->
    <template v-slot:item.has_pain_meds="{ item }">
      <v-btn
        :color="item.has_pain_meds ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/opPainMeds/${siteId}/${item.id}`"
      >
        {{ item.has_pain_meds ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- PFTs -->
    <template v-slot:item.has_pfts="{ item }">
      <v-btn
        :color="item.has_pfts ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/opPfts/${siteId}/${item.id}`"
      >
        {{ item.has_pfts ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- QoL -->
    <template v-slot:item.has_qol="{ item }">
      <v-btn
        :color="item.has_qol ? 'default' : 'primary'"
        small
        class="table-button"
        :to="`/opQol/${siteId}/${item.id}`"
      >
        {{ item.has_qol ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- comment -->
    <template v-slot:item.comment="{ item }">
      <td @click="$router.push(`/opVisit/${siteId}/${patientId}/${item.id}`)">
        {{ item.comment }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OpVisitsTable',
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: true },
    visits: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Visit Date', value: 'visit_date' },
        { text: 'Post-Injury Day', value: 'day_num' },
        { text: 'Vitals', value: 'has_vitals' },
        { text: 'Pain Medications', value: 'has_pain_meds' },
        { text: 'PFTs', value: 'has_pfts' },
        { text: 'Quality of Life', value: 'has_qol' },
        { text: 'Comments', value: 'comment' }
      ]
    };
  },
  methods: {
    usaDate(d) {
      return moment(d).format('M/D/YYYY');
    }
  }
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
