<template>
  <v-card class="mx-auto overflow-hidden">
    <v-app-bar app color="primary" dark>
      <!-- If we have a back URL, then show the left arrow. -->
      <v-btn icon v-if="backPath" @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <!-- Otherwise, show the nav icon bars. -->
      <v-app-bar-nav-icon v-else @click.stop="openNavDrawer" />

      <v-toolbar-title class="headline">{{ title }}</v-toolbar-title>
      <v-spacer />

      <a href="https://cwisociety.org/" target="_blank">
        <v-avatar>
          <img src="/img/cwis_logo_recolored.png" alt="CIWS" />
        </v-avatar>
      </a>
      <v-toolbar-title class="ciid-logo">CIID</v-toolbar-title>
      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on" color="info">{{ initials }}</v-avatar>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-card>
</template>

<script>
export default {
  name: 'AppBarr',
  props: ['initials'],
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    backPath() {
      return this.$store.state.navData.backPath;
    },
    title() {
      return this.$store.state.navData.title;
    }
  },
  methods: {
    async logout() {
      this.$emit('logout');
    },
    goBack() {
      if (this.backPath) {
        this.$router.push(this.backPath);
      }
    },
    openNavDrawer() {
      this.$emit('openNavDrawer');
    }
  }
};
</script>

<style scoped>
.ciid-logo {
  margin-right: 20px;
}
</style>
