<template>
  <v-data-table :headers="headers" :items="patients" :items-per-page="10">
    <!-- patient ID -->
    <template v-slot:item.mrn="{ item }">
      <router-link :to="`/patient/${siteId}/${item.id}`">
        {{ item.mrn }}
      </router-link>
    </template>

    <!-- demographics summary -->
    <template v-slot:item.demoSummary="{ item }">
      <router-link :to="`/patient/${siteId}/${item.id}/2`" v-if="item.yob">
        {{ cleanDemo(item) }}
      </router-link>
      <v-btn
        v-else
        :to="`/patient/${siteId}/${item.id}/2`"
        small
        color="primary"
        class="table-button"
      >
        Add
      </v-btn>
    </template>

    <!-- injuries summary -->
    <template v-slot:item.injuriesSummary="{ item }">
      <router-link
        :to="`/patient/${siteId}/${item.id}/3`"
        v-if="item.injury_date"
      >
        {{ item.num_fx }} fx
      </router-link>
      <v-btn
        v-else
        :to="`/patient/${siteId}/${item.id}/3`"
        small
        color="primary"
        class="table-button"
      >
        Add
      </v-btn>
    </template>

    <!-- admit summary -->
    <template v-slot:item.admitSummary="{ item }">
      <router-link
        :to="`/patient/${siteId}/${item.id}/4`"
        v-if="item.admit_date"
      >
        {{ cleanDate(item.admit_date) }}
      </router-link>
      <v-btn
        v-else
        :to="`/patient/${siteId}/${item.id}/4`"
        small
        color="primary"
        class="table-button"
        >Add</v-btn
      >
    </template>

    <!-- operation summary -->
    <template v-slot:item.operationSummary="{ item }">
      <router-link
        :to="`/patient/${siteId}/${item.id}/5`"
        v-if="item.num_plates"
      >
        {{ item.num_plates }}
        plate<span v-if="item.num_plates !== 1">s</span>
      </router-link>
      <v-btn
        v-else
        :to="`/patient/${siteId}/${item.id}/5`"
        small
        :color="item.had_operation ? 'default' : 'primary'"
        class="table-button"
      >
        {{ item.had_operation ? 'Edit' : 'Add' }}
      </v-btn>
    </template>

    <!-- inpatient summary -->
    <template v-slot:item.inpatientSummary="{ item }">
      <router-link :to="`/ipDays/${siteId}/${item.id}`" v-if="item.ip_days">
        {{ item.ip_days }}
        day<span v-if="item.ip_days !== 1">s</span>
      </router-link>
      <v-btn
        v-else
        small
        class="table-button"
        color="primary"
        :to="`/ipDays/${siteId}/${item.id}`"
      >
        Add
      </v-btn>
    </template>

    <!-- outpatient summary -->
    <template v-slot:item.outpatientSummary="{ item }">
      <router-link :to="`/opVisits/${siteId}/${item.id}`" v-if="item.op_visits">
        {{ item.op_visits }}
        visit<span v-if="item.op_visits !== 1">s</span>
      </router-link>
      <v-btn
        v-else
        small
        class="table-button"
        color="primary"
        :to="`/opVisits/${siteId}/${item.id}`"
      >
        Add
      </v-btn>
    </template>

    <!-- comment -->
    <template v-slot:item.comment="{ item }">
      <td @click="$router.push(`/patient/${siteId}/${item.id}`)">
        {{ item.comment }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PatientsTable',
  props: {
    siteId: { type: Number, required: true },
    patients: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Patient ID', value: 'mrn' },
        { text: 'Demographics', value: 'demoSummary' },
        { text: 'Injuries', value: 'injuriesSummary' },
        { text: 'Admitted', value: 'admitSummary' },
        { text: 'Operation', value: 'operationSummary' },
        { text: 'Inpatient', value: 'inpatientSummary' },
        { text: 'Outpatient', value: 'outpatientSummary' },
        { text: 'Comments', value: 'comment' }
      ]
    };
  },
  methods: {
    cleanDate(dateStr) {
      return moment(dateStr).format('MM/DD/YYYY');
    },
    cleanDemo(patient) {
      // Get the age at the time of admission.
      let toDate = moment();
      if (patient.admit_date) {
        toDate = moment(patient.admit_date);
      }
      const years = toDate.year() - patient.yob;
      let age = `${years} yr`;
      // Build the demographics summary string.
      if (patient.sex) {
        return `${age} ${patient.sex[0]}`;
      }
      return age;
    }
  }
};
</script>

<style scoped>
.table-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
</style>
