<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Set Your Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-lock"
                name="password"
                label="New Password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                :rules="[v => !!v || 'Required']"
                required
                v-on:keydown.enter.prevent="save"
              />
              <v-checkbox v-model="showPassword" label="Show password" />

              <!-- button row -->
              <v-row class="action-row">
                <v-spacer />
                <v-btn
                  color="primary"
                  :loading="loading"
                  :disabled="!trimmed"
                  @click.prevent="save"
                  class="spaced-button"
                  type="submit"
                  v-on:keydown.enter.prevent="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
  name: 'SetPassword',
  data() {
    return {
      loading: false,
      password: '',
      showPassword: true
    };
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    trimmed() {
      return this.password.trim();
    }
  },
  async created() {
    this.$store.dispatch('setNavData', { title: 'Set Password', backPath: '' });
  },
  methods: {
    async save() {
      if (!this.trimmed) {
        return;
      }

      this.loading = true;
      await api.post('/auth/setPassword', { password: this.trimmed });
      this.loading = false;

      this.$snotify.success('Saved your password', 'Saved');
      // Go to the patients list page.
      this.$router.push('/patients');
    }
  }
};
</script>

<style scoped>
#login-alert {
  margin-bottom: 20px;
}
</style>
