<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-card-text>
            <v-file-input label="Click here to select a file" v-model="file" />

            <!-- button row -->
            <v-row class="action-row">
              <v-spacer />

              <v-btn
                v-if="importing"
                class="spaced-button"
                color="primary"
                disabled
              >
                <v-progress-circular indeterminate size="20" class="spinner" />
                Importing...
              </v-btn>
              <span v-else>
                <v-btn to="/">Cancel</v-btn>
                <v-btn
                  class="spaced-button"
                  color="primary"
                  @click.prevent="handleImport"
                >
                  Import
                </v-btn>
              </span>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from '@/api';

export default {
  name: 'ImportPage',
  components: {},
  data() {
    return {
      file: null,
      importing: false
    };
  },
  async created() {
    this.$store.dispatch('setNavData', { title: 'File Import', backPath: '' });
  },
  methods: {
    handleImport() {
      if (!this.file) {
        return;
      }

      this.importing = true;

      // Open the file, and upload its contents.
      const reader = new FileReader();
      reader.onload = async () => {
        // Send file data to API.
        try {
          await api.post('/fileImport/uploadFile', {
            fileName: this.file.name,
            contentType: this.file.type,
            dataUrl: reader.result
          });

          // Clear the file input, for the next file.
          this.file = null;
          // Show confirmation message.
          this.$snotify.success('File uploaded successfully', 'Uploaded');
        } catch (err) {
          console.log('upload file error:', err);
          this.$snotify.error('Error importing file', 'Error');
        } finally {
          this.importing = false;
        }
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = err => {
        console.log('file reading has failed');
        console.log('error:', err);
      };

      reader.readAsDataURL(this.file);
    }
  }
};
</script>

<style scoped>
.spinner {
  margin-right: 8px;
}
</style>
