<template>
  <v-container>
    <v-layout justify-center column>
      <v-flex fluid>
        <v-card class="elevation-6 page-section">
          <v-card-text>
            <StudiesTable :studies="studies" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" to="/editStudy">Add Study</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import StudiesTable from '@/components/studies/StudiesTable';

export default {
  name: 'Studies',
  components: {
    StudiesTable
  },
  data() {
    return {
      studies: []
    };
  },
  async created() {
    if (this.user && this.user.role === 'CIID admin') {
      this.getStudies();
    }
    this.$store.dispatch('setNavData', { title: 'Studies', backPath: '' });
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async getStudies() {
      const result = await api.post('/studies/getStudies');
      this.studies = result.data;
    }
  }
};
</script>

<style scoped>
.left-btn {
  margin-right: 10px;
}
.add-icon {
  margin-right: 7px;
}
.page-section {
  margin-top: 30px;
}
</style>
