var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"top-row",attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-checkbox',{attrs:{"label":"Anticoagulation / Antiplatelet"},model:{value:(_vm.data.anti_coag_platelet),callback:function ($$v) {_vm.$set(_vm.data, "anti_coag_platelet", $$v)},expression:"data.anti_coag_platelet"}})],1),(_vm.data.anti_coag_platelet)?_c('v-flex',{attrs:{"xs12":"","md2":"","offset-md1":""}},[_c('v-checkbox',{attrs:{"label":"α-Coagulation"},model:{value:(_vm.data.alpha_coag),callback:function ($$v) {_vm.$set(_vm.data, "alpha_coag", $$v)},expression:"data.alpha_coag"}})],1):_vm._e(),(_vm.data.alpha_coag)?_c('v-flex',{attrs:{"xs12":"","md5":"","offset-md1":""}},[_c('v-combobox',{attrs:{"label":"Coagulation Options","chips":"","clearable":"","multiple":"","hint":"You can add new options.","persistent-hint":"","items":_vm.coagOptions,"item-value":"id","item-text":"name","value":_vm.coags},on:{"input":_vm.handleCoagChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeCoag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name || item)+" ")])]}}],null,false,1281679202)})],1):_vm._e()],1),(_vm.data.anti_coag_platelet)?_c('v-layout',{staticClass:"top-row",attrs:{"wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":"","md2":"","offset-md4":""}},[_c('v-checkbox',{attrs:{"label":"α-Platelet"},model:{value:(_vm.data.alpha_platelet),callback:function ($$v) {_vm.$set(_vm.data, "alpha_platelet", $$v)},expression:"data.alpha_platelet"}})],1),(_vm.data.alpha_platelet)?_c('v-flex',{attrs:{"xs12":"","md5":"","offset-md1":""}},[_c('v-combobox',{attrs:{"label":"Platelet Options","chips":"","clearable":"","multiple":"","hint":"You can add new options.","persistent-hint":"","items":_vm.plateletOptions,"item-value":"id","item-text":"name","value":_vm.platelets},on:{"input":_vm.handlePlateletChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removePlatelet(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name || item)+" ")])]}}],null,false,3452668569)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }