<template>
  <v-container>
    <v-form ref="operationForm">
      <v-checkbox
        v-model="patient.had_operation"
        label="Patient had rib or sternal fixation"
      />

      <div v-if="patient.had_operation">
        <PlateGrid
          :plateType="plateType"
          :plates="plates"
          @setPlateType="setPlateType"
          @addPlate="addPlate"
          @removePlate="removePlate"
        />

        <v-divider class="divider" />

        <v-layout wrap row class="position-row">
          <v-flex xs12 md3>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateFormatted"
                  :label="patient.case_date ? 'OR Date' : 'OR Date (Required)'"
                  prepend-icon="mdi-calendar"
                  :hint="dateHint"
                  persistent-hint
                  required
                  :rules="dateRules"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="patient.case_date"
                no-title
                @input="dateMenu = false"
              />
            </v-menu>
          </v-flex>

          <v-flex xs12 md3 offset-md1>
            <v-text-field
              v-model="patient.case_start_time"
              label="OR Start Time"
              prepend-icon="mdi-clock"
              :rules="timeRules"
              hint="HH:MM (24h)"
              persistent-hint
            />
          </v-flex>

          <v-flex xs12 md3 offset-md1>
            <v-text-field
              v-model="patient.case_end_time"
              label="OR End Time"
              prepend-icon="mdi-clock"
              :rules="timeRules"
              hint="HH:MM (24h)"
              persistent-hint
            />
          </v-flex>
        </v-layout>

        <!-- checkbox row -->
        <v-layout row wrap class="checkbox-row">
          <v-flex xs12 md3>
            <v-checkbox v-model="patient.bronchoscopy" label="Bronchoscopy" />
          </v-flex>
          <v-flex xs12 md3 offset-md1>
            <v-checkbox v-model="patient.chest_tube" label="Chest tube" />
          </v-flex>
          <v-flex xs12 md3 offset-md1>
            <v-checkbox
              v-model="patient.evac_htx"
              label="Evacuation of hemothorax"
            />
          </v-flex>
        </v-layout>

        <!-- checkbox row -->
        <v-layout row wrap class="checkbox-row">
          <v-flex xs12 md3>
            <v-checkbox
              v-model="patient.subq_drain"
              label="Subcutaneous drain"
            />
          </v-flex>
          <v-flex xs12 md3 offset-md1>
            <v-checkbox
              v-model="patient.lung_isolation"
              label="Lung isolation"
            />
          </v-flex>
          <v-flex xs12 md3 offset-md1>
            <v-checkbox
              v-model="patient.intraop_thoroscopy"
              label="Intra-op thoracoscoopy"
            />
          </v-flex>
        </v-layout>

        <!-- checkbox row -->
        <v-layout row wrap class="checkbox-row">
          <v-flex xs12 md3>
            <v-checkbox
              v-model="patient.irrigation"
              label="Intra-op pleural irrigation"
            />
          </v-flex>
        </v-layout>

        <LocalPainControl
          :siteId="siteId"
          :data="patient"
          :locals="locals"
          @setLocals="setLocals"
        />

        <v-layout row class="position-row">
          <v-flex xs12 md3>
            <v-combobox
              v-model="positions"
              :items="positionOptions"
              item-value="id"
              item-text="name"
              label="Position(s)"
              chips
              clearable
              multiple
              hint="You can add new position types."
              persistent-hint
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="removePosition(item.id)"
                >
                  {{ item.name || item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>

          <v-flex xs12 md8 offset-md1>
            <v-combobox
              v-model="procedures"
              :items="procedureOptions"
              item-value="id"
              item-text="name"
              label="Other Thoracic Repairs"
              chips
              clearable
              multiple
              hint="You can add new surgery types."
              persistent-hint
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="removeProcedure(item.id)"
                >
                  {{ item.name || item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
        </v-layout>

        <v-layout row class="position-row">
          <v-flex xs12 md3>
            <v-select
              label="Primary Surgeon"
              v-model="patient.surgeon_id"
              :items="surgeonOptions"
              item-value="id"
              item-text="last_name"
            />
          </v-flex>
        </v-layout>
      </div>

      <!-- button row -->
      <v-layout row class="action-row">
        <v-btn @click="handleBack">
          <v-icon>mdi-chevron-left</v-icon>Back
        </v-btn>
        <v-btn to="/patients" class="spaced-button">Cancel</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="saving" @click.prevent="handleSave">
          Save
        </v-btn>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { cleanNamedItems } from '../utils/cleanNamedItems';
import PlateGrid from '@/components/PlateGrid.vue';
import LocalPainControl from '@/components/LocalPainControl.vue';

export default {
  name: 'OperationForm',
  components: {
    PlateGrid,
    LocalPainControl
  },
  props: {
    siteId: { type: Number, required: true },
    patientId: { type: Number, required: false },
    patient: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      plates: [],
      plateType: null,
      locals: [],
      positionOptions: [],
      positions: [],
      procedureOptions: [],
      procedures: [],
      surgeonOptions: [],
      dateMenu: false,
      dateRules: [
        (d) => Boolean(d) || 'Required',
        (d) => moment(d, 'M/D/YYYY', true).isValid() || 'M/D/YYYY', // Valid date string
        (d) => moment(d) >= moment(this.patient.injury_date) || 'Must be after injury', 
      ],
      timeRules: [v => !v || moment(v, 'HH:mm', true).isValid() || 'HH:MM']
    };
  },
  computed: {
    dateFormatted() {
      return this.patient.case_date
        ? moment(this.patient.case_date).format('M/D/YYYY')
        : '';
    },
    dateHint() {
      return this.patient.case_date ? 'MM/DD/YYYY' : 'Required';
    }
  },
  created() {
    if (this.patientId) {
      this.getPlates();
      this.getLocals();
      this.getSiteUsers();
      this.getPositions();
      this.getProcedures();
    }
    this.getPositionOptions();
    this.getCaseProcedureOptions();
  },
  methods: {
    // Load the users for my site.
    async getSiteUsers() {
      const result = await api.post('/users/getSiteUsers');
      this.surgeonOptions = result.data;
    },

    // ----------- local pain controls section -----------
    async getLocals() {
      const result = await api.post('/localPainControls/getCaseLocals', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.locals = result.data;
    },
    setLocals(newLocals) {
      this.locals = newLocals;
    },
    async saveLocals() {
      const cleaned = cleanNamedItems(this.locals);
      const result = await api.post('/localPainControls/saveCaseLocals', {
        siteId: this.siteId,
        patientId: this.patientId,
        locals: cleaned
      });
      this.locals = result.data;
    },

    // ----------- plates section -----------
    // Initialize the plate type, using the first plate with a type.
    getPlateType() {
      if (this.plates.length > 0) {
        const plate = this.plates.find(p => p.type);
        if (plate) {
          this.plateType = plate.type;
        }
      }
    },
    // Set the type of all the plates.
    setPlateType(type) {
      this.plateType = type;
      this.plates.forEach(p => {
        if (p.type !== type) {
          p.type = type;
        }
      });
    },
    async getPlates() {
      const result = await api.post('/patients/getPlates', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.plates = result.data;
      this.getPlateType();
    },
    findPlateIndex(plate) {
      if (plate.id) {
        // Search by ID.
        const index = this.plates.findIndex(p => p.id === plate.id);
        if (index > -1) {
          return index;
        }
      }

      // Search by the postition.
      return this.plates.findIndex(
        p =>
          p.rib === plate.rib &&
          p.side === plate.side &&
          p.location === plate.location
      );
    },
    addPlate(plate) {
      // Make sure that the fracture has the patient ID.
      if (!plate.patient_id) {
        plate.patient_id = this.patient.id;
      }
      const index = this.findPlateIndex(plate);
      if (index > -1) {
        this.plates[index] = plate;
      } else {
        this.plates.push(plate);
      }
    },
    removePlate(plate) {
      const index = this.findPlateIndex(plate);
      if (index > -1) {
        this.plates = [
          ...this.plates.slice(0, index),
          ...this.plates.slice(index + 1)
        ];
      }
    },
    async savePlates() {
      const result = await api.post('/patients/savePlates', {
        siteId: this.siteId,
        patientId: this.patientId,
        plates: this.plates
      });
      this.plates = result.data;
    },
    // ----------- position section -----------
    // Get the patient's case positions.
    async getPositions() {
      const result = await api.post('/patients/getPositions', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.positions = result.data;
    },
    async getPositionOptions() {
      const result = await api.post('/patients/getPositionOptions', {
        siteId: this.siteId
      });
      this.positionOptions = result.data;
    },
    removePosition(id) {
      this.positions = this.positions.filter(p => p.id !== id);
    },
    async savePositions() {
      const cleaned = cleanNamedItems(this.positions);
      const result = await api.post('/patients/savePositions', {
        siteId: this.siteId,
        patientId: this.patient.id,
        positions: cleaned
      });
      this.positions = result.data;
    },

    // ----------- procedures section -----------
    // Get the patient's case positions.
    async getProcedures() {
      const result = await api.post('/patients/getCaseProcedures', {
        siteId: this.siteId,
        patientId: this.patientId
      });
      this.procedures = result.data;
    },
    async getCaseProcedureOptions() {
      const result = await api.post('/patients/getCaseProcedureOptions', {
        siteId: this.siteId
      });
      this.procedureOptions = result.data;
    },
    removeProcedure(id) {
      this.procedures = this.procedures.filter(p => p.id !== id);
    },
    async saveProcedures() {
      const cleaned = cleanNamedItems(this.procedures);
      const result = await api.post('/patients/saveCaseProcedures', {
        siteId: this.siteId,
        patientId: this.patient.id,
        procedures: cleaned
      });
      this.procedures = result.data;
    },

    // ----------- save section -----------
    saveFormData() {
      this.savePlates();
      this.saveLocals();
      this.savePositions();
      this.saveProcedures();
    },
    handleBack() {
      this.saveFormData();
      this.$emit('back');
    },
    handleSave() {
      this.saveFormData();
      this.$emit('save');
    }
  }
};
</script>

<style scoped>
.divider {
  margin-top: 20px;
}
.checkbox-row {
  max-height: 30px;
}
.position-row {
  margin-top: 25px;
}
</style>
