import voldyneData from './voldyneSpiroData';
import vyaireData from './vyaireSpiroData';
import interpolate from './bilinearInterpolation';

// Calculate the spirometry value as a percentage of the expected value.
// sex is either "Male" or "Female"
// age is the patient's age in years
// height is in cm
// volume is the measured spriometry value in ml
const calculateSpiroPct = (sex, age, height, model, volume) => {
  if (![sex, age, height, model, volume].every(Boolean)) {
    return null;
  }
  if (!['Male', 'Female'].includes(sex)) {
    console.log('Unknown sex');
    return null;
  }
  if (!['Voldyne 5000', 'Vyaire AirLife 2500'].includes(model)) {
    console.log('Unknown spirometer model');
    return null;
  }

  let data;
  if (model === 'Voldyne 5000') {
    data = voldyneData;
  } else if (model === 'Vyaire AirLife 2500') {
    data = vyaireData;
  }

  // Convert the height from centimeters into inches.
  const heightInches = height * 0.393701;
  // Look up the spirometry data for the patient's dex.
  const { spiro, ages, heights } = data[sex];
  // Calculate the expected value, based on the patient demographics.
  const expected = interpolate(spiro, ages, heights, age, heightInches);
  // Return the measured value's percent of the expected value.
  return Math.round((100.0 * volume) / expected);
};

// function test() {
//   console.log('in test');
//   const pct = calculateSpiroPct('Male', 40, 167, 2000);
//   console.log('got pct:', pct);
// }
// test();

export default calculateSpiroPct;
