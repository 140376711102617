<template>
  <v-form>
    <v-layout>
      <v-flex xs12 md4>
        <v-text-field label="Daily Opiate Equivalents (MME)" prepend-icon="mdi-calculator"
          @click:prepend="openNarcsDialog" type="number" messages="Total dose of past 24 hours"
          v-model="meds.daily_narcs" />
      </v-flex>
    </v-layout>

    <!-- checkbox row -->
    <v-layout wrap row class="checkbox-row">
      <v-flex xs12 md3>
        <v-checkbox v-model="meds.tylenol" label="Tylenol" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="meds.nsaid" label="NSAIDs" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="meds.ketamine" label="Ketamine" />
      </v-flex>
    </v-layout>

    <!-- checkbox row -->
    <v-layout wrap row class="checkbox-row">
      <v-flex xs12 md3>
        <v-checkbox v-model="meds.gabapentin" label="Gabapentin" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-checkbox v-model="meds.lidocaine" label="Lidocaine Infusion" />
      </v-flex>
      <v-flex xs12 md3 offset-md1>
        <v-text-field label="Other" v-model="meds.other" />
      </v-flex>
    </v-layout>

    <LocalPainControl :siteId="siteId" :data="meds" :locals="meds.locals" @setLocals="setLocals" />

    <!-- button row -->
    <v-layout row class="action-row">
      <v-spacer />
      <v-btn :to="`/ipDays/${siteId}/${meds.patient_id}`" class="spaced-button">
        Cancel
      </v-btn>
      <v-btn color="primary" type="submit" :loading="saving" @click.prevent="$emit('save')">
        Save
      </v-btn>
    </v-layout>

    <!-- narcotics pop-up dialog -->
    <v-dialog v-model="narcsDialog" max-width="800" @keydown.esc="closeNarcsDialog">
      <NarcoticsForm :narcs="narcs" :saving="savingNarcs" @cancel="closeNarcsDialog" @save="saveNarcs" />
    </v-dialog>
  </v-form>
</template>

<script>
import api from '@/api.js';
import LocalPainControl from '@/components/LocalPainControl.vue';
import NarcoticsForm from '@/components/NarcoticsForm.vue';

export default {
  name: 'IpPainMedsForm',
  components: { LocalPainControl, NarcoticsForm },
  props: {
    siteId: { type: Number, required: true },
    dayId: { type: Number, required: true },
    meds: { type: Object, required: true },
    saving: Boolean
  },
  data() {
    return {
      gettingNarcs: false,
      savingNarcs: false,
      narcs: [],
      narcsDialog: false
    };
  },
  created() {
    this.getNarcs();
  },
  methods: {
    setLocals(newLocals) {
      this.$emit('setLocals', newLocals);
    },
    openNarcsDialog() {
      this.narcsDialog = true;
    },
    closeNarcsDialog() {
      this.narcsDialog = false;
    },
    // Load the narcotics data from the database.
    async getNarcs() {
      this.gettingNarcs = true;
      const result = await api.post('/inpatients/getIpNarcs', {
        siteId: this.siteId,
        dayId: this.dayId
      });
      this.narcs = result.data;
      this.gettingNarcs = false;
    },
    async saveNarcs(newNarcs, totalDose) {
      this.narcs = newNarcs;
      this.meds.daily_narcs = totalDose;
      this.savingNarcs = true;
      await api.post('/inpatients/saveIpNarcs', {
        siteId: this.siteId,
        dayId: this.dayId,
        narcs: newNarcs,
        totalDose
      });
      this.savingNarcs = false;
      this.narcsDialog = false;
    }
  }
};
</script>
