var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.visits,"items-per-page":10},scopedSlots:_vm._u([{key:"item.visit_date",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/opVisit/" + _vm.siteId + "/" + _vm.patientId + "/" + (item.id))}},[_vm._v(" "+_vm._s(_vm.usaDate(item.visit_date))+" ")])]}},{key:"item.has_vitals",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"table-button",attrs:{"color":item.has_vitals ? 'default' : 'primary',"small":"","to":("/opVitals/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.has_vitals ? 'Edit' : 'Add')+" ")])]}},{key:"item.has_pain_meds",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"table-button",attrs:{"color":item.has_pain_meds ? 'default' : 'primary',"small":"","to":("/opPainMeds/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.has_pain_meds ? 'Edit' : 'Add')+" ")])]}},{key:"item.has_pfts",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"table-button",attrs:{"color":item.has_pfts ? 'default' : 'primary',"small":"","to":("/opPfts/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.has_pfts ? 'Edit' : 'Add')+" ")])]}},{key:"item.has_qol",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"table-button",attrs:{"color":item.has_qol ? 'default' : 'primary',"small":"","to":("/opQol/" + _vm.siteId + "/" + (item.id))}},[_vm._v(" "+_vm._s(item.has_qol ? 'Edit' : 'Add')+" ")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.$router.push(("/opVisit/" + _vm.siteId + "/" + _vm.patientId + "/" + (item.id)))}}},[_vm._v(" "+_vm._s(item.comment)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }