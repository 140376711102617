import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import Snotify from 'vue-snotify';
// style for vue-snotify
import 'vue-snotify/styles/material.css'; // or dark.css or simple.css
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.config.productionTip = false;

Vue.use(Snotify);
Vue.use(VueYouTubeEmbed);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
