<template>
  <div>
    <span class="subheading">
      Location Legend
    </span>
    <table>
      <tbody>
        <tr>
          <td>P:</td>
          <td>Posterior</td>
        </tr>
        <tr>
          <td>PL:</td>
          <td>Posterior-lateral</td>
        </tr>
        <tr>
          <td>L:</td>
          <td>Lateral</td>
        </tr>
        <tr>
          <td>AL:</td>
          <td>Antero-lateral</td>
        </tr>
        <tr>
          <td>A:</td>
          <td>Anterior</td>
        </tr>
      </tbody>
    </table>

    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">
          Show Diagram
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Rib Locations
        </v-card-title>

        <v-card-text>
          <v-img src="/img/location_legend.png" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RibLocationLegend',
  data() {
    return {
      dialog: false
    };
  }
};
</script>
