<template>
  <v-form>
    <v-card>
      <v-container>
        <v-card-title class="headline grey lighten-2" primary-title>
          Injury Severity Score
        </v-card-title>
        <v-card-text>
          <h4 class="section-title">
            Chest injuries for ISS calcaultion
          </h4>

          <v-row>
            <v-col xs12 md5>
              <v-select
                label="Head and neck worst injury"
                v-model="headNeck"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Face worst injury"
                v-model="face"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Chest worst injury"
                v-model="chest"
                :items="options"
                item-value="value"
                item-text="text"
              />
            </v-col>

            <v-col xs12 md5 offset-md1>
              <v-select
                label="Abdomen worst injury"
                v-model="abdomen"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="Extremity (Including pelvis) worst injury"
                v-model="extremity"
                :items="options"
                item-value="value"
                item-text="text"
              />

              <v-select
                label="External worst injuries"
                v-model="external"
                :items="options"
                item-value="value"
                item-text="text"
              />
            </v-col>
          </v-row>

          <v-alert :value="true" text color="primary">
            IS score: {{ iss }}
          </v-alert>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn @click="$emit('cancel')">
            Cancel
          </v-btn>

          <v-btn color="primary" @click.prevent="$emit('save', iss)">
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { sum } from 'lodash';

export default {
  name: 'IssForm',
  props: {
    patientId: { type: Number, required: true }
  },
  data() {
    return {
      options: [
        { value: 0, text: 'No Injury' },
        { value: 1, text: 'Minor' },
        { value: 2, text: 'Moderate' },
        { value: 3, text: 'Serious' },
        { value: 4, text: 'Severe' },
        { value: 5, text: 'Critical' },
        { value: 6, text: 'Unsurvivable' }
      ],
      headNeck: 0,
      face: 0,
      chest: 0,
      abdomen: 0,
      extremity: 0,
      external: 0
    };
  },
  computed: {
    // Calculate the injury severity score.
    iss() {
      // Assemble the values into an array, for easier processing.
      const values = [
        this.headNeck,
        this.face,
        this.chest,
        this.abdomen,
        this.extremity,
        this.external
      ];
      // If any field is set to 'Unsurvivable', then return the max value of 75.
      if (values.some(v => v === 6)) {
        return 75;
      }
      // Return the sum of the squares of the top 3 scores.
      values.sort().reverse();
      return sum(values.slice(0, 3).map(v => v * v));
    }
  }
};
</script>

<style scoped>
.section-title {
  margin-top: 15px;
}
</style>
