<template>
  <v-app>
    <!-- Show the nav bar and drawer only if I'm logged in. -->
    <div v-if="user">
      <AppBar :initials="initials" v-on:openNavDrawer="drawer = true" v-on:logout="logout" />

      <!-- navigation drawer -->
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <!-- my name -->
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar color="primary" dark>
              <v-img src="/img/cwis_logo_recolored.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
              <v-list-item-subtitle>Logged In</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider />

        <!-- list of navigation links -->
        <v-list>
          <!-- link to CIID analytics page -->
          <v-list-item link to="/ciidAnalytics" v-if="showCiidAnalytics">
            <v-list-item-icon>
              <v-icon>mdi-gauge</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>CIID Analytics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- link to site analytics page -->
          <v-list-item link to="/analytics">
            <v-list-item-icon>
              <v-icon>mdi-google-analytics</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Site Analytics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- link to patients page -->
          <v-list-item link to="/patients">
            <v-list-item-icon>
              <v-icon>mdi-medical-bag</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Patients</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- link to sites page, only for CIID admins -->
          <v-list-item link v-if="user.role === 'CIID admin'" to="/sites">
            <v-list-item-icon>
              <v-icon>mdi-hospital-building</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Sites</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- link to users page, for CIID admins and site admins -->
          <v-list-item link v-if="['CIID admin', 'Site admin'].includes(user.role)" to="/users">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- research studies page, for CIID admins -->
          <v-list-item link v-if="user.role === 'CIID admin'" to="/studies">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Studies</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- import file page, only for CIID admins and Phillip -->
          <v-list-item link v-if="showImportPage" to="/import">
            <v-list-item-icon>
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Import</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <!-- help -->
          <v-list-item link to="/help">
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- logout -->
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Show the app version. -->
          <v-list-item v-if="appVersion">
            <span id="app-version"> CIID version {{ appVersion }} </span>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-main id="app-content">
      <router-view />
    </v-main>
    <vue-snotify />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import shouldShowCiidAnalytics from './utils/shouldShowCiidAnalytics';
import AppBar from '@/components/AppBar.vue';

export default {
  name: 'App',
  components: {
    AppBar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      appVersion: (state) => state.appVersion,
    }),
    showCiidAnalytics() {
      return shouldShowCiidAnalytics(this.user);
    },
    // Show import file page only to CIID admins and Phillip, for now.
    showImportPage() {
      return (
        this.user.role === 'CIID admin' ||
        (this.user.first_name === 'Phillip' &&
          this.user.last_name === 'Antippa')
      );
    },
    fullName() {
      const u = this.user;
      if (!u) {
        return '';
      }

      return `${u.first_name} ${u.last_name}`;
    },
    initials() {
      const u = this.user;
      if (!u) {
        return '';
      }
      return `${u.first_name[0]}${u.last_name[0]}`;
    },
  },
  methods: {
    async logout() {
      // Close the drawer, so that it's closed when the next person logs in.
      if (this.drawer) {
        this.drawer = false;
      }

      await this.$store.dispatch('logout');
      // Clear the nav data.
      this.$store.dispatch('setNavData', { title: '', backPath: '' });
      // Go back to the home page.
      this.$router.push('/');
    },
  },
};
</script>

<!-- Global styles -->
<style>
/* Don't capitalize all the buttons */
.v-btn {
  text-transform: none;
}

.action-row {
  margin-top: 30px;
}

.short-date {
  max-width: 150px;
}

.page-title {
  margin-bottom: 30px;
}

.checkbox-row {
  max-height: 40px;
}

#app-content {
  background-color: #efefef;
}

.confirm-p {
  margin-top: 20px;
}

#app-version {
  color: #888888;
  font-size: 0.7em;
}
</style>
