<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12>
        <!-- Stepper for the multi-part patient form -->
        <v-stepper v-model="step" non-linear>
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" editable>
              Patient ID
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 2"
              step="2"
              editable
              v-if="patient.id"
            >
              Demographics
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 3"
              step="3"
              editable
              v-if="patient.id"
            >
              Injuries
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 4"
              step="4"
              editable
              v-if="patient.id"
            >
              Admission
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 5"
              step="5"
              editable
              v-if="patient.id"
            >
              Operation
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <PatientIdForm
                :siteId="siteId"
                :patient="patient"
                :saving="saving"
                @saveAndClose="saveAndClose"
                @next="next"
                @deletePatient="deletePatient"
              />
            </v-stepper-content>

            <v-stepper-content step="2" v-if="Boolean(patient.id)">
              <DemographicForm
                :siteId="siteId"
                :patientId="patient.id"
                :patient="patient"
                :saving="saving"
                @back="back"
                @saveAndClose="saveAndClose"
                @next="next"
              />
            </v-stepper-content>

            <v-stepper-content step="3" v-if="Boolean(patient.id)">
              <InjuriesForm
                :siteId="siteId"
                :patientId="patient.id"
                :patient="patient"
                :saving="saving"
                @back="back"
                @saveAndClose="saveAndClose"
                @next="next"
              />
            </v-stepper-content>

            <v-stepper-content step="4" v-if="Boolean(patient.id)">
              <HospitalCareForm
                :siteId="siteId"
                :patient="patient"
                :saving="saving"
                @back="back"
                @saveAndClose="saveAndClose"
                @next="next"
              />
            </v-stepper-content>

            <v-stepper-content step="5" v-if="Boolean(patient.id)">
              <OperationForm
                :siteId="siteId"
                :patientId="patient.id"
                :patient="patient"
                :saving="saving"
                @back="back"
                @save="saveAndClose"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api.js';
import { mapState } from 'vuex';
import PatientIdForm from '@/components/PatientIdForm.vue';
import DemographicForm from '@/components/DemographicForm.vue';
import InjuriesForm from '@/components/InjuriesForm.vue';
import HospitalCareForm from '@/components/HospitalCareForm.vue';
import OperationForm from '@/components/OperationForm.vue';
import { SnotifyPosition } from 'vue-snotify';

export default {
  name: 'Patient',
  props: {
    siteId: { type: Number, required: true },
    id: { type: Number, required: false },
    startStep: { type: Number, required: false },
  },
  components: {
    PatientIdForm,
    DemographicForm,
    InjuriesForm,
    HospitalCareForm,
    OperationForm,
  },
  data() {
    return {
      step: 1,
      // default blank patient object
      patient: {
        site_id: this.siteId,
        studyIds: [],
      },
      saving: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    title() {
      return this.makePageTitle();
    },
  },
  async created() {
    if (this.id) {
      await this.getPatient(this.siteId, this.id);
    } else {
      // Initialize a blank patient.
      this.patient.entered_by = this.user.id;
    }

    let title = this.makePageTitle();
    this.$store.dispatch('setNavData', { title, backPath: '/patients' });

    if (this.startStep) {
      const intStep = parseInt(this.startStep, 10);
      if (intStep) {
        this.step = intStep;
      }
    }
  },
  methods: {
    async getPatient(siteId, id) {
      const result = await api.post('/patients/getPatient', { siteId, id });
      const patient = result.data;
      if (!patient) {
        return;
      }

      const studies = await this.getPatientStudies(id);
      patient.studyIds = studies.map((s) => s.id);

      // Remove the seconds part of the times.
      if (patient.case_start_time) {
        patient.case_start_time = patient.case_start_time.slice(0, 5);
      }
      if (patient.case_end_time) {
        patient.case_end_time = patient.case_end_time.slice(0, 5);
      }

      this.patient = patient;
    },

    async getPatientStudies(id) {
      const result = await api.post('/studies/getPatientStudies', { id });
      return result.data;
    },
    makePageTitle() {
      if (this.patient.id) {
        return `Patient ${this.patient.mrn}`;
      }
      return 'Add new patient';
    },
    // Format the date as a USA style string M/D/YYYY.
    usaDate(d) {
      if (moment(d).isValid()) {
        return moment(d).format('M/D/YYYY');
      }
      return d;
    },
    // ----- save section -----
    // Save a new patient into the database.
    async addPatient() {
      const result = await api.post('/patients/addPatient', {
        patient: this.patient,
      });
      this.patient = result.data;
    },
    async updatePatient(section) {
      const result = await api.post('/patients/updatePatient', {
        patient: this.patient,
        section,
      });
      this.patient = result.data;
    },
    async save() {
      // Make sure that the step is an integer, not a string.
      if (typeof this.step === 'string') {
        this.step = parseInt(this.step, 10);
      }
      // Save the patient information
      if (this.step === 1) {
        if (this.patient.id) {
          await this.updatePatient('ids');
        } else {
          await this.addPatient();
        }
      } else if (this.step === 2) {
        await this.updatePatient('demographics');
      } else if (this.step === 3) {
        await this.updatePatient('injuries');
      } else if (this.step === 4) {
        await this.updatePatient('hospitalCare');
      } else if (this.step === 5) {
        await this.updatePatient('operation');
      }
    },
    async saveAndClose() {
      await this.save();
      this.$snotify.success('Saved the Patient', 'Saved', {
        position: SnotifyPosition.rightTop,
      });
      // Go back to the patients list page.
      this.$router.push('/patients');
    },
    async back() {
      await this.save();
      this.step--;
    },
    async next() {
      await this.save();
      this.step++;
    },
    async deletePatient() {
      await api.post('/patients/deletePatient', {
        siteId: this.patient.site_id,
        patientId: this.patient.id,
      });
      this.$snotify.success('Deleted the Patient', 'Deleted', {
        position: SnotifyPosition.rightTop,
      });
      // Go back to the patients list page.
      this.$router.push('/patients');
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 10px;
  padding-bottom: 20px;
}
.page-title {
  margin-bottom: 30px;
}
.panel {
  margin-top: 30px;
}
</style>
