<template>
  <v-layout wrap row v-bind:class="{ 'extra-margin': data.local_pain_ctrl }">
    <v-flex xs12 md3>
      <v-checkbox
        v-model="data.local_pain_ctrl"
        label="Locoregional Pain Ctrl"
      />
    </v-flex>

    <v-flex xs12 md8 offset-md1 v-if="data.local_pain_ctrl">
      <v-combobox
        label="Pain Control Options"
        chips
        clearable
        multiple
        hint="You can add new options."
        persistent-hint
        :items="options"
        item-value="id"
        item-text="name"
        :value="locals"
        @input="handleChange"
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click="select"
            @click:close="removeControl(item)"
          >
            {{ item.name || item }}
          </v-chip>
        </template>
      </v-combobox>
    </v-flex>
  </v-layout>
</template>

<script>
import api from '@/api.js';

export default {
  name: 'LocalPainControl',
  props: {
    siteId: { type: Number, required: true },
    data: { type: Object, required: true },
    locals: { type: Array, required: true }
  },
  data() {
    return {
      options: []
    };
  },
  methods: {
    async getOptions() {
      const result = await api.post('/localPainControls/getLocalOptions', {
        siteId: this.siteId
      });
      this.options = result.data;
    },
    handleChange(newLocals) {
      this.$emit('setLocals', newLocals);
    },
    removeControl(item) {
      const index = this.locals.indexOf(item);
      if (index > -1) {
        const newLocals = [
          ...this.locals.slice(0, index),
          ...this.locals.slice(index + 1)
        ];
        this.$emit('setLocals', newLocals);
      }
    }
  },
  created() {
    this.getOptions();
  }
};
</script>

<style scoped>
.extra-margin {
  margin-top: 20px;
}
</style>
