import axios from 'axios';

const url = process.env.VUE_APP_API_URL;

// Custom instance of axios.
const myAxios = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default myAxios;
